import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Livello_2_00000118359338288148000240000008798190139665735356_"
    x={0}
    y={0}
    viewBox="0 -23.17 588.6 148.48"
    {...props}
  >
    <g id="Livello_1-2">
      <path d="M220.4 2.1h13.3v74.6h-13.3zM266.4 72.5V32.9h24V30h-24V6.2h36.4V2.1h-49.7v74.5h53.8v-4.1zM461.5 72.5V32.9h24V30h-24V6.2H498V2.1h-49.8v74.5h53.9v-4.1z" />
      <defs>
        <path id="SVGID_1_" d="M0 0h523.8v78.7H0z" />
      </defs>
      <clipPath id="SVGID_00000000915976054557540520000004531346480930910860_">
        <use
          xlinkHref="#SVGID_1_"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath:
            "url(#SVGID_00000000915976054557540520000004531346480930910860_)",
        }}
      >
        <path d="M170.8 2.1h-26.5v74.6h26.5c20 0 35.6-16.1 35.6-37.3S190.8 2.1 170.8 2.1m-6.6 70.4h-6.6V5h6.6c16.3 0 29 11.4 29 33.8s-12.8 33.7-29 33.7M312.4 65.6c2 4.2 13.5 13.1 30.1 13.1 17.7 0 27.9-9.8 27.9-20.5s-5.3-18.8-23.3-25.6c-20-7.5-24.1-10.8-24.1-16.9 0-4.8 5.4-12.8 18.5-12.8s19.2 8 22.6 12.9l3.6-8C364 5.2 357.4 0 341.9 0 323.5 0 315 10.6 315 20.1s5.8 17.1 21.8 23.2c20 7.5 25.2 10.7 25.2 18.3 0 5.9-5.4 13-19.3 13-15.5 0-22.3-10.1-25.5-19.1l-4.8 10.1zM377 65.6c2 4.2 13.5 13.1 30.1 13.1 17.7 0 27.9-9.8 27.9-20.5s-5.3-18.8-23.3-25.6c-20-7.5-24.1-10.8-24.1-16.9 0-4.8 5.4-12.8 18.5-12.8s19.2 8 22.6 12.9l3.6-8C428.6 5.2 422 0 406.5 0c-18.4 0-26.9 10.6-26.9 20.1s5.8 17.1 21.8 23.2c20 7.5 25.2 10.7 25.2 18.3 0 5.9-5.4 13-19.3 13-15.5 0-22.3-10.1-25.5-19.1L377 65.6zM119.6 76.6h14.1L106.5 2.1H92.4l2.7 7.5-27.8 67h4.5l11.6-28h25.9c.1.1 10.3 28 10.3 28zM84.7 45.8l12.6-30.4 11.1 30.4H84.7zM51 55.9c-2.9-6-7.4-9.2-12.4-10.7C49.8 43.2 58 34.6 58 23.9 58 11.5 47.1 2.1 33.1 2.1H0v74.6h13.3V45.8h12c6.1 0 9.9 2.1 12.3 7.9l9.2 23h14.9L51 55.9zM13.3 5H29c8.9 0 15.8 8.2 15.8 18.9s-6.8 18.8-15.5 19h-16V5zM513.5 3.3c.5-.8 1.1-1.5 2-2 .8-.5 1.8-.7 2.8-.7s2 .2 2.8.7c.8.5 1.5 1.1 2 1.9s.7 1.7.7 2.8-.3 2-.8 2.8c-.5.8-1.1 1.4-2 1.9-.8.5-1.8.7-2.8.7s-1.9-.2-2.8-.7c-.8-.5-1.5-1.1-2-1.9-.5-.8-.7-1.7-.7-2.7s.4-2 .8-2.8m8.5.5c-.4-.7-.9-1.2-1.5-1.6s-1.4-.6-2.2-.6-1.5.2-2.2.6c-.7.4-1.2.9-1.5 1.6-.4.7-.5 1.4-.5 2.2s.2 1.6.5 2.2c.4.7.9 1.2 1.5 1.5.7.4 1.4.6 2.2.6s1.6-.2 2.2-.6c.7-.4 1.2-.9 1.5-1.6.4-.7.5-1.4.5-2.2s-.1-1.4-.5-2.1m-3.5-.8c.7 0 1.3.2 1.7.5s.6.8.6 1.4c0 .4-.1.8-.4 1.1-.2.3-.6.5-1 .6l1.4 2.4h-1.3l-1.3-2.1h-.9V9h-1.2V3h2.4zm-1.1 2.9h1.1c.3 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.5-.2-.8-.2h-1.1v1.9z" />
      </g>
    </g>
    <text
      className="st0"
      style={{
        fontSize: "132.1304px",
        fontFamily: "&quot",
      }}
      transform="translate(513.306 76.594)"
    >
      {"+"}
    </text>
    <path d="M183.7 124.7V94.3h2.6v28H201v2.4h-17.3zM206.7 124.7V94.3h2.6v30.4h-2.6zM216.8 124.7V94.3h9.7c9.4 0 15.4 6.9 15.4 15.2 0 8.4-5.9 15.2-15.4 15.2h-9.7zm2.6-2.4h7.1c8 0 12.6-5.7 12.6-12.8 0-7.1-4.6-12.8-12.6-12.8h-7.1v25.6zM262 93.8c8.9 0 14.8 6.8 14.8 15.7s-5.9 15.7-14.8 15.7-14.8-6.8-14.8-15.7S253 93.8 262 93.8zm0 2.4c-7.4 0-12 5.6-12 13.3 0 7.7 4.6 13.4 12 13.4 7.3 0 12-5.7 12-13.4s-4.7-13.3-12-13.3zM282 109.5c0-9.3 6.8-15.7 15.3-15.7 4.9 0 8.6 2.1 11.2 5.4l-2.2 1.4c-1.9-2.6-5.2-4.4-9-4.4-7.1 0-12.6 5.4-12.6 13.3 0 7.8 5.5 13.4 12.6 13.4 3.7 0 7.1-1.8 9-4.4l2.2 1.4c-2.7 3.3-6.2 5.4-11.2 5.4-8.5 0-15.3-6.4-15.3-15.8zM335.8 124.7l-3.1-7.5h-16.2l-3.1 7.5h-2.9L323 94.3h3.2l12.4 30.4h-2.8zm-11.2-27.8-7.2 18h14.5l-7.3-18zM343.2 124.7V94.3h2.6v30.4h-2.6zM375.1 124.7 356 98.5v26.2h-2.6V94.3h2.6l19 25.9V94.3h2.6v30.4h-2.5zM385.2 124.7V94.3h19.3v2.4h-16.7V108h16.4v2.4h-16.4v12h16.7v2.4h-19.3z" />
  </svg>
);
export { SvgComponent as RadiessePlus };
