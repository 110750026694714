import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Routes } from "../../utils/routes";
import React from "react";
import { StandbyHandler } from "../../pages/StandbyHandler";

export type Route<Routes extends string = string> = Record<Routes, JSX.Element>;

type ChangeRouteConfig<Args = any> = {
  args?: Args;
  showPrevious: boolean;
};

interface MainRouterProps<Routes extends string = string> {
  routes: Route<Routes>;
  initialRoute: keyof Route<Routes>;
}

interface IRouteContext<Keys extends string = string, Args = any> {
  changeRoute: (e: Keys, config: ChangeRouteConfig<Args>) => void;
  pop: () => void;
  currentRoute?: Keys;
  args?: Args;
}

const RouteContext = createContext<IRouteContext | null>(null);

type NavigationStack = string[];

export function MainRouter(props: MainRouterProps) {
  const [args, setArgs] = useState<any | undefined>();
  const [navigationStack, setNavigationStack] = useState<NavigationStack>([
    props.initialRoute,
  ]);

  const currentRoute = useMemo<string>(
    () => navigationStack[0],
    [navigationStack]
  );

  const routes = useMemo(
    () =>
      Object.entries(props.routes).map(([key, X]) => ({
        key,
        value: React.memo(() => (
          <div
            id={key}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: props.initialRoute === key ? 1 : 0,
            }}
            children={X}
          />
        )),
      })),
    [props.routes, props.initialRoute]
  );

  const addRoute = useCallback((route: string) => {
    setNavigationStack((old) => [route, ...old]);
  }, []);

  const showRoute = useCallback((route: string, config: ChangeRouteConfig) => {
    const visibleRoute = document.getElementById(route);
    if (visibleRoute) {
      visibleRoute.style.zIndex = "1";
      visibleRoute.style.visibility = "visible";
    }
  }, []);

  const hideRoute = useCallback((route: string, config: ChangeRouteConfig) => {
    const oldRoute = document.getElementById(route);
    if (oldRoute) {
      if (!config.showPrevious) {
        oldRoute.style.visibility = "hidden";
      }
      oldRoute.style.zIndex = "0";
    }
  }, []);

  const pop = useCallback(() => {
    setNavigationStack((e) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...other] = e;
      if (e.length > 0) {
        hideRoute(currentRoute, { showPrevious: false });
        showRoute(other[0], { showPrevious: false });
        return other;
      } else {
        return e;
      }
    });
  }, [currentRoute, hideRoute, showRoute]);

  const changeRoute = useCallback(
    (newRoute: string, config: ChangeRouteConfig) => {
      showRoute(newRoute, config);
      hideRoute(currentRoute, config);
      addRoute(newRoute);
      setArgs(config.args);
    },
    [addRoute, currentRoute, hideRoute, showRoute]
  );

  return (
    <RouteContext.Provider value={{ changeRoute, pop, args, currentRoute }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          backgroundColor: "black",
        }}
      >
        {routes.map(({ key, value: Element }) => (
          <Element key={key} />
        ))}
      </div>

      <StandbyHandler />
    </RouteContext.Provider>
  );
}

export function SingleRoute({
  route,
  children,
}: {
  route: Routes;
  children?: React.DetailedHTMLProps<any, HTMLDivElement["children"]>;
}) {
  const value: IRouteContext<string, any> = {
    currentRoute: route,
    changeRoute(e, config) {},
    pop() {},
  };
  return <RouteContext.Provider value={value} children={children} />;
}

export function useRouter<Args = any>() {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error(" must be used within an AssetsLoader");
  }

  return context as IRouteContext<Routes, Args>;
}

export function useIsRouteVisible(route: Routes) {
  const { currentRoute } = useRouter();

  return currentRoute === route;
}
