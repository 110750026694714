import { Grid, Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { easeOutBack, transitionEnabled } from "../../../constants";

export function Title() {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<DOMRect | undefined>();

  const isFullScreen = useAppSelector(
    (state) =>
      state.viso.status === "fullscreen" || state.viso.status === "muscles"
  );

  useEffect(() => {
    if (ref.current) {
      setSize(ref.current.getBoundingClientRect());
    }
  }, []);

  return (
    <>
      <div ref={ref}></div>
      {size && (
        <div
          style={{
            animation: `slide-in-bck-left 2s ${easeOutBack}`,
            animationFillMode: "both",
            color: "black",
            position: "absolute",
            top: size.top,
            left: 100,
            opacity: isFullScreen ? 1 : 0,
            transition: !transitionEnabled ? undefined : "opacity 1s ease-out",
          }}
        >
          <Grid container direction={"column"} gap={2}>
            <Typography variant="h3">
              <strong>ESPLORA</strong> IL MODELLO{" "}
              <strong
                style={{
                  color: "#C7005D",
                }}
              >
                3D
              </strong>
            </Typography>
            <Typography
              variant="h4"
              style={{ borderLeft: "0.5rem solid #C7005D" }}
              pl={2}
            >
              <strong>DISTRETTI ANATOMICI</strong>
              <br />
              <strong>PIANI INIETTIVI</strong>
              <br />E <strong>PRODOTTI</strong>
            </Typography>
          </Grid>
        </div>
      )}
      {size && (
        <div
          style={{
            animation: `slide-in-bck-right 2s ${easeOutBack}`,
            animationFillMode: "both",
            color: "black",
            position: "absolute",
            top: size.top,
            right: 100,
            opacity: isFullScreen ? 1 : 0,
            transition: !transitionEnabled ? undefined : "opacity 1s ease-out",
          }}
        >
          <Grid container direction={"column"} gap={2}>
            <Typography variant="h4">
              Seleziona l'<strong>area</strong> da approfondire
            </Typography>
          </Grid>
        </div>
      )}
    </>
  );
}
