import { Grid, GridProps, Typography, TypographyProps } from "@mui/material";

interface RectangleProps {
  active?: boolean;
  text?: TypographyProps["children"];
  activeColor?: string;
  disabledColor?: string;
  width?: GridProps["width"];
  height?: GridProps["height"];
  visibility?: GridProps["visibility"];
  onClick?: GridProps["onClick"];
  variant?: TypographyProps["variant"];
}

export function Rectangle({
  active,
  text,
  activeColor,
  width,
  height,
  disabledColor,
  visibility,
  onClick,
  variant,
}: RectangleProps) {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      height={height ?? "100%"}
      width={width}
      visibility={visibility}
      py={1}
      sx={{
        backgroundColor: active ? activeColor : disabledColor,
      }}
      onClick={onClick}
    >
      <Typography
        variant={variant}
        style={{
          userSelect: "none",
          color: active ? "white" : "black",
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
}
