import { Grid, Typography } from "@mui/material";
import { TextWithLines } from "../../components/Atoms/TextWithLines";

export function JointBusinessPlan() {
  return (
    <Grid
      container
      height={"100%"}
      width={"100%"}
      direction={"column"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Grid item width={"100%"} px={5}>
        <TextWithLines animation="blur 1s ease-out">
          <Typography
            variant="h4"
            textAlign={"center"}
            style={{ animation: "text-focus-in 2s ease-out" }}
          >
            Sviluppiamo insieme a te una{" "}
            <i>
              <strong>partnership</strong>
            </i>{" "}
            sostenibile nel tempo,
            <br />
            sulla base di una pianificazione strategica e accordi commerciali
            dedicati.
          </Typography>
        </TextWithLines>
      </Grid>
    </Grid>
  );
}
