import { CSSProperties } from "react";

export const transitionEnabled = true;
export const maxZoomScale = 4;

export const easeInSine = "cubic-bezier(0.470, 0.000, 0.745, 0.715)";
export const easeInQuad = "cubic-bezier(0.550, 0.085, 0.680, 0.530)";
export const easeOutQuad =
  "scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)";
export const easeOutQuint = "cubic-bezier(0.230, 1.000, 0.320, 1.000)";
export const easeOutBack = "cubic-bezier(0.175, 0.885, 0.320, 1.275)";

export const focusInAnimation: CSSProperties = {
  animation: `text-focus-in 1s ${easeInQuad}`,
  animationFillMode: "both",
};

export const prodottiInAnimation: CSSProperties = {
  animation: `slide-in-left 1s ${easeInQuad}`,
  animationFillMode: "both",
};

export const secondsAfterStandby = 60 * 10;

export const bocotureEnabled = true;

export const minTimeVideoDuration = 3000;

export const skipVideoChiSiamo = false;

export const rotationOffsetSensibility = (e: number) => e / 4;
