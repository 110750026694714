import { Box } from "@mui/material";
import { useRouter } from "../components/MainRouter/MainRouter";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ZIndexes } from "../components/InternalPage/InternalPage";
import { resetViso } from "../redux/viso.reducer";
import { Routes } from "../utils/routes";
import { resetBelotero } from "../redux/belotero.reducer";
import { resetIntro } from "../redux/pages.reducer";
import { useEffect } from "react";
import { secondsAfterStandby } from "../constants";
import { setYear } from "../redux/history.reducer";

export function StandbyHandler() {
  const { changeRoute, currentRoute } = useRouter();
  const dispatch = useAppDispatch();
  return (
    <>
      <Handler />
      {currentRoute !== Routes.standby && (
        <Box
          position={"absolute"}
          left={0}
          top={0}
          zIndex={ZIndexes.everywhere}
          width={"3rem"}
          height={"25%"}
          onClick={() => {
            changeRoute(Routes.standby, { showPrevious: false });
            dispatch(setYear(null));
            dispatch(resetViso());
            dispatch(resetBelotero());
            dispatch(resetIntro());
          }}
        />
      )}
    </>
  );
}

function Handler() {
  const { currentRoute, changeRoute } = useRouter();
  const state = useAppSelector((state) => state);

  useEffect(() => {
    //TIMEOUT AFTER 10 MINUTES
    const timeout = setTimeout(() => {
      if (currentRoute !== Routes.standby) {
        changeRoute(Routes.standby, { showPrevious: false });
      }
    }, 1000 * secondsAfterStandby);
    return () => {
      clearTimeout(timeout);
    };
  }, [changeRoute, currentRoute, state]);

  return <></>;
}
