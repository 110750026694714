import { Middleware, configureStore } from "@reduxjs/toolkit";
import viso from "./viso.reducer";
import modal from "./modal.reducer";
import belotero from "./belotero.reducer";
import history from "./history.reducer";
import prodotti from "./prodotti.reducer";
import pages from "./pages.reducer";
import elite from "./elite.reducer";

export const store = configureStore({
  reducer: {
    viso,
    modal,
    belotero,
    history,
    prodotti,
    pages,
    elite,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type Middle = Middleware<{}, RootState, AppDispatch>;
