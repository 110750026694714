import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./styles/theme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";
import { AssetsLoader } from "./components/AsssetsLoader";
import { getAssetLoaderStrategy, getAssets } from "./utils/assets";
import { MainRouter } from "./components/MainRouter/MainRouter";
import { Routes, routes } from "./utils/routes";
import { LoadingPage } from "./pages/LoadingPage";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Modal } from "./components/Modal";
import swConfig from "./swConfig";
import * as Sentry from "@sentry/react";

document.addEventListener("contextmenu", (event) => event.preventDefault());

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://954370294b7fe90f69e1e3a92f05f02b@o4506788256350208.ingest.sentry.io/4506788268998656",
});

var timeout: NodeJS.Timeout | null = null;

window.onerror = function (message, source, lineno, colno, error) {
  if (
    typeof message === "string" &&
    message.includes("Cannot inject key into script value")
  ) {
    if (timeout != null) clearTimeout(timeout);
    timeout = setTimeout(() => window.location.reload(), 2000);
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense fallback={"Loading.."}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <AssetsLoader
          assetsUrl={getAssets()}
          loading={(props) => <LoadingPage {...props} />}
          type={getAssetLoaderStrategy("cacheBeforeLazy")}
        >
          <Provider store={store}>
            <Modal />
            <MainRouter
              routes={routes}
              initialRoute={
                process.env.NODE_ENV === "production" ? Routes.home : Routes.dna
              }
            />
          </Provider>
        </AssetsLoader>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(swConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
