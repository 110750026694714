import { Grid, Typography } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  ModalSlidersData,
  ModalDOMRect,
  closeModal,
} from "../../redux/modal.reducer";
import { LeftIcon, RightIcon } from "../Icons/ArrowUP";
import { useWindowSize } from "@uidotdev/usehooks";

export const ModalSlider = (
  props: ModalSlidersData & { rect: ModalDOMRect }
) => {
  const [index, setIndex] = useState(0);
  const current = useMemo(() => props.sliders[index], [index, props.sliders]);
  const { close } = useAppSelector((state) => ({
    close: state.modal.close,
  }));

  const { height, width } = useWindowSize();

  const size = useMemo(() => {
    if (height === undefined || width === undefined) return undefined;
    const midWidth = width! * 0.4;
    return midWidth <= height! * 0.8 ? midWidth : height! * 0.8;
  }, [height, width]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (close) {
      dispatch(closeModal());
    }
  }, [close, dispatch]);

  if (size === undefined) return <></>;

  return (
    <Grid
      container
      height={"100%"}
      width={"100%"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Grid
        container
        width={"100%"}
        height={`${size}px`}
        gap={3}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item height={"100%"}>
          <Grid
            container
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignContent={"center"}
            onClick={
              index > 0
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIndex(index - 1);
                  }
                : undefined
            }
          >
            <LeftIcon
              width={"2rem"}
              style={{ visibility: index > 0 ? "visible" : "hidden" }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          width={`${size}px`}
          height={`${size}px`}
          style={{ overflow: "hidden" }}
        >
          <video
            crossOrigin="anonymous"
            src={current.video}
            autoPlay
            style={{
              height: "auto",
              width: "100%",
              objectFit: "cover",
            }}
          ></video>
        </Grid>
        <Grid
          item
          width={`${size}px`}
          height={"100%"}
          sx={{ backgroundColor: "belotero.main" }}
        >
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignContent={"center"}
            height={"100%"}
            width={"100%"}
            sx={{
              flexWrap: "wrap",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
            pt={8}
            px={8}
          >
            <Grid item xs={4}>
              <Grid container direction={"column"} width={"100%"}>
                {current.prodotti.map((e, i) => (
                  <Grid
                    key={i}
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                  >
                    <Grid item xs={3}>
                      {i === 0 ? <strong>Prodotti</strong> : <></>}
                    </Grid>
                    <Grid item xs={7}>
                      <strong>{e.title}</strong>
                    </Grid>
                    <Grid item xs={2}>
                      <strong>{e.amount}</strong>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid item xs>
              <Typography overflow={"wrap"}>
                <strong>{current.desc}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignContent={"center"}
            onClick={
              index < props.sliders.length - 1
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIndex(index + 1);
                  }
                : undefined
            }
          >
            <RightIcon
              width={"2rem"}
              style={{
                visibility:
                  index < props.sliders.length - 1 ? "visible" : "hidden",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
