import styled from "@emotion/styled";
import { LogoProps } from "./BocotureLogo";

const SvgComponent = (props: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Ebene_1"
    x={0}
    y={0}
    viewBox="142 207.85 538.81 93.45"
    {...props}
  >
    <path
      d="M142 299.6v-82.7h16.3c9.2 0 18.3 0 25.5 6.4 4.1 3.7 7 9.7 7 15.4 0 10.4-6.7 14.3-9.3 15.8 5.5 2 14.2 6.6 14.2 20.6 0 9.9-5.3 16.1-10.3 19.7-6.3 4.4-16 4.9-21.3 4.9H142v-.1zm15.3-36.2v21.9h7.7c3 0 15.4 0 15.4-10.8 0-3.4-1.7-11.1-15.1-11.1h-8zm0-32.2v17.9h5c3.6 0 13.2 0 13.2-8.9 0-4.9-3.1-8.9-11.8-8.9h-6.4v-.1z"
      className="st0"
    />
    <path
      d="M205.8 299.6v-82.7h44.4v14.3h-29.1v20.2H249v14.3h-27.9v19.6h29.1v14.3h-44.4z"
      className="st1"
    />
    <path
      d="M305.6 299.4zm-40.5.2v-82.7h15.3v68.4h24.5l-19 14.3h-20.8zM332.7 279c5 4.9 11.7 8 19.3 8 15.8 0 27.9-13.3 27.9-28.6 0-4.6-1-8.9-2.9-12.8l12.2-9.2c3.8 6.4 6 13.9 6 22.1 0 23.7-19.4 42.8-43.2 42.8-12.3 0-23.4-5.1-31.3-13.3l12-9zm-8.6-20.7c0 3.8.8 7.5 2.1 10.9l-12.4 9.3c-3.2-6-5.1-12.8-5.1-20.1 0-24.5 19.8-43.2 43.2-43.2 11.5 0 22.1 4.5 29.9 12l-12 9c-4.8-4.2-11.1-6.7-17.9-6.7-15.4 0-27.8 13-27.8 28.8z"
      className="st0"
    />
    <path
      d="M412.1 299.6v-68.4h-15.3v-.5l18.3-13.8h27.7v14.3h-15.4v68.4h-15.3zM449.9 299.6v-82.7h44.4v14.3h-29.1v20.2h27.9v14.3h-27.9v19.6h29.1v14.3h-44.4z"
      className="st1"
    />
    <path
      d="M508.3 299.6v-82.7h25.4c9.5 0 15.4 1.8 20.1 5.6 4.7 3.8 9.7 10.5 9.7 20 0 5.5-2 14.1-7.9 19.8-4.9 4.7-9.6 5.8-13.7 6.8l22.5 30.5h-18.5L523.8 268l-.2 31.6h-15.3zm15.3-68.4v25.3h9.8c12.6 0 14.7-8.4 14.7-12.7 0-4.6-2.8-12.5-14.7-12.5h-9.8v-.1zM572.4 258.5c0-24.5 19.8-43.2 43.2-43.2s43.2 18.8 43.2 43.2c0 23.7-19.4 42.8-43.2 42.8-23.8-.1-43.2-19.2-43.2-42.8zm15.3-.2c0 15.3 12.2 28.6 27.9 28.6 15.8 0 27.9-13.3 27.9-28.6 0-15.9-12.4-28.8-27.9-28.8-15.5 0-27.9 13-27.9 28.8z"
      className="st0"
    />
    <text
      className="st1"
      style={{
        fontSize: "30.5849px",
        fontFamily: "&quot",
      }}
      transform="translate(656.343 238.351)"
    >
      {"\xAE"}
    </text>
  </svg>
);

export const BeloteroLogo = styled(SvgComponent)`
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
  .st0,
  .st1 {
    fill: #ee7203;
  }
`;
