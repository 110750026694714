import { Grid, GridProps, Typography, TypographyProps } from "@mui/material";
import { Colors } from "../../styles/theme";
import { Line } from "../Atoms/Lines";
import { easeInQuad, easeInSine } from "../../constants";

interface LineMessage {
  type: "line";
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
}

interface IconMessage {
  type: "icon";
  icon: JSX.Element;
  title: TypographyProps["children"];
}

type MessageType = LineMessage | IconMessage;

interface KeyMessagesProps {
  color: Colors;
  description: string | JSX.Element;
  messages: Array<MessageType>;
  animationDelay?: number;
}

export function KeyMessages(props: KeyMessagesProps) {
  return (
    <Grid
      container
      direction="column"
      alignItems={"center"}
      height={"100%"}
      justifyContent={"flex-start"}
      pt={4}
    >
      <Typography
        variant={"h5"}
        textAlign={"center"}
        sx={{
          color: `${props.color}.main`,
          animation: `text-focus-in 1s ${easeInQuad}`,
          animationFillMode: "both",
          animationDelay: `${props.animationDelay}s`,
        }}
      >
        <i>Key Messages</i>
      </Typography>
      <Grid item width={"100%"} xs>
        <Grid
          container
          height={"100%"}
          width={"100%"}
          justifyContent={"space-evenly"}
          alignContent={"center"}
          direction={"column"}
        >
          <Grid
            item
            width={"100%"}
            sx={{
              animation: `text-focus-in 1s ${easeInQuad}`,
              animationFillMode: "both",
              animationDelay: `${props.animationDelay ?? 2}s`,
            }}
          >
            {typeof props.description === "string" ? (
              <Typography variant={"body1"} textAlign={"center"}>
                <strong>{props.description}</strong>
              </Typography>
            ) : (
              props.description
            )}
          </Grid>
          <Grid item width={"100%"}>
            <Grid container direction={"row"} justifyContent={"space-evenly"}>
              {props.messages.map((m, i) => (
                <Grid key={i} item xs={2}>
                  <Message
                    {...m}
                    color={props.color}
                    sx={{
                      animation: `puff-in-center 1s ${easeInSine}`,
                      animationDelay: `${
                        (i + 1 + (props.animationDelay ?? 0)) * 1
                      }s`,
                      animationFillMode: "both",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Message(props: MessageType & { color: Colors; sx?: GridProps["sx"] }) {
  switch (props.type) {
    case "line":
      return (
        <Grid
          container
          direction="column"
          alignItems={"center"}
          gap={2}
          sx={props.sx}
        >
          <Grid item width={"50%"}>
            <Line height={"0.2rem"} color={props.color}></Line>
          </Grid>
          <Grid item>
            <Typography
              variant={"body1"}
              textAlign={"center"}
              fontWeight={"bold"}
            >
              {props.title}
            </Typography>
            <Typography
              variant={"body1"}
              textAlign={"center"}
              fontWeight={"bold"}
              sx={{ color: `${props.color}.main` }}
            >
              {props.subtitle}
            </Typography>
          </Grid>
        </Grid>
      );
    case "icon":
      return (
        <Grid
          container
          direction="column"
          alignItems={"center"}
          gap={1}
          sx={{ color: `${props.color}.main`, ...props.sx }}
        >
          <Grid item>{props.icon}</Grid>
          <Grid item>
            <Typography variant={"body1"} textAlign={"center"}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      );
  }
}
