import { CSSProperties } from "react";

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  fill?: CSSProperties["fill"];
}
export function Logo({ fill, ...other }: LogoProps) {
  return (
    <svg viewBox="0 0 410 96" {...other}>
      <path
        fill={fill}
        d="M357.75,63.52l0-1.55l12.97,0c3.43,0,5.63-2.1,5.63-5.45v-2.56c0-3.61-1.85-5.28-5.97-5.28h-4.72
	c-5.11,0-7.94-2.28-7.94-6.4l0-2.63c0-4.25,2.83-7.04,7.26-7.04l12.88,0l0,1.59l-12.97,0c-3.43,0-5.63,2.1-5.63,5.45l0,2.33
	c0,4.92,4.54,5.07,6.4,5.07h4.72c4.81,0,7.47,2.41,7.47,6.74l0,2.95c0,4.08-2.79,6.78-6.96,6.78L357.75,63.52z M334.81,39.17
	c0-0.15-0.27-4.98,5.62-4.98h12.97V32.6h-13.01c-7.14,0-7.13,5.96-7.13,6.7l0,17.43c0,4.08,2.79,6.78,6.96,6.78l13.05,0v-1.55
	l-12.83,0c-3.43,0-5.63-2.1-5.63-5.45L334.81,39.17z M325.1,63.52h1.55l0-30.92h-1.55V63.52z M301.02,34.19h9.15l0,29.32h1.55V34.19
	h9.15V32.6h-19.84V34.19z M278.3,39.64c0-3.35,2.19-5.45,5.63-5.45l12.88,0V32.6h-12.75c-4.43,0-7.26,2.79-7.26,7.04v17.09
	c0,4.08,2.79,6.78,6.96,6.78h12.97v-1.55h-12.75c-3.43,0-5.63-2.1-5.63-5.45v-7.46h18.38l0.09-1.59H278.3V39.64z M268.68,47.47
	H249.4l0-14.87h-1.54l0,30.92h1.54l0-14.45h19.28v14.45h1.5l0-30.92h-1.5L268.68,47.47z M223.77,34.19h8.96l0,29.32h1.55l0-29.32
	h9.15V32.6h-19.65V34.19z M214.72,63.52c4.17,0,6.96-2.71,6.96-6.78l0-2.95c0-4.34-2.66-6.74-7.47-6.74h-4.72
	c-1.86,0-6.4-0.14-6.4-5.07v-2.33c0-3.35,2.19-5.45,5.63-5.45h10.85V32.6l-10.76,0c-4.43,0-7.26,2.79-7.26,7.04v2.63
	c0,4.12,2.83,6.4,7.94,6.4h4.72c4.12,0,5.97,1.68,5.97,5.28l0,2.56c0,3.35-2.19,5.45-5.63,5.45l-12.97,0v1.55L214.72,63.52z
	 M178.7,39.64c0-3.35,2.19-5.45,5.63-5.45l12.88,0V32.6h-12.75c-4.43,0-7.26,2.79-7.26,7.04v17.09c0,4.08,2.79,6.78,6.96,6.78h12.97
	v-1.55h-12.75c-3.43,0-5.63-2.1-5.63-5.45v-7.46h18.38l0.09-1.59H178.7V39.64z M147.21,63.52l12.06-30.1
	c0.31-0.74,0.74-1.14,1.39-1.14c0.65,0,1.02,0.4,1.35,1.14l12.07,30.1l-1.89,0l-3.5-9.04h-16.11l-3.54,9.04L147.21,63.52z
	 M167.96,52.88l-7.3-19.07l-7.39,19.07H167.96z M117.63,63.52c-0.72,0-2.22,0-2.22-1.73c0-0.49,0.21-1.08,0.58-1.67l16.31-24.33
	h-16.43V32.6h18.41c0.72,0,2.22,0,2.22,1.73c0,0.49-0.21,1.08-0.58,1.67l-16.3,24.37h16.75v3.14H117.63z M98.87,51.07l16.6,12.45
	h-5.33L94.5,51.75v11.77h-3.15V32.6h14.6c5.07,0,7.98,2.7,7.98,7.41v3.93c0,4.4-2.8,7.13-7.29,7.13H98.87z M106.56,47.93
	c2.73-0.04,4.23-1.39,4.23-3.82v-4.18c0-2.79-1.57-4.14-4.79-4.14H94.5v12.23c0.3-0.05,0.57-0.05,0.74-0.05L106.56,47.93z
	 M87.77,35.79V32.6H74.88c-4.61,0-7.7,3.01-7.7,7.49v16.17c0,4.34,2.98,7.25,7.42,7.25h13.09v-3.14H74.79
	c-2.8,0-4.48-1.61-4.48-4.31v-6.25h17.32l0.18-3.19H70.28V40.1c0-2.7,1.67-4.31,4.48-4.31L87.77,35.79z M47.72,58.84L35.95,34.23
	c-0.51-1.09-0.91-1.95-2.1-1.95c-1.69,0-1.69,1.77-1.69,2.63v28.61h3.15V40.13l10.32,21.65c0.63,1.35,0.96,1.94,2.06,1.94
	c1.11,0,1.64-0.94,2.26-2.27l9.92-21.18v23.24h3.15l0-28.61c0-0.85,0-2.63-1.69-2.63c-1.2,0-1.6,0.86-2.1,1.95L47.72,58.84z
	 M387.46,32.28c2.59,0,4.69,2.11,4.69,4.69c0,2.59-2.11,4.69-4.69,4.69c-2.59,0-4.69-2.11-4.69-4.69
	C382.77,34.39,384.87,32.28,387.46,32.28z M387.46,40.83c2.13,0,3.86-1.73,3.86-3.86c0-2.13-1.73-3.86-3.86-3.86
	c-2.13,0-3.86,1.73-3.86,3.86C383.6,39.11,385.33,40.83,387.46,40.83z M385.66,34.26h2.07c1.29,0,1.89,0.58,1.89,1.61
	c0,0.93-0.56,1.39-1.29,1.48l1.4,2.27h-1.04l-1.34-2.2h-0.72v2.2h-0.98V34.26z M386.64,36.59h0.72c0.61,0,1.27-0.04,1.27-0.76
	c0-0.72-0.67-0.76-1.27-0.74h-0.72V36.59z"
      />
    </svg>
  );
}
