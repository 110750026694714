import { Box, BoxProps, SxProps, Theme } from "@mui/material";
import React, { forwardRef, useMemo } from "react";

interface BaseHorizontalGridProps {
  children?: BoxProps["children"];
  sx?: BoxProps["sx"];
  onClick?: BoxProps["onClick"];
}

export interface ItemProps extends BaseHorizontalGridProps {
  type: "item";
  children?: BoxProps["children"];
  sx?: BoxProps["sx"];
}

export interface ContainerProps extends BaseHorizontalGridProps {
  type: "container";
  columns: number;
  rowSize?: number;
  columnSize: number | string | Array<string>;
  firstColumn?: string;
  gap?: number;
  scrollable?: boolean;
  width?: string;
}

export type HorizontalGridProps = ContainerProps | ItemProps;

export const HorizontalGrid = forwardRef(function HorizontalGrid(
  props: HorizontalGridProps,
  ref: React.Ref<HTMLDivElement>
) {
  const sxProps = useMemo<SxProps<Theme> | undefined>(() => {
    switch (props.type) {
      case "container":
        return {
          ...props.sx,
          overflowY: "hidden",
          overflowX: props.scrollable ?? true ? "scroll" : "hidden",
          whiteSpace: "nowrap",
        };
      case "item":
        return {
          ...props.sx,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sx, props.type]);

  const gridTemplateColumns = useMemo(() => {
    if (props.type === "container") {
      if (Array.isArray(props.columnSize)) {
        return `${props.firstColumn ?? ""} ${props.columnSize.join(" ")}`;
      } else {
        return `${props.firstColumn ?? ""} repeat(${props.columns}, ${typeof props.columnSize === "string"
            ? props.columnSize
            : `${props.columnSize}px`
          })`;
      }
    } else {
      return undefined;
    }
  }, [props]);

  switch (props.type) {
    case "container":
      return (
        <Box
          sx={sxProps}
          pr={props.gap}
          height={props.rowSize}
          onClick={props.onClick}
          ref={ref}
        >
          <Box
            sx={{
              height: props.rowSize ?? "100%",
              display: "inline-grid",
              gridTemplateColumns,
              gap: `${props.gap}rem`,
              width: props.width,
              "& > *": {
                height: props.rowSize ?? "100%",
              },
            }}
            children={props.children}
          />
        </Box>
      );
    case "item":
      return (
        <Box
          sx={sxProps}
          children={props.children}
          onClick={props.onClick}
          ref={ref}
        />
      );
  }
});

export interface VerticalItemProps extends BaseHorizontalGridProps {
  type: "item";
  children?: BoxProps["children"];
  sx?: BoxProps["sx"];
}

export interface VerticalContainerProps extends BaseHorizontalGridProps {
  type: "container";
  rows: Array<string>;
  gap?: number;
}

export type VerticalGridProps = VerticalContainerProps | VerticalItemProps;

// r
export const VerticalGrid = forwardRef(function VerticalGrid(
  props: VerticalGridProps,
  ref: React.Ref<HTMLDivElement>
) {
  const sxProps = useMemo<SxProps<Theme> | undefined>(() => {
    switch (props.type) {
      case "container":
        return {
          ...props.sx,
        };
      case "item":
        return {
          ...props.sx,
        };
    }
  }, [props.sx, props.type]);

  switch (props.type) {
    case "container":
      return (
        <Box sx={sxProps} height={"100%"} ref={ref}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "grid",
              gridTemplateRows: props.rows.join(" "),
              gap: `${props.gap}rem`,
              "& > *": {
                overflow: "hidden",
                width: "100%",
                height: "100%",
              },
            }}
            children={props.children}
          />
        </Box>
      );
    case "item":
      return <Box sx={sxProps} children={props.children} ref={ref} />;
  }
});
