import { SVGProps } from "react";
export function PlayIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="100.4 0 259.83 300"
      {...props}
    >
      <path
        d="M114.4 24.25 332.21 150 114.4 275.75V24.25M100.4 0v300l259.81-150L100.4 0Z"
        style={{
          fill: "#fff",
          strokeWidth: 0,
        }}
      />
    </svg>
  );
}
