import { Grid } from "@mui/material";
import { HomeCard, HomeCardProps } from "../components/Card/HomeCard";
import {
  Transition,
  TransitionProps,
} from "../components/Animations/Transitions/Transition";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIsRouteVisible } from "../components/MainRouter/MainRouter";
import { Routes } from "../utils/routes";
import { VideoKeys } from "../utils/assets";
import { adjustVolume } from "../utils/functions";
import { useSingleAsset } from "../components/AsssetsLoader";
import { useAppDispatch } from "../redux/hooks";
import { setIntroViewed, useIsIntroViewed } from "../redux/pages.reducer";

function shuffleArray<T = any>(array: Array<T>) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

interface HomePageProps
  extends Pick<TransitionProps, "duration" | "timingFunction"> {
  startDelay?: number;
  innerDelay?: number;
  data: Array<HomeCardProps & Pick<TransitionProps, "animation">>;
}

export function HomePage({
  data,
  startDelay,
  innerDelay,
  ...props
}: HomePageProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [index, setIndex] = useState(0);
  const [startVideo, setStartVideo] = useState(false);
  const [indexes, setIndexes] = useState(shuffleArray(data.map((_, i) => i)));
  // const [isActive, setIsActive] = useState(false);
  const isIntroViewed = useIsIntroViewed(Routes.home);
  const dispatch = useAppDispatch();

  const standbyTransition = useSingleAsset<VideoKeys>("standby_transition");

  const isVisible = useIsRouteVisible(Routes.home);

  useEffect(() => {
    if (videoRef.current === null) return;
    if (isVisible && !isIntroViewed) {
      setTimeout(() => dispatch(setIntroViewed(Routes.home)), 0);
      videoRef.current.play().catch(() => {});
      setTimeout(() => {
        adjustVolume(videoRef.current!, 0, { duration: 2000 });
      }, 8000);
    }
  }, [isVisible, isIntroViewed, dispatch]);

  const onEnd = useCallback(() => {
    const newIndex = (index + 1) % data.length;
    setStartVideo(false);
    setIndex(newIndex);
    setTimeout(() => setStartVideo(true), (innerDelay ?? 0) * 1000);
    if (newIndex === 0) setIndexes(shuffleArray(indexes));
  }, [data.length, index, indexes, innerDelay]);

  useEffect(() => {
    const timeout = setTimeout(
      () => setStartVideo(true),
      (startDelay ?? 0) * 1000
    );
    return () => clearTimeout(timeout);
  }, [startDelay]);

  const indexPlaying = useMemo(() => indexes[index], [index, indexes]);

  if (!isVisible) return <></>;

  return (
    <>
      <video
        crossOrigin="anonymous"
        ref={videoRef}
        src={standbyTransition}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
        }}
      />
      <Grid
        container
        direction="row"
        height={"100%"}
        gap={1}
        style={{
          backgroundColor: "black",
        }}
        alignItems={"center"}
      >
        {data.map(({ animation, ...e }, i) => (
          <Grid item xs height={"100%"} key={e.img}>
            <Transition {...props} animation={animation} on={isIntroViewed}>
              <HomeCard
                {...e}
                videoOn={isIntroViewed && startVideo && indexPlaying === i}
                onEnded={onEnd}
              />
            </Transition>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
