import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 3"
    viewBox="50.45 40 300.02 259.83"
    {...props}
  >
    <path
      d="M200.46 299.81 350.46 40h-300l150 259.81z"
      style={{
        fill: "black",
        strokeWidth: 0,
      }}
    />
  </svg>
);
export { SvgComponent as ArrowDownFill };
