import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "./hooks";

type PagesState = {
  intro_viewed: string[];
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState: {
    intro_viewed: [],
  } as PagesState,
  reducers: {
    setIntroViewed: (state, action: PayloadAction<string[] | string>) => {
      state.intro_viewed =
        typeof action.payload === "string" ? [action.payload] : action.payload;
    },
    removeIntroViewed: (state, action: PayloadAction<string>) => {
      state.intro_viewed = state.intro_viewed.filter(
        (e) => e !== action.payload
      );
    },
    resetIntro: (state) => {
      state.intro_viewed = [];
    },
  },
});

export const { setIntroViewed, resetIntro, removeIntroViewed } =
  pagesSlice.actions;

export const useIsIntroViewed = (id?: string) =>
  useAppSelector((state) => state.pages.intro_viewed.some((e) => e === id));

export default pagesSlice.reducer;
