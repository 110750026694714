import {
  Palette,
  PaletteColor,
  PaletteExt,
  createTheme,
} from "@mui/material/styles";
import { KeysOfType } from "../utils/types";

declare module "@mui/material/styles" {
  interface PaletteExt {
    smalt: PaletteColor;
    debianRed: PaletteColor;
    titaniumYellow: PaletteColor;
    eliteWhite: PaletteColor;
    bocotureBlue: PaletteColor;
    ultherapyYellow: PaletteColor;
    radiesseBlue: PaletteColor;
    biorepeel: PaletteColor;
    belotero: PaletteColor;
    belotero_revive: PaletteColor;
    belotero_soft: PaletteColor;
    belotero_balance: PaletteColor;
    belotero_intense: PaletteColor;
    belotero_volume: PaletteColor;
    belotero_lips: PaletteColor;
    infoGrey: PaletteColor;
    ultherapyDots: PaletteColor;
    biorepeelDots: PaletteColor;
    radiesseDots: PaletteColor;
    radiesseText: PaletteColor;
    bgGrey: PaletteColor;
    black: PaletteColor;
  }

  interface Palette extends PaletteExt {}

  interface PaletteOptions extends PaletteExt {}
}

export type Colors = KeysOfType<Palette & PaletteExt, PaletteColor>;

const { palette } = createTheme();
const { augmentColor } = palette;
export const createColor = (
  mainColor: string,
  optional?: Partial<PaletteColor>
) => Object.assign(augmentColor({ color: { main: mainColor } }), optional);

// A custom theme for this app
export const lightTheme = createTheme({
  typography: {
    fontFamily: "ProximaNova",
    fontSize: 16,
    allVariants: {
      lineHeight: 1.3,
    },
    h4: {
      fontSize: "1.875rem",
    },
    body1: {
      fontSize: "1.0625rem",
    },
    caption: {
      fontSize: "0.5rem",
    },
  },
  // breakpoints: {

  // },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          height: "100%",
        },
        html: {
          height: "100%",
          "background-color": theme.palette.secondary,
          [theme.breakpoints.down("xl")]: {
            fontSize: 12,
          },
        },
      }),
    },
  },
  palette: {
    text: {
      primary: "#FDFDFD",
    },
    radiesseText: createColor("#1AAFCD"),
    debianRed: createColor("#C7005D"),
    titaniumYellow: createColor("#F1E307"),
    smalt: createColor("#002FA1"),
    eliteWhite: createColor("#ffffff"),
    bocotureBlue: createColor("#0279C1", {
      light: "#EEF9FF",
    }),
    radiesseBlue: createColor("#1C3754", {
      light: "#1AAFCD",
    }),
    radiesseDots: createColor("#00A6C8", {
      light: "#1C3754",
    }),
    bgGrey: createColor("#E9E7E7", {
      light: "#F8F4F4",
      dark: "#D2D2D2",
    }),
    ultherapyYellow: createColor("#FCB43A", {
      light: "#FFF8EC",
    }),
    ultherapyDots: createColor("#ED7204", {
      light: "#ED7204",
    }),
    biorepeelDots: createColor("#008083", {
      light: "#008083",
    }),
    biorepeel: createColor("#008083", {
      light: "#DBE9E9",
    }),
    belotero: createColor("#ED7204", {
      light: "#F8E9DC",
    }),
    belotero_revive: createColor("#19D3C5"),
    belotero_soft: createColor("#ebb401"),
    belotero_balance: createColor("#de6e01"),
    belotero_intense: createColor("#cd004a"),
    belotero_volume: createColor("#530161"),
    belotero_lips: createColor("#cf0000"),
    infoGrey: createColor("#8A8A8A"),
    black: createColor("#000000"),
  },
});
