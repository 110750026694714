import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { VerticalGrid } from "../HorizontalGrid/HorizontalGrid";
import { RightIcon } from "../Icons/ArrowUP";
import { ModalDOMRect } from "../../redux/modal.reducer";

interface BeforeAndAfterProps {
  before: string;
  after: string;
  rect?: ModalDOMRect;
  enabled?: boolean;
}

const BeforeImage = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
`;

const AfterImage = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

export function BeforeAndAfter({
  before,
  after,
  rect,
  enabled,
}: BeforeAndAfterProps) {
  const [dragPosition, setDragPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);
  const line = "0.1rem";
  const diameter = 2;
  const ref = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const handleDrag: DraggableEventHandler = (_, { x, y }) => {
    if (circleRef.current !== null && imageRef.current !== null) {
      setDragPosition({ x, y });
    }
  };

  const polygonX = useMemo(
    () => (dragPosition !== undefined ? `${dragPosition.x}px` : `50%`),
    [dragPosition]
  );

  useEffect(() => {
    if (imageRef.current !== null) {
      const maxPos = imageRef.current.offsetWidth;
      setDragPosition({ x: maxPos / 2, y: 0 });
    }
  }, []);

  return (
    <Box
      ref={ref}
      width={"100%"}
      height={"100%"}
      position={"relative"}
      overflow={"hidden"}
    >
      <AfterImage src={after} ref={imageRef} draggable={false} />

      <BeforeImage
        src={before}
        style={{
          clipPath: `polygon(0 0, ${polygonX} 0, ${polygonX} 100%, 0% 100%)`,
        }}
        draggable={false}
      />

      {dragPosition !== undefined ? (
        enabled ? (
          <Draggable
            axis="x"
            bounds="parent"
            position={dragPosition}
            onDrag={handleDrag}
          >
            <div
              ref={circleRef}
              style={{
                height: "100%",
                width: "2rem",
                position: "absolute",
                marginLeft: "-1rem",
              }}
            >
              <VerticalGrid
                type="container"
                rows={["auto", `${diameter}rem`, "auto"]}
              >
                <VerticalGrid type="item">
                  <Grid container justifyContent="center" height={"100%"}>
                    <div
                      style={{
                        width: line,
                        height: "100%",
                        backgroundColor: "white",
                      }}
                    ></div>
                  </Grid>
                </VerticalGrid>
                <VerticalGrid type="item">
                  <div
                    style={{
                      border: `${line} solid white`,
                      width: `${diameter}rem`,
                      height: `${diameter}rem`,
                      borderRadius: `${diameter}rem`,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      height={"100%"}
                      width={"100%"}
                      alignContent={"center"}
                    >
                      <RightIcon height={`${diameter / 2}rem`} />
                    </Grid>
                  </div>
                </VerticalGrid>
                <VerticalGrid type="item">
                  <Grid container justifyContent="center" height={"100%"}>
                    <div
                      style={{
                        width: line,
                        height: "100%",
                        backgroundColor: "white",
                      }}
                    ></div>
                  </Grid>
                </VerticalGrid>
              </VerticalGrid>
            </div>
          </Draggable>
        ) : (
          <div
            ref={circleRef}
            style={{
              height: "100%",
              width: "2rem",
              position: "absolute",
              marginLeft: "-1rem",
              left: dragPosition.x,
            }}
          >
            <VerticalGrid
              type="container"
              rows={["auto", `${diameter}rem`, "auto"]}
            >
              <VerticalGrid type="item">
                <Grid container justifyContent="center" height={"100%"}>
                  <div
                    style={{
                      width: line,
                      height: "100%",
                      backgroundColor: "white",
                    }}
                  ></div>
                </Grid>
              </VerticalGrid>
              <VerticalGrid type="item">
                <div
                  style={{
                    border: `${line} solid white`,
                    width: `${diameter}rem`,
                    height: `${diameter}rem`,
                    borderRadius: `${diameter}rem`,
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    height={"100%"}
                    width={"100%"}
                    alignContent={"center"}
                  >
                    <RightIcon height={`${diameter / 2}rem`} />
                  </Grid>
                </div>
              </VerticalGrid>
              <VerticalGrid type="item">
                <Grid container justifyContent="center" height={"100%"}>
                  <div
                    style={{
                      width: line,
                      height: "100%",
                      backgroundColor: "white",
                    }}
                  ></div>
                </Grid>
              </VerticalGrid>
            </VerticalGrid>
          </div>
        )
      ) : (
        <></>
      )}
    </Box>
  );
}
