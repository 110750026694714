export function addAlpha(color: string, opacity: number) {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  }
}

export function createObjectURL(file: Blob) {
  return window.URL.createObjectURL(file);
}

function calculatePointZ(X: Point, Y: Point, fraction: number) {
  const Z = {
    x: X.x + fraction * (Y.x - X.x),
    y: X.y + fraction * (Y.y - X.y),
  };

  return Z;
}

export type Point = {
  x: number;
  y: number;
};

export type Polygon = {
  type: "polygon";
  points: Array<Point>;
};

export type Circle = {
  type: "circle";
  center: Point;
  size: number;
};
export type ClipPath = Polygon | Circle;

export function getInitialClipPath(e: ClipPath): ClipPath {
  switch (e.type) {
    case "polygon":
      return { type: "polygon", points: getPolygonOfCenter(e.points) };
    case "circle":
      return { type: "circle", center: e.center, size: 0 };
  }
}

export function getCenterOfClipPath(e: ClipPath): Point | null {
  if (e.type === "circle") {
    return e.center;
  } else if (e.type === "polygon") {
    return getCenter(e.points);
  } else {
    return null;
  }
}

function getCenter(polygon: Array<Point>): Point | null {
  if (polygon.length === 0) {
    return null; // Return null for an empty array of points
  }

  // Initialize variables to store the sum of x and y coordinates
  let sumX = 0;
  let sumY = 0;

  // Iterate through each point and accumulate the sum of coordinates
  for (const point of polygon) {
    sumX += point.x;
    sumY += point.y;
  }

  // Calculate the centroid by averaging the coordinates
  const centroid = {
    x: sumX / polygon.length,
    y: sumY / polygon.length,
  };
  return centroid;
}
function getPolygonOfCenter(polygon: Array<Point>): Array<Point> {
  const centroid = getCenter(polygon);
  if (centroid) {
    return polygon.map((_) => centroid);
  } else {
    return [];
  }
}

function offsetPolygon(polygon: Array<Point>, fraction: number): Array<Point> {
  const centroid = getCenter(polygon);
  if (centroid) {
    return polygon.map((e) => calculatePointZ(centroid, e, fraction));
  } else {
    return [];
  }
}

export function validateClipPath(e: ClipPath | string): ClipPath {
  if (typeof e === "string") {
    const points = e.split(",").map<Point>((e) => {
      const [x, y] = e
        .replaceAll("%", "")
        .split(/\s+/)
        .filter((e) => e.length > 0)
        .map(Number)
        .filter((e) => e !== 0);
      return {
        x,
        y,
      };
    });
    return { type: "polygon", points };
  } else {
    return e;
  }
}

function convertToPolygonCSS(points: Array<Point>) {
  return `polygon(${points
    .map((e) => `${e.x.toFixed(2)}% ${e.y.toFixed(2)}%`)
    .join(",")})`;
}

export function convertClipPathToCSS(clipPath: ClipPath, fraction: number) {
  switch (clipPath.type) {
    case "polygon":
      return convertToPolygonCSS(offsetPolygon(clipPath.points, fraction));
    case "circle":
      return `circle(${fraction * clipPath.size}px at ${clipPath.center.x}% ${
        clipPath.center.y
      }%)`;
  }
}

export function heightToWidth(height: number) {
  return height * 1.77;
}

export function widthToHeight(width: number) {
  return width * 0.5625;
}
export async function adjustVolume(
  element: HTMLMediaElement | null,
  newVolume: number,
  {
    duration = 1000,
    easing = swing,
    interval = 13,
  }: {
    duration?: number;
    easing?: typeof swing;
    interval?: number;
  } = {}
): Promise<void> {
  if (element === null) return;

  const originalVolume = element.volume;
  const delta = newVolume - originalVolume;

  if (!delta || !duration || !easing || !interval) {
    element.volume = newVolume;
    return Promise.resolve();
  }

  const ticks = Math.floor(duration / interval);
  let tick = 1;

  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (element.paused) {
        clearInterval(timer);
        resolve();
      }
      element.volume = originalVolume + easing(tick / ticks) * delta;
      if (element.volume === 0) {
        element.pause();
      }
      if (++tick === ticks + 1) {
        clearInterval(timer);
        resolve();
      }
    }, interval);
  });
}

export function swing(p: number) {
  return 0.5 - Math.cos(p * Math.PI) / 2;
}

// Function to perform a hit test
export function performHitTest(
  x: number,
  y: number,
  width: number,
  height: number,
  layers: Array<HTMLImageElement>
): number {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context == null) return -1;

  // If no layer was hit, return -1 or some appropriate value
  return layers.findIndex((image) => {
    // Clear the canvas for each layer
    canvas.width = width;
    canvas.height = height;

    // Draw the current layer onto the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    // Check if the point (x, y) is within the non-transparent area of the current layer
    try {
      const pixelData = context.getImageData(x, y, 1, 1).data;
      const alpha = pixelData[3];

      // The point is within a non-transparent area of this layer
      return alpha > 0;
    } catch (error) {
      return false;
    }
  });
}
