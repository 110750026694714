import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ImageKeys } from "../utils/assets";
import { LayerSelection, SelectionPoint } from "../pages/prodotti/VisoPage";
import { useAppSelector } from "./hooks";
import { useMemo } from "react";
import { ClipPath, getInitialClipPath } from "../utils/functions";
import { layersData } from "../constants/viso.constants";

type VisoState = {
  currentFrame: number;
  rotationEnable: boolean;
  layerSelected?: ImageKeys;
  layer?: LayerSelection;
  selectedPoint?: SelectionPoint;
  status: "initializing" | "fullscreen" | "detail" | "muscles";
  clipPathImage?: {
    src: string;
    clipPath: ClipPath;
  };
  scale?: number;
  translateY?: number;
};

export const visoSlice = createSlice({
  name: "viso",
  initialState: {
    scale: 1,
    rotationEnable: false,
    status: "fullscreen",
    currentFrame: 21,
  } as VisoState,
  reducers: {
    setLayerSelected: (state, action: PayloadAction<ImageKeys | undefined>) => {
      state.layerSelected = action.payload;
    },
    showLayerDetail: (state) => {
      if (state.layerSelected !== undefined) {
        state.layer = layersData[state.layerSelected];
        state.status = "detail";
      }
    },
    setSelectedPoint: (
      state,
      action: PayloadAction<{
        clipPath: {
          src: string;
          clipPath: ClipPath;
        };
        selectedPoint: SelectionPoint;
      }>
    ) => {
      state.rotationEnable = false;
      state.clipPathImage = action.payload.clipPath;
      state.selectedPoint = action.payload.selectedPoint;
    },
    unselectePoint(state) {
      if (state.clipPathImage !== undefined) {
        state.clipPathImage = {
          clipPath: getInitialClipPath(state.clipPathImage.clipPath),
          src: state.clipPathImage.src,
        };
      }
      state.selectedPoint = undefined;
    },
    toggleRotation: (state) => {
      if (state.selectedPoint === undefined) {
        state.rotationEnable = !state.rotationEnable;
      }
    },
    goBack: (state) => {
      state.layer = undefined;
      state.status = "fullscreen";
      state.clipPathImage = undefined;
      state.selectedPoint = undefined;
    },
    resetViso: (state) => {
      state.layerSelected = undefined;
      state.layer = undefined;
      state.clipPathImage = undefined;
      state.selectedPoint = undefined;
      state.status = "fullscreen";
      state.currentFrame = 21;
      state.rotationEnable = false;
      state.scale = 1;
    },
    setFrame(state, action: PayloadAction<number>) {
      state.currentFrame = action.payload;
    },
    setTransform(
      state,
      action: PayloadAction<Pick<VisoState, "scale" | "translateY">>
    ) {
      state.scale = action.payload.scale;
      state.translateY = action.payload.translateY;
    },
    setStatus(state, action: PayloadAction<VisoState["status"]>) {
      state.status = action.payload;
    },
    initVisoWithoutRotation: (state) => {
      state.layerSelected = undefined;
      state.layer = undefined;
      state.clipPathImage = undefined;
      state.selectedPoint = undefined;
      state.currentFrame = 21;
      state.status = "fullscreen";
    },
    initVisoForRotation: (
      state,
      action: PayloadAction<Pick<VisoState, "scale" | "translateY"> | undefined>
    ) => {
      state.layer = undefined;
      //0 is the starting frame
      state.currentFrame = 0;
      state.status = "initializing";
      state.scale = action.payload?.scale;
      state.translateY = action.payload?.translateY;
    },
  },
});

export const {
  setLayerSelected,
  showLayerDetail,
  goBack,
  setSelectedPoint,
  unselectePoint,
  toggleRotation,
  setFrame,
  setStatus,
  initVisoForRotation,
  initVisoWithoutRotation,
  resetViso,
  setTransform,
} = visoSlice.actions;

export default visoSlice.reducer;

export function useImageTransform() {
  const { scale, translateX, translateY } = useAppSelector((state) => ({
    scale: state.viso.layer?.scale ?? state.viso.scale,
    translateX: state.viso.layer?.translateX,
    translateY: state.viso.layer?.translateY ?? state.viso.translateY,
  }));

  return useMemo(
    () =>
      `${scale ? `scale(${scale})` : ""}translate(${translateX ?? 0}%, ${
        translateY ?? 0
      }%)`,
    [scale, translateX, translateY]
  );
}
