import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "./hooks";

export enum ElitePages {
  MAIN = "main",
  ADVANCED_TRAINING = "advanced-training",
  IN_CLINIC = "in-clinic",
  DIGITAL_MARKETING = "digital-marketing",
  SCIENTIFIC_NETWORK = "scientific-network",
  JOINT_BUSINESS_PLAN = "joint-business-plan",
}

export enum AdvancedTrainingOpts {
  INTERNATIONAL_MASTERCLASS = "international-masterclass",
  INTERNATIONAL_MEXS = "international-mexs",
  MEXS_ITALY = "mexs-italy",
  HANDS_ON = "hands-on",
  PRIVATE_FACE_TO_FACE = "private-face-to-face",
  STAFF_TRAINING = "staff-training",
  EXPERT_ROUND_TABLE = "expert-round-table",
  INTERNATIONAL_CONGRESS_AMWC = "international-congress-amwc",
  INTERNATIONAL_CONGRESS_IMCAS = "international-congress-imcas",
}

type EliteState = {
  page: ElitePages;
  circles: Array<{
    id: ElitePages;
    options: Array<{ text: string; value: any }>;
    position: number;
    selected: number;
    isDraggingUser: boolean;
    isAutoRotating: boolean;
    velocity: number;
  }>;
};

export const initialAdvancedTraining = {
  id: ElitePages.ADVANCED_TRAINING,
  options: [
    {
      text: "International Masterclass",
      value: AdvancedTrainingOpts.INTERNATIONAL_MASTERCLASS,
    },
    {
      text: "International MEXS",
      value: AdvancedTrainingOpts.INTERNATIONAL_MEXS,
    },
    { text: "MEXS Italy", value: AdvancedTrainingOpts.MEXS_ITALY },
    {
      text: "Hands-on Master Courses",
      value: AdvancedTrainingOpts.HANDS_ON,
    },
    {
      text: "Private Face-to-Face",
      value: AdvancedTrainingOpts.PRIVATE_FACE_TO_FACE,
    },
    {
      text: "Staff training",
      value: AdvancedTrainingOpts.STAFF_TRAINING,
    },
    {
      text: "Expert Round table",
      value: AdvancedTrainingOpts.EXPERT_ROUND_TABLE,
    },
    {
      text: "International Congress AMWC",
      value: AdvancedTrainingOpts.INTERNATIONAL_CONGRESS_AMWC,
    },
    {
      text: "International Congress IMCAS",
      value: AdvancedTrainingOpts.INTERNATIONAL_CONGRESS_IMCAS,
    },
  ],
  position: 4,
  selected: 1,
  isDraggingUser: false,
  isAutoRotating: false,
  velocity: 1,
};
const initialState: EliteState = {
  circles: [
    {
      id: ElitePages.MAIN,
      options: [
        { text: "Advanced Training", value: ElitePages.ADVANCED_TRAINING },
        { text: "In-clinic", value: ElitePages.IN_CLINIC },
        { text: "Digital Marketing", value: ElitePages.DIGITAL_MARKETING },
        { text: "Scientific Network", value: ElitePages.SCIENTIFIC_NETWORK },
        {
          text: "Joint Business Plan",
          value: ElitePages.JOINT_BUSINESS_PLAN,
        },
      ],
      position: 2,
      selected: 1,
      isDraggingUser: false,
      isAutoRotating: false,
      velocity: 1,
    },
    initialAdvancedTraining,
  ],
  page: ElitePages.MAIN,
};

export const eliteSlice = createSlice({
  name: "elite",
  initialState: initialState,
  reducers: {
    resetAdvancedTraining(state) {
      return {
        ...state,
        circles: state.circles.map((circle) => {
          if (circle.id === ElitePages.ADVANCED_TRAINING) {
            return initialAdvancedTraining;
          }
          return circle;
        }),
      };
    },
    updatePosition: (
      state,
      action: PayloadAction<{
        id: ElitePages;
        offset: number;
        isDraggingUser: boolean;
        isAutoRotating: boolean;
        velocity: number;
      }>
    ) => {
      return {
        ...state,
        circles: state.circles.map((circle) => {
          if (circle.id === action.payload.id) {
            const position = Math.max(
              -Math.floor(circle.options.length / 2),
              Math.min(
                Math.floor(circle.options.length / 2),
                circle.position + action.payload.offset
              )
            );
            return {
              ...circle,
              selected:
                circle.options.length -
                (position + Math.floor(circle.options.length / 2)),
              position,
              isDraggingUser: action.payload.isDraggingUser,
              isAutoRotating: action.payload.isAutoRotating,
              velocity: action.payload.velocity,
            };
          }
          return circle;
        }),
      };
    },
    goToElitePage(state, action: PayloadAction<ElitePages>) {
      return {
        ...state,
        page: action.payload,
      };
    },
    resetElite(state) {
      return { ...initialState };
    },
  },
});

export const {
  updatePosition,
  goToElitePage,
  resetElite,
  resetAdvancedTraining,
} = eliteSlice.actions;
export default eliteSlice.reducer;

export function useSelectedCircleOption<T = any>(id: string): T | undefined {
  return useAppSelector((state) => {
    const circle = state.elite.circles.find((e) => e.id === id);
    if (circle === undefined) return undefined;
    return circle.options[circle.selected - 1]?.value;
  });
}
