import styled from "@emotion/styled";
import { ZIndexes } from "../InternalPage/InternalPage";

interface DoubleElementProps {
  id: string;
  left: JSX.Element;
  right: JSX.Element;
}

const WrappedElement = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export function DoubleElement({ left, right, id }: DoubleElementProps) {
  return (
    <div
      id={id}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <WrappedElement children={left} style={{ zIndex: ZIndexes.body }} />
      <WrappedElement
        style={{
          clipPath: "polygon(0% 0%, 60% 0%, 40% 100%, 0% 100%)",
          zIndex: ZIndexes.body + 1,
        }}
        children={right}
      />
    </div>
  );
}
