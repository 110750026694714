import { Box } from "@mui/material";
import { SVGProps, useEffect, useMemo, useRef, useState } from "react";
import { ModalDOMRect } from "../../redux/modal.reducer";
import { ZIndexes } from "../InternalPage/InternalPage";
import { Point } from "../../utils/functions";
import { useAppSelector } from "../../redux/hooks";

export type CircleCoord = ModalDOMRect & { center: Point };

interface CircleProdottiProps {
  children?(coord: Point): Array<JSX.Element>;
}
function calcolaCoordinate(
  r: number,
  angoloGradi: number,
  center: { x: number; y: number }
) {
  // Converti l'angolo da gradi a radianti
  var angoloRadianti = (angoloGradi * Math.PI) / 180;

  // Calcola le coordinate (x, y)
  var x = r * Math.cos(angoloRadianti);
  var y = r * Math.sin(angoloRadianti);

  return { x: x + center.x, y: y + center.y };
}

export function CircleProdotti(props: CircleProdottiProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect | undefined>();

  const [circleRect, setCircleRect] = useState<CircleCoord | undefined>();
  const [center, setCenter] = useState<Point | undefined>();

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, []);

  useEffect(() => {
    if (rect !== undefined) {
      const size = Math.min(rect.width, rect.height);
      const paddingX = (rect.width - size) / 2;
      const paddingY = (rect.height - size) / 2;
      setCircleRect({
        width: size,
        height: size,
        top: 0,
        left: (rect.width - size) / 2,
        center: {
          x: paddingX + size / 2,
          y: paddingY + size / 2,
        },
      });
      setCenter({
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height / 2,
      });
    }
  }, [rect]);

  const children = useMemo(
    () =>
      center === undefined
        ? undefined
        : props.children?.call(undefined, center),
    [props.children, center]
  );
  const length = useMemo(() => children?.length ?? 0, [children]);

  return (
    <Box sx={{ width: "100%", height: "100%" }} ref={ref} position={"relative"}>
      {circleRect && (
        <Box
          position={"absolute"}
          top={0}
          width={circleRect.width}
          height={circleRect.height}
          left={circleRect.left}
          zIndex={ZIndexes.background}
          visibility={"hidden"}
        ></Box>
      )}

      {circleRect && (
        <Box
          position={"absolute"}
          width={circleRect.width}
          height={circleRect.height}
          left={circleRect.left}
          top={circleRect.top}
        >
          <Circle />
        </Box>
      )}

      {circleRect &&
        rect &&
        children &&
        children.map((e, i) => {
          const res = calcolaCoordinate(
            circleRect.width / 2,
            (i + 1) * (360 / length) - 30,
            circleRect.center
          );

          return (
            <Box
              key={i}
              position={"absolute"}
              left={res.x}
              top={res.y}
              children={e}
              zIndex={ZIndexes.dialog}
            />
          );
        })}
    </Box>
  );
}

const Circle = (props: SVGProps<SVGSVGElement>) => {
  const color = useAppSelector((state) => state.prodotti.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.68 0 633.65 475.49"
      {...props}
    >
      <g>
        <path
          d="M541.53 92.79C481.69 32.95 402.12 0 317.5 0S153.31 32.96 93.47 92.79C33.64 152.63.68 232.19.68 316.82c-.06 46.76 10.52 93.79 30.6 136.03 3.49 7.34 7.31 14.62 11.35 21.62l.58 1.01h6.98l-1.76-3.04c-4.27-7.38-8.21-14.84-11.7-22.17a308.283 308.283 0 0 1-28.04-98.15 314.058 314.058 0 0 1 4.33-97.93c8.18-39.95 23.91-77.34 46.76-111.12 33.81-50.09 81.09-89.02 136.74-112.6a308.945 308.945 0 0 1 58.33-18.1c41.57-8.48 83.72-8.48 125.29 0 39.93 8.17 77.32 23.9 111.12 46.76 50.08 33.8 89.02 81.09 112.6 136.74a309.704 309.704 0 0 1 18.11 58.33c13.39 65.14 5.25 134.18-22.92 194.41a310.74 310.74 0 0 1-12.45 23.84l-1.76 3.04h6.97l.58-1.02c4.33-7.54 8.41-15.39 12.13-23.31 19.55-41.74 29.86-88.2 29.81-134.33 0-84.62-32.96-164.18-92.8-224.02Z"
          style={{
            fill: color,
            strokeWidth: 0,
            animation: "text-focus-in 1s ease-out",
            animationFillMode: "forwards",
          }}
        />
      </g>
    </svg>
  );
};
