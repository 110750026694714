import { GridProps, Typography, TypographyProps } from "@mui/material";
import { Colors } from "../../styles/theme";
import { CSSProperties } from "react";
import { VerticalGrid } from "../HorizontalGrid/HorizontalGrid";

interface TextCardProps {
  color?: Colors;
  title?: TypographyProps["children"];
  children?: GridProps["children"];
  sx?: GridProps["sx"];
  titlePb?: TypographyProps["pb"];
  visibility?: CSSProperties["visibility"];
  textAlign?: TypographyProps["textAlign"];
  overflow?: boolean;
}

export function TextCard(props: TextCardProps) {
  return (
    <VerticalGrid type="container" rows={["3rem", "auto"]}>
      <VerticalGrid
        type="item"
        sx={{ overflow: props.overflow ? "visible" : "hidden" }}
      >
        <Typography
          variant="h4"
          sx={{
            whiteSpace: props.overflow ? "nowrap" : undefined,
            color:
              props.color !== undefined ? `${props.color}.main` : undefined,
            visibility: props.visibility,
          }}
          textAlign={props.textAlign}
        >
          <i> {props.title}</i>
        </Typography>
      </VerticalGrid>
      <VerticalGrid type="item" children={props.children} />
    </VerticalGrid>
  );
}
