import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="24.87 20 335.15 265.08"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#fff;stroke-width:0}"}</style>
    </defs>
    <path
      d="M243 20c-64.51 0-117 52.49-117 117 0 24.34 7.48 46.97 20.25 65.71L24.89 272.94l7.01 12.12 122.98-71.17c21.46 24.57 53.01 40.11 88.11 40.11 6.07 0 12.17-.47 18.13-1.4 27.28-4.25 52.3-18.15 70.44-39.15A116.978 116.978 0 0 0 359.99 137c0-64.51-52.49-117-117-117Zm77.97 184.3c-15.98 18.49-37.99 30.73-62 34.47-5.25.82-10.62 1.23-15.98 1.23-56.79 0-103-46.21-103-103s46.21-103 103-103 103 46.21 103 103c0 24.72-8.89 48.62-25.03 67.3Z"
      className="cls-1"
    />
    <path
      d="M250 83.16h-14v46.87h-46.87v14H236v46.87h14v-46.87h46.87v-14H250V83.16z"
      className="cls-1"
    />
  </svg>
);
export { SvgComponent as LensIcon };
