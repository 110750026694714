/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Colors } from "../../styles/theme";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { InfoIcon } from "../Icons/InfoIcon";
import { ZIndexes } from "../InternalPage/InternalPage";

interface ScrollableElement {
  title?: string;
  subtitle?: TypographyProps["children"];
  element: JSX.Element;
}

interface VerticalScrollableCardProps {
  color: Colors;
  readyAfter?: number;
  title: string;
  selected?: number;
  hover?: GridProps["children"];
  elements: Array<ScrollableElement>;
  backgroundColor?: CSSProperties["backgroundColor"];
  onSelectedIndexChange?: (index: number) => void;
}
// 405 altezza card
// 223 altezza contenuto

function calculateIndex(
  height: number | undefined,
  elements: Array<any>,
  scrollTop: number
) {
  if (height === undefined || elements.length < 2) {
    return 0;
  } else {
    return Math.min(
      Math.max(Math.round(scrollTop / height) + 0, 0),
      elements.length - 1
    );
  }
}

export function VerticalScrollableCard(props: VerticalScrollableCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect | undefined>();
  const [scrollTop, setScrollTop] = useState(
    (rect?.height ?? 0) * (props.selected ?? 0)
  );
  const [canScroll, setCanScroll] = useState(false);

  const selectedIndex = useMemo(() => props.selected ?? 0, [props.selected]);

  const currentElement = useMemo(
    () => props.elements[selectedIndex],
    [props.elements, selectedIndex]
  );

  useEffect(() => {
    setTimeout(() => setCanScroll(true), (props.readyAfter ?? 0) * 1000);
  }, [props.readyAfter]);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (canScroll) {
        const element = e.target as HTMLDivElement;
        const scrollTop = element.scrollTop;
        setScrollTop(scrollTop);
        const index = calculateIndex(rect?.height, props.elements, scrollTop);
        props.onSelectedIndexChange?.call(undefined, index);
      }
    },
    [canScroll, props.elements, props.onSelectedIndexChange, rect?.height]
  );

  useEffect(() => {
    if (canScroll) {
      let timeout: NodeJS.Timeout | undefined;
      timeout = setTimeout(() => {
        const top = (rect?.height ?? 0) * selectedIndex;
        scrollableRef.current?.scrollTo({
          top,
          behavior: "smooth",
        });
      }, 200);
      return () => clearTimeout(timeout);
    } else {
      const top = (rect?.height ?? 0) * selectedIndex;
      scrollableRef.current?.scrollTo({
        top,
      });
    }
  }, [rect?.height, scrollTop, selectedIndex, canScroll, props.elements]);

  useEffect(() => {
    setRect(ref.current?.getBoundingClientRect());
  }, []);

  return (
    <Box
      ref={ref}
      component="div"
      style={{
        // backgroundColor: props.backgroundColor,
        width: "100%",
        height: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      {/* <Box ref={scrollableRef} sx={{ width: "100%", height: "100%" }}>
        {rect && (
          <Box
            style={{ width: rect.width, height: rect.height }}
            children={currentElement.element}
          />
        )}
      </Box> */}
      {/* TITLE AND SUBTITLE */}
      {(currentElement.subtitle !== undefined ||
        currentElement.title !== undefined) && (
        <Grid
          container
          direction="column"
          position="absolute"
          bottom={0}
          color={"white"}
          pl={4}
          pr={2}
          pb={2}
          pt={4}
          zIndex={ZIndexes.body + 1}
          justifyContent="flex-end"
          style={{
            background:
              "transparent linear-gradient(rgba(242,242,242,0) 0%, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0.6) 100% ) 0% 0% no-repeat padding-box",
          }}
        >
          {currentElement.title !== undefined && (
            <Typography variant="h5" style={{ whiteSpace: "break-spaces" }}>
              <i>{currentElement.title}</i>
            </Typography>
          )}
          {currentElement.subtitle !== undefined && (
            <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
              {currentElement.subtitle}
            </Typography>
          )}
        </Grid>
      )}
      {/* INFO */}
      <Grid
        container
        direction={"column"}
        position="absolute"
        top={0}
        zIndex={ZIndexes.body + 1}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignContent={"baseline"}
            alignItems={"baseline"}
            gap={2}
          >
            <InfoIcon />
            <Typography variant="h4" sx={{ color: `${props.color}.main` }}>
              <i>{props.title}</i>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs children={props.hover} />
      </Grid>
      {/* VERTICAL DOTS */}
      {props.elements.length > 1 && (
        <Grid
          position={"absolute"}
          top={0}
          right={0}
          zIndex={ZIndexes.body + 1}
        >
          <Grid item>
            <Grid container direction={"column"} gap={1} pt={2} px={3}>
              {props.elements.map((_, i) => (
                <Circle
                  color={props.color}
                  size={12}
                  active={i === selectedIndex}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* VERTICAL SCROLL  */}
      {props.elements.length > 1 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            overflowY: "auto" /* Enable scrolling for content */,
            backgroundColor: props.backgroundColor,
          }}
          ref={scrollableRef}
          top={0}
          right={0}
          onScroll={onScroll}
        >
          {rect &&
            props.elements.map((e, i) => (
              <Box key={i} style={{ width: rect.width, height: rect.height }}>
                {e.element}
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
}

export interface CircleProps {
  color: Colors;
  size: BoxProps["width"];
  active?: boolean;
  border?: number;
}
export function Circle(props: CircleProps) {
  return (
    <Box
      sx={{
        backgroundColor: `${props.color}.${props.active ? "main" : "light"}`,
        borderColor: `${props.color}.${props.active ? "main" : "light"}`,
        border: props.border,
        opacity: props.active ? 1 : 0.5,
      }}
      width={props.size}
      height={props.size}
      borderRadius={props.size}
    />
  );
}

export interface DotProps {
  color: CSSProperties["backgroundColor"];
  size: BoxProps["width"];
  active?: boolean;
  onClick?: () => void;
}
export function Dot(props: DotProps) {
  return (
    <Box
      sx={{
        backgroundColor: props.color,
        opacity: props.active ? 1 : 0.5,
        cursor: props.onClick !== undefined ? "pointer" : undefined,
      }}
      onClick={props.onClick}
      width={props.size}
      height={props.size}
      borderRadius={props.size}
    />
  );
}
