import { Route } from "../components/MainRouter/MainRouter";
import { DnaPage } from "../pages/chi_siamo";
import { MerzHome } from "../pages/MerzHome";
import { RadiessePage } from "../pages/prodotti/RadiessePage";
import { StandByPage } from "../pages/StandByPage";
import { VisoPage } from "../pages/prodotti/VisoPage";
import { BocoturePage } from "../pages/prodotti/BocoturePage";
import { BioRepeelPage } from "../pages/prodotti/BioRepeelPage";
import { UltherapyPage } from "../pages/prodotti/UltherapyPage";
import { BeloteroPage } from "../pages/prodotti/BeloteroPage";
import { ServiziPage } from "../pages/servizi";
import { ProdottiPage } from "../pages/prodotti";
import { ElitePageWithRoute } from "../pages/elite";

export const routes: Route<Routes> = {
  standby: <StandByPage />,
  home: <MerzHome />,
  inostriprodotti: <ProdottiPage />,
  viso: <VisoPage />,
  dna: <DnaPage />,
  bocoture: <BocoturePage />,
  biorepeel: <BioRepeelPage />,
  radiesse: <RadiessePage />,
  ultherapy: <UltherapyPage />,
  belotero: <BeloteroPage />,
  servizi: <ServiziPage />,
  elite: <ElitePageWithRoute />,
};

export enum Routes {
  standby = "standby",
  home = "home",
  inostriprodotti = "inostriprodotti",
  viso = "viso",
  dna = "dna",
  radiesse = "radiesse",
  bocoture = "bocoture",
  biorepeel = "biorepeel",
  ultherapy = "ultherapy",
  belotero = "belotero",
  servizi = "servizi",
  elite = "elite",
}
