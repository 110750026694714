/* eslint-disable jsx-a11y/alt-text */
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import { useRouter } from "../../components/MainRouter/MainRouter";
import { Routes } from "../../utils/routes";
import { Box, Grid, GridProps, Typography } from "@mui/material";
import { GenericCardProps } from "../../components/Card/GenericCard";
import { ImageKeys } from "../../utils/assets";
import { VideoKeys } from "../../utils/assets";
import { useDrag } from "react-use-gesture";
import { BeloteroLogo } from "../../components/Logos/BeloteroLogo";
import { Colors } from "../../styles/theme";
import { UltherapyLogo } from "../../components/Logos/UltherapyLogo";
import { BioRepeelLogo } from "../../components/Logos/BioRepeelLogo";
import { RadiesseLogo } from "../../components/Logos/RadiesseLogo";
import { CircleProdotti } from "../../components/CircleProdotti";
import { Line } from "../../components/Atoms/Lines";
import { useSingleAsset } from "../../components/AsssetsLoader";
import { initVisoForRotation } from "../../redux/viso.reducer";
import { useAppDispatch } from "../../redux/hooks";
import { useVisoSize } from "./VisoPage";
import { NeuromodulazioneLogo } from "../../components/Logos/NeuromodulazioneLogo";
import { animated, useSpring } from "react-spring";
import { ProdottiState, selectProduct } from "../../redux/prodotti.reducer";
import { Point } from "../../utils/functions";
import { Header } from "./BioRepeelPage";
import { easeOutQuad } from "../../constants";

export const genericAnimation: CSSProperties = {
  opacity: 0,
  animation: "blur 2s ease-out",
  // animationDelay: "2s",
  animationFillMode: "forwards",
};

export function ProdottiPage() {
  const { currentRoute, args, changeRoute } = useRouter<DOMRect>();

  const isActive = useMemo(
    () => currentRoute === Routes.inostriprodotti,
    [currentRoute]
  );

  if (isActive) {
    return (
      <InternalPage
        color="debianRed"
        rect={args}
        backgroundColor="black"
        goUpper={() => changeRoute(Routes.home, { showPrevious: false })}
        goBack={() => changeRoute(Routes.dna, { showPrevious: false })}
        goNext={() => changeRoute(Routes.servizi, { showPrevious: false })}
        sectionHeader={
          <Header color="debianRed" showEverywhere>
            <Typography variant="h5" textAlign="center">
              IL <strong>FUTURO</strong> DELLA <i>bellezza</i>
            </Typography>
            <Typography
              variant="body1"
              style={{
                opacity: 0,
                animation: "blur 3s ease-out",
                animationDelay: "1s",
                animationFillMode: "forwards",
                textAlign: "center",
              }}
            >
              Trascina la card del prodotto al centro o seleziona il modello 3D
            </Typography>
          </Header>
        }
        subsection={"I nostri prodotti"}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignContent={"flex-start"}
          height={"70%"}
          width={"100%"}
          pt={20}
        >
          <Grid item xs={10} height={"100%"}>
            <CircleProdotti>
              {(coord) => [
                <DraggableCard
                  route={Routes.biorepeel}
                  coord={coord}
                  product={"biorepeel"}
                  child={(rect) => (
                    <LogoCard
                      color="biorepeel"
                      height={rect.height}
                      width={rect.width}
                      position="bottomRight"
                      // onClick={() =>
                      //   changeRoute(Routes.biorepeel, { showPrevious: false })
                      // }
                    >
                      {/** margin-top: 0.3rem */}
                      <BioRepeelLogo
                        width={"70%"}
                        style={{ marginTop: "0.3rem" }}
                      />
                    </LogoCard>
                  )}
                />,
                //START TO BOTTOM RIGHT
                <></>,
                <DraggableCard
                  route={Routes.belotero}
                  coord={coord}
                  product={"belotero"}
                  child={(rect) => (
                    <LogoCard
                      color="belotero"
                      height={rect.height}
                      width={rect.width}
                      position="bottomLeft"
                      // onClick={() =>
                      //   changeRoute(Routes.belotero, { showPrevious: false })
                      // }
                    >
                      <BeloteroLogo width={"60%"} />
                    </LogoCard>
                  )}
                />,
                <DraggableCard
                  route={Routes.radiesse}
                  coord={coord}
                  product={"radiesse"}
                  child={(rect) => (
                    <LogoCard
                      color="radiesseBlue"
                      height={rect.height}
                      width={rect.width}
                      position="left"
                      // onClick={() =>
                      //   changeRoute(Routes.radiesse, { showPrevious: false })
                      // }
                    >
                      <RadiesseLogo width={"60%"} />
                    </LogoCard>
                  )}
                />,
                <DraggableCard
                  route={Routes.bocoture}
                  coord={coord}
                  product={"neuromodulazione"}
                  child={(rect) => (
                    <LogoCard
                      color="bocotureBlue"
                      height={rect.height}
                      width={rect.width}
                      position="top"
                      // onClick={() =>
                      //   changeRoute(Routes.bocoture, { showPrevious: false })
                      // }
                    >
                      <NeuromodulazioneLogo width={"90%"} />
                    </LogoCard>
                  )}
                />,
                <DraggableCard
                  route={Routes.ultherapy}
                  coord={coord}
                  product={"ultherapy"}
                  child={(rect) => (
                    <LogoCard
                      color="ultherapyYellow"
                      height={rect.height}
                      width={rect.width}
                      position="right"
                      // onClick={() => {
                      //   changeRoute(Routes.ultherapy, { showPrevious: false });
                      // }}
                    >
                      <UltherapyLogo width={"70%"} fill="black" />
                    </LogoCard>
                  )}
                />,
              ]}
            </CircleProdotti>
          </Grid>
        </Grid>

        <Box
          position={"fixed"}
          bottom={"0"}
          width={"100%"}
          zIndex={ZIndexes.dialog - 10}
        >
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            width={"100%"}
          >
            <Grid item position={"relative"} width={"25vw"}>
              <Viso />
            </Grid>
          </Grid>
        </Box>
        <BGVideo video="i_nostri_prodotti_video_sfondo" />
      </InternalPage>
    );
  } else {
    return <></>;
  }
}

interface BGVideoProps {
  video: VideoKeys;
}
export function BGVideo(props: BGVideoProps) {
  const sfondo = useSingleAsset<VideoKeys>(props.video);
  return (
    <Box
      width={"100%"}
      height={"100%"}
      position={"fixed"}
      top={0}
      zIndex={ZIndexes.background}
    >
      <video
        src={sfondo}
        width={"100%"}
        autoPlay
        muted
        loop
        style={{ position: "absolute", bottom: 0, objectFit: "cover" }}
      />
    </Box>
  );
}

export function BGImage(props: { image: ImageKeys; opacity?: number }) {
  const img = useSingleAsset<ImageKeys>(props.image);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      position={"fixed"}
      top={0}
      zIndex={ZIndexes.background + 1}
    >
      <img
        src={img}
        height={"100%"}
        width={"100%"}
        style={{
          position: "absolute",
          top: 0,
          objectFit: "cover",
          opacity: props.opacity,
          animation: "blur 2s ease-out",
        }}
      />
    </Box>
  );
}

function Viso() {
  const imgRef = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();
  const { changeRoute } = useRouter<DOMRect>();
  const viso = useSingleAsset<ImageKeys>("viso1");

  const size = useVisoSize();

  return size ? (
    <img
      ref={imgRef}
      src={viso}
      width={"auto"}
      style={{
        width: "100%",
        height: "auto",
        position: "absolute",
        bottom: 0,
        animationName: "bottom-up",
        animationDuration: "2s",
        animationTimingFunction: easeOutQuad,
        animationFillMode: "both",
      }}
      onClick={() => {
        const rect = imgRef.current?.getBoundingClientRect();
        if (rect) {
          dispatch(
            initVisoForRotation({
              scale: rect.width / size.width,
              translateY: 80,
            })
          );
          changeRoute(Routes.viso, { showPrevious: false, args: rect });
        }
      }}
    ></img>
  ) : (
    <></>
  );
}

interface DraggableCardProps {
  product: ProdottiState["selectedProduct"];
  child: GenericCardProps["mediaChild"];
  coord: Point;
  route: Routes;
}

function isPointInsideBox(
  x: number,
  y: number,
  cx: number,
  cy: number,
  w: number,
  h: number
): boolean {
  const halfW = w / 2;
  const halfH = h / 2;
  const left = cx - halfW;
  const right = cx + halfW;
  const top = cy - halfH;
  const bottom = cy + halfH;

  return x >= left && x <= right && y >= top && y <= bottom;
}

function DraggableCard({ product, child, coord, route }: DraggableCardProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));
  const dispatch = useAppDispatch();
  const { changeRoute } = useRouter<DOMRect>();

  const [rect, setRect] = useState<DOMRect | undefined>();
  const bind = useDrag(
    ({ down, movement: [mx, my] }) => {
      dispatch(selectProduct(down ? product : null));
      const currentRect = ref.current?.getBoundingClientRect();
      let isInside = false;
      if (currentRect !== undefined) {
        isInside = isPointInsideBox(
          currentRect.x,
          currentRect.y,
          coord.x,
          coord.y,
          200,
          200
        );
      }
      set({
        x: down ? mx : isInside ? mx : 0,
        y: down ? my : isInside ? my : 0,
      });
      if (isInside && !down) changeRoute(route, { showPrevious: false });
    },
    {
      useTouch: true,
    }
  );

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, []);

  return (
    <animated.div
      ref={ref}
      {...bind()}
      style={{ width: "14rem", height: "4.375rem", x, y, touchAction: "none" }}
      // bgcolor={backgroundColor}
    >
      {rect && child && child(rect)}
    </animated.div>
  );
}

interface LogoCardProps {
  color: Colors;
  children: JSX.Element;
  width: number;
  height: number;
  position: "left" | "right" | "top" | "bottomLeft" | "bottomRight";
  onClick?: GridProps["onClick"];
}

function LogoCard(props: LogoCardProps) {
  const marginStyle = useMemo(() => {
    switch (props.position) {
      case "left":
        return {
          marginLeft: `-${props.width / 1.5}px`,
          marginTop: `${props.height / 2}px`,
        };
      case "right":
        return {
          marginLeft: `-${props.width / 3}px`,
          marginTop: `${props.height / 2}px`,
        };
      case "top":
        return {
          marginLeft: `-${props.width / 2}px`,
          marginTop: `-${props.height / 2}px`,
        };
      case "bottomLeft":
        return { marginLeft: `-${props.width / 3}px` };
      case "bottomRight":
        return { marginLeft: `-${props.width / 1.5}px` };
      default:
        return {};
    }
  }, [props.position, props.width, props.height]);

  return (
    <Grid
      container
      height={props.height}
      width={props.width}
      bgcolor={"white"}
      direction={"column"}
      justifyContent={"space-around"}
      alignContent={"center"}
      style={{
        ...marginStyle,
        animation: "text-focus-in 2s ease-out 1s",
        animationFillMode: "both",
      }}
      onClick={props.onClick}
    >
      <Grid item xs>
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          height={"100%"}
        >
          {props.children}
        </Grid>
      </Grid>
      <Grid item height={6}>
        <Line color={props.color} height={6} />
      </Grid>
    </Grid>
  );
}
