import { useRef, useEffect } from "react";
import { transitionEnabled } from "../../constants";
import { useAppDispatch } from "../../redux/hooks";
import { useIsIntroViewed, setIntroViewed } from "../../redux/pages.reducer";
import { adjustVolume } from "../../utils/functions";
import { ZIndexes } from "../InternalPage/InternalPage";
import { Quality, getQuality } from "../../utils/assets";
import { Colors } from "../../styles/theme";

const duration = 2;

interface BackgroundIntroProps {
  id: string;
  type: "img" | "video";
  src?: string;
  whenQualityLow?: string;
  color?: Colors;
}

export function BackgroundIntro({
  id,
  src,
  type,
  whenQualityLow,
}: BackgroundIntroProps) {
  const isViewed = useIsIntroViewed(id);
  const dispatch = useAppDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current === null) return;
    if (isViewed) {
      adjustVolume(videoRef.current!, 0, { duration: 500 });
    } else {
      videoRef?.current?.play().catch(() => {});
    }
  }, [isViewed]);

  if (src === undefined) return <></>;

  if (getQuality() === Quality.low && whenQualityLow !== undefined) {
    return (
      <img
        src={whenQualityLow}
        alt=""
        style={{
          transition: !transitionEnabled
            ? undefined
            : `height ${duration}s ease-out`,
          position: "fixed",
          objectFit: "cover",
          width: "100%",
          top: 0,
          left: 0,
          zIndex: ZIndexes.intro,
          height: isViewed ? "0%" : "100%",
          opacity: 1,
        }}
      />
    );
  }

  switch (type) {
    case "img":
      return (
        <img
          src={src}
          alt=""
          style={{
            transition: !transitionEnabled
              ? undefined
              : `height ${duration}s ease-out`,
            position: "fixed",
            objectFit: "cover",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: ZIndexes.intro,
            height: isViewed ? "0%" : "100%",
            opacity: 1,
          }}
        />
      );
    case "video":
      return (
        <video
          ref={videoRef}
          src={src}
          autoPlay={true}
          disablePictureInPicture={true}
          onEnded={() => dispatch(setIntroViewed(id))}
          style={{
            transition: !transitionEnabled
              ? undefined
              : `height ${duration}s ease-out`,
            position: "fixed",
            objectFit: "cover",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: ZIndexes.navigation - 1,
            height: isViewed ? "0%" : "100%",
            opacity: isViewed ? "0.9" : 1,
          }}
        />
      );
  }
}
