import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="12 -0.2 476.1 31.5"
    {...props}
  >
    <path d="M33.1 30.6 17.3 8.5v22.1H12V.3h5.5L33 21.8V.3h5.3v30.4h-5.2zM44.8 30.6V.3h20.8V5H50.1v7.9h15.2v4.7H50.1V26h15.5v4.7H44.8zM71 .3h5.4v18.2c0 4.8 2.7 8 7.8 8s7.7-3.1 7.7-8V.3h5.4v18.4c0 7.6-4.3 12.6-13.1 12.6S71 26.2 71 18.8V.3zM121.1 30.6l-6.7-11.3h-5.3v11.3h-5.3V.3h13.3c6 0 9.9 3.9 9.9 9.5 0 5.4-3.6 8.3-7.2 8.9l7.4 11.9h-6.1zm-4.7-25.7h-7.3v9.7h7.3c3 0 5.2-1.9 5.2-4.8s-2.2-4.9-5.2-4.9zM146.6-.2c9.1 0 15.5 6.6 15.5 15.7s-6.4 15.7-15.5 15.7-15.5-6.6-15.5-15.7C131 6.4 137.4-.2 146.6-.2zm0 4.7c-6.1 0-10.1 4.7-10.1 11 0 6.2 3.9 11 10.1 11 6.1 0 10.1-4.7 10.1-11-.1-6.3-4-11-10.1-11zM193.6 30.6v-27l-11.1 27h-1l-11.1-27v27h-2.6V.3h3.9L182 25.4 192.3.2h3.9v30.4h-2.6zM217.4-.2c8.9 0 14.8 6.8 14.8 15.7 0 8.9-5.9 15.7-14.8 15.7s-14.8-6.8-14.8-15.7c0-8.9 5.9-15.7 14.8-15.7zm0 2.3c-7.4 0-12 5.6-12 13.3 0 7.7 4.6 13.3 12 13.3 7.3 0 12-5.7 12-13.3 0-7.7-4.7-13.3-12-13.3zM238.6 30.6V.3h9.7c9.4 0 15.3 6.9 15.3 15.2 0 8.4-5.9 15.2-15.3 15.2h-9.7zm2.6-2.3h7.1c8 0 12.6-5.7 12.6-12.8s-4.6-12.8-12.6-12.8h-7.1v25.6zM270.1.3h2.6V19c0 6.1 3.2 9.9 9.2 9.9 6 0 9.2-3.7 9.2-9.9V.3h2.6V19c0 7.5-4 12.2-11.8 12.2-7.8 0-11.8-4.7-11.8-12.2V.3zM301.3 30.6V.3h2.6v28h14.7v2.4h-17.3zM346.4 30.6l-3.1-7.5h-16.2l-3.1 7.5h-2.9L333.5.2h3.2l12.4 30.4h-2.7zM335.2 2.8l-7.2 18h14.5l-7.3-18zM352.3 30.6v-2.3l18.3-25.7h-18.3V.3h21.6v2.2l-18.3 25.8h18.6v2.4h-21.9zM380.2 30.6V.3h2.6v30.4h-2.6zM404-.2c8.9 0 14.8 6.8 14.8 15.7 0 8.9-5.9 15.7-14.8 15.7s-14.8-6.8-14.8-15.7c0-8.9 5.9-15.7 14.8-15.7zm0 2.3c-7.4 0-12 5.6-12 13.3 0 7.7 4.6 13.3 12 13.3 7.3 0 12-5.7 12-13.3 0-7.7-4.7-13.3-12-13.3zM446.9 30.6 427.8 4.4v26.2h-2.6V.3h2.6l19 25.9V.3h2.6v30.4h-2.5zM457 30.6V.3h19.3v2.4h-16.7v11.2H476v2.4h-16.4v12h16.7v2.4H457z" />
    <path
      d="M483.7 3.6h.2c.4 0 .9-.1.9-.7 0-.7-.6-.7-.9-.7h-.2v1.4zm1.4 2.3-1.4-1.8v1.8h-.6V1.7h.8c.3 0 1.6 0 1.6 1.2 0 .6-.4 1.1-1.1 1.2l1.4 1.8h-.7zm-.8-5.3c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.2-1.4 3.2-3.2 0-1.8-1.4-3.2-3.2-3.2m0-.6c2.2 0 3.8 1.6 3.8 3.8 0 2.2-1.6 3.8-3.8 3.8-2.2 0-3.8-1.6-3.8-3.8 0-2.2 1.6-3.8 3.8-3.8"
      style={{
        fill: "#000101",
      }}
    />
  </svg>
);
export { SvgComponent as NeuromodulazioneLogo };
