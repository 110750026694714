import {
  Box,
  Grid,
  GridProps,
  IconButton,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Line } from "../Atoms/Lines";
import { ArrowDownIcon } from "../Icons/ArrowUP";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Colors } from "../../styles/theme";
import { Media, VideoProps } from "../Atoms/Media";

export const defaultTransitionValue: CSSProperties["transition"] =
  "opacity .6s ease-in";

export interface HomeCardProps {
  sectionTitle: React.ReactNode;
  sectionHeader: JSX.Element;
  sectionTitleVisibilty?: TypographyProps["visibility"];
  video?: string;
  img?: string;
  videoOn?: boolean;
  color: Colors;
  onClick?: (rect: DOMRect) => void;
  onEnded?: VideoProps["props"]["onEnded"];
  sx?: GridProps["sx"];
}

export function HomeCard(props: HomeCardProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (videoRef.current === null) return;
    if (props.videoOn) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(() => {});
    } else {
      videoRef.current.pause();
    }
  }, [props.videoOn]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      spacing={0}
      sx={props.sx}
      onClick={() =>
        props.onClick?.call(undefined, ref.current!.getBoundingClientRect())
      }
    >
      <Grid
        ref={ref}
        container
        item
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Line color={props.color} height={15} />
        <Grid
          item
          py={3}
          children={props.sectionHeader}
          width={"100%"}
          alignSelf={"center"}
        />
      </Grid>
      <Grid item sx={{ position: "relative" }}>
        <Media
          type="img"
          props={{
            src: props.img,
            width: "100%",
            style: {
              objectFit: "cover",
            },
          }}
        />
        <Media
          type="video"
          props={{
            ref: videoRef,
            muted: true,
            src: props.video,
            width: "100%",
            style: {
              position: "absolute",
              top: 0,
              left: 0,
              objectFit: "cover",
              transition: defaultTransitionValue,
              opacity: props.videoOn ? 1 : 0,
            },
            onEnded: props.onEnded,
          }}
        />
        <TextBand
          text={props.sectionTitle}
          visibility={props.sectionTitleVisibilty}
        />
      </Grid>
      <Grid item pb={2}>
        <Grid container direction="column">
          <IconButton sx={{ color: "white" }}>
            <ArrowDownIcon width={"1rem"} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function TextBand(props: {
  text: React.ReactNode;
  visibility?: TypographyProps["visibility"];
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current?.offsetHeight]);
  return (
    <Box
      position={"absolute"}
      bottom={0}
      width={"100%"}
      height={"100%"}
      ref={ref}
    >
      <Typography
        position={"absolute"}
        variant="h3"
        visibility={props.visibility}
        textTransform={"uppercase"}
        sx={{
          top: `-${width}px`,
          right: 0,
          transform: "rotate(-90deg)",
          transformOrigin: "bottom right",
          width: height,
          height: width,
          lineHeight: 0.9,
          opacity: 0,
          animation: "blur 2s ease-in-out forwards",
        }}
        textAlign={"left"}
      >
        <span style={{ position: "absolute", bottom: "1.5rem", left: "2rem" }}>
          {props.text}
        </span>
      </Typography>
    </Box>
  );
}
