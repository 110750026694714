import { Box, Grid, Typography } from "@mui/material";
import { TextWithLines } from "../../components/Atoms/TextWithLines";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { RectangleButton } from "../../components/Atoms/RectangleButton";
import { LensIcon } from "../../components/Icons/LensIcon";
import { ImageKeys } from "../../utils/assets";
import { useAsset, useSingleAsset } from "../../components/AsssetsLoader";
import { VideoKeys } from "../../utils/assets";
import { useAppDispatch } from "../../redux/hooks";
import { useRef } from "react";
import { openModal } from "../../redux/modal.reducer";
import { ZIndexes } from "../../components/InternalPage/InternalPage";

export const VISIBILITA_ID = "elite_visibilita_digital";

export function DigitalMarketing() {
  const boxHeight = 3;
  const dispatch = useAppDispatch();
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2_1 = useRef<HTMLDivElement>(null);
  const ref2_2 = useRef<HTMLDivElement>(null);
  const ref2_3 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  // const ref5 = useRef<HTMLDivElement>(null);
  // const ref6 = useRef<HTMLDivElement>(null);

  const [
    elite_visibilita_digital,
    elite_content_creation_1,
    elite_content_creation_2,
    elite_content_creation_3,
    elite_content_creation_4,
    elite_content_creation_5,
    elite_content_creation_6,
    elite_content_creation_7,
    elite_content_creation_8,
    elite_content_creation_9,
    elite_content_creation_10,
    elite_pr,
  ] = useAsset<ImageKeys>([
    "elite_visibilita_digital",
    "elite_content_creation_1",
    "elite_content_creation_2",
    "elite_content_creation_3",
    "elite_content_creation_4",
    "elite_content_creation_5",
    "elite_content_creation_6",
    "elite_content_creation_7",
    "elite_content_creation_8",
    "elite_content_creation_9",
    "elite_content_creation_10",
    "elite_pr",
  ]);
  const elite_content_creation = useSingleAsset<VideoKeys>(
    "elite_content_creation_video"
  );
  return (
    <VerticalGrid type="container" rows={["7rem", "auto"]}>
      <VerticalGrid type="item"></VerticalGrid>
      <VerticalGrid type="item">
        <Grid
          container
          height={"100%"}
          width={"100%"}
          gap={5}
          direction={"column"}
        >
          <Grid item width={"100%"}>
            <TextWithLines animation="blur 1s ease-out">
              <Typography
                variant="h4"
                textAlign={"center"}
                style={{ animation: "text-focus-in 2s ease-out" }}
              >
                <strong>
                  Miglioriamo la tua
                  <br />
                  <i>brand awareness</i> tra i pazienti
                </strong>
              </Typography>
            </TextWithLines>
          </Grid>
          <Grid
            item
            xs
            style={{
              animation: "blur 2s ease-out",
              opacity: 0,
              animationDelay: "0.5s",
              animationFillMode: "forwards",
            }}
          >
            <Grid
              container
              direction={"column"}
              height={"100%"}
              px={10}
              justifyContent={"space-between"}
            >
              <VerticalGrid
                type="container"
                rows={[`${boxHeight}rem`, "auto", `${boxHeight}rem`, "auto"]}
              >
                <VerticalGrid type="item">
                  <HorizontalGrid
                    type="container"
                    columnSize={[
                      "2rem",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                    ]}
                    columns={7}
                    scrollable={false}
                    width="100%"
                    sx={{ height: "100%" }}
                  >
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderBottom: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Visibilità Digital</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderTop: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Consulenza Web & Social Media</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderBottom: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Content Creation</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{ borderBottom: "1px solid #707070" }}
                    ></HorizontalGrid>
                  </HorizontalGrid>
                </VerticalGrid>
                <VerticalGrid type="item">
                  <HorizontalGrid
                    type="container"
                    columnSize={[
                      "2rem",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                    ]}
                    columns={7}
                    scrollable={false}
                    width="100%"
                    sx={{ height: "100%" }}
                  >
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderLeft: "1px solid #707070",
                      }}
                    />
                    <HorizontalGrid type="item" sx={{ pt: 2 }}>
                      <RectangleButton
                        ref={ref1}
                        text="Scopri di più"
                        icon={<LensIcon width={"1.5rem"} />}
                        onClick={() => {
                          dispatch(
                            openModal({
                              id: VISIBILITA_ID,
                              modalData: {
                                type: "carousel",
                                items: [
                                  {
                                    src: elite_visibilita_digital,
                                    type: "image",
                                    style: {
                                      height: "100%",
                                    },
                                  },
                                ],
                              },
                              rect: ref1.current!.getRect(),
                            })
                          );
                        }}
                      />
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item" sx={{ overflow: "visible" }}>
                      <Box
                        width={"25rem"}
                        height={"100%"}
                        position={"relative"}
                      >
                        <Box
                          position={"absolute"}
                          top={0}
                          left={0}
                          zIndex={ZIndexes.body + 1}
                        >
                          <Typography py={2}>
                            Mettiamo a disposizione nostri consulenti
                            selezionati, <br />
                            con expertise consolidata in medicina estetica, che{" "}
                            <br />
                            potranno darti consigli profittevoli in ambito di{" "}
                            <br />
                            comunicazione. <br />
                          </Typography>
                          <Grid
                            container
                            direction={"row"}
                            width={"25rem"}
                            justifyContent={"space-between"}
                          >
                            <Grid
                              item
                              xs={3.8}
                              ref={ref2_1}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalData: {
                                      type: "pdf",
                                      pdf: "elite_belotero_",
                                      pages: 18,
                                    },
                                    rect: ref2_1.current!.getRect(),
                                  })
                                );
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "black",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  color={"white"}
                                  textAlign={"center"}
                                  py={1}
                                >
                                  <i>Belotero</i>
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              sx={{ backgroundColor: "black" }}
                              xs={3.8}
                              ref={ref2_2}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalData: {
                                      type: "pdf",
                                      pdf: "elite_radiesse_",
                                      pages: 17,
                                    },
                                    rect: ref2_2.current!.getRect(),
                                  })
                                );
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "black",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  color={"white"}
                                  textAlign={"center"}
                                  py={1}
                                >
                                  <i>Radiesse</i>
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              sx={{ backgroundColor: "black" }}
                              xs={3.8}
                              ref={ref2_3}
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    modalData: {
                                      type: "pdf",
                                      pdf: "elite_ultherapy_",
                                      pages: 18,
                                    },
                                    rect: ref2_3.current!.getRect(),
                                  })
                                );
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "black",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  color={"white"}
                                  textAlign={"center"}
                                  py={1}
                                >
                                  <i>Ultherapy</i>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item">
                      <Typography py={2}>
                        In partnership con MERZ AESTHETICS:
                        <br />
                        <b>CIAK SI GIRA!</b>
                        <br />
                        Un'equipe di esperti ti supporterà nella creazione di
                        <br />
                        contenuti foto & video coerenti alla tua Brand Identity
                        <br />
                        per valorizzare la tua pratica clinica tra i pazienti,
                        <br />
                        sfruttando i canali di comunicazione.
                      </Typography>
                      <RectangleButton
                        ref={ref3}
                        text="Scopri di più"
                        icon={<LensIcon width={"1.5rem"} />}
                        onClick={() => {
                          const rect = ref3.current!.getRect();
                          dispatch(
                            openModal({
                              modalData: {
                                type: "carousel",
                                items: [
                                  {
                                    src: elite_content_creation!,
                                    type: "video",
                                    style: {
                                      height: "100%",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_1,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_2,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_3,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_4,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_5,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_6,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_7,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_8,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_9,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                  {
                                    src: elite_content_creation_10,
                                    type: "image",
                                    style: {
                                      width: "auto",
                                    },
                                  },
                                ],
                              },
                              rect,
                            })
                          );
                        }}
                      />
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderRight: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                  </HorizontalGrid>
                </VerticalGrid>
                <VerticalGrid type="item">
                  <HorizontalGrid
                    type="container"
                    columnSize={[
                      "2rem",
                      "15rem",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                    ]}
                    columns={7}
                    scrollable={false}
                    width="100%"
                    sx={{ height: "100%" }}
                  >
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderTop: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderTop: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>PR</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        border: "1px solid #707070",
                      }}
                    >
                      <Center>
                        <Typography>
                          <i>Partnership con TUAME.it</i>
                        </Typography>
                      </Center>
                    </HorizontalGrid>
                    <HorizontalGrid
                      type="item"
                      sx={{
                        borderTop: "1px solid #707070",
                      }}
                    ></HorizontalGrid>
                  </HorizontalGrid>
                </VerticalGrid>
                <VerticalGrid type="item">
                  <HorizontalGrid
                    type="container"
                    columnSize={[
                      "2rem",
                      "15rem",
                      "15rem",
                      "auto",
                      "15rem",
                      "auto",
                    ]}
                    columns={7}
                    scrollable={false}
                    width="100%"
                    sx={{ height: "100%" }}
                  >
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item">
                      <HorizontalGrid type="item">
                        <Typography py={2}>
                          Il nostro ufficio stampa è a disposizione per
                          <br />
                          intervistarti sui nuovi trend in medicina estetica e
                          <br />
                          dare visibilità alla tua visione di medicina
                          <br />
                          estetica sui principali magazine nazionali.
                        </Typography>
                        <RectangleButton
                          ref={ref4}
                          text="Scopri di più"
                          icon={<LensIcon width={"1.5rem"} />}
                          onClick={() => {
                            dispatch(
                              openModal({
                                modalData: {
                                  type: "carousel",
                                  items: [
                                    {
                                      src: elite_pr,
                                      type: "image",
                                      style: {
                                        height: "70vh",
                                      },
                                    },
                                  ],
                                },
                                rect: ref4.current!.getRect(),
                              })
                            );
                          }}
                        />
                      </HorizontalGrid>
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                    <HorizontalGrid type="item">
                      <Typography py={2}>
                        Entra a far parte della community di esperti che
                        <br />
                        promuovono un concetto etico di Medicina Estetica.
                      </Typography>
                    </HorizontalGrid>
                    <HorizontalGrid type="item"></HorizontalGrid>
                  </HorizontalGrid>
                </VerticalGrid>
              </VerticalGrid>
            </Grid>
          </Grid>
        </Grid>
      </VerticalGrid>
    </VerticalGrid>
  );
}

export function Center(props: { children: React.ReactNode }) {
  return (
    <Grid
      container
      width={"100%"}
      height={"100%"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Grid item children={props.children} />
    </Grid>
  );
}
