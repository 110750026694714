import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Livello_1"
    x={0}
    y={0}
    viewBox="0.8 0.7 36.2 35.6"
    {...props}
  >
    <path d="M17.8 14.6v10.6L8.4 20V9.4zM20.2 14.6v10.6l9.4-5.2V9.4zM29.6 6.8 19 12.7 8.4 6.8 19 .7z" />
    <path d="m13.2 25.4-1.5 1.2 2.9 3.4-2.6-.1c-2.8-.2-5.3-1.6-7.1-3.8-1.2-1.5-2-3.4-2.1-5.3v-1.7c.2-1.9.9-3.8 2.1-5.3.5-.6 1.1-1.2 1.7-1.7V9.8c-2.6 1.5-4.6 4-5.4 7.1-.2.7-.3 1.4-.4 2.1v2c.1.6.2 1.3.4 2 1.3 4.9 5.6 8.4 10.6 8.7l2.6.2-3.3 2.9 1.2 1.5 5.1-4.4c.4-.3.4-1 .1-1.4l-4.3-5.1zM21.1 32h-.9v-1.9h.8c1.2 0 2.4 0 3.6-.1l1.2-.1c2.8-.2 5.3-1.6 7.1-3.8 1.2-1.5 2-3.4 2.1-5.3.1-.6.1-1.1 0-1.7-.2-1.9-.9-3.8-2.1-5.3-.5-.6-1.1-1.2-1.7-1.7V9.8c2.6 1.5 4.6 4 5.4 7.1.2.7.3 1.4.4 2.1v2c-.1.7-.2 1.4-.4 2.1C35.3 28 31 31.5 26 31.8l-1.3.1c-1.2 0-2.4.1-3.6.1z" />
  </svg>
);
export { SvgComponent as RotateIcon };
