import React, { useEffect, useRef, useState } from "react";
import { ZIndexes } from "../InternalPage/InternalPage";
import { Grid } from "@mui/material";

type GenericMediaProps<
  S extends string,
  E extends React.HTMLAttributes<T>,
  T
> = {
  type: S;
  props: React.DetailedHTMLProps<E, T>;
};

export type ImgProps = GenericMediaProps<
  "img",
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export type VideoProps = GenericMediaProps<
  "video",
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

// export type MaskedVideoProps = GenericMediaProps<
//   "masked_video",
//   React.VideoHTMLAttributes<HTMLVideoElement>,
//   HTMLVideoElement
// >;

export type MediaProps = ImgProps | VideoProps;

export function Media({ type, props }: MediaProps) {
  switch (type) {
    case "img":
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img {...props} />;
    case "video":
      return <video crossOrigin="anonymous" {...props} />;
  }
}

interface MaskedVideoProps {
  video: Omit<
    React.DetailedHTMLProps<
      React.VideoHTMLAttributes<HTMLVideoElement>,
      HTMLVideoElement
    >,
    "ref"
  >;
  onVideoInit?: (e: HTMLVideoElement) => void;
  rgbColor: Array<number>;
  colorThreshold?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let globalRgbColor: Array<number>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let globalColorTreshold: number = 0;

export const MaskedVideo = ({
  video: { style, autoPlay, ...other },
  onVideoInit,
  rgbColor,
  colorThreshold,
}: MaskedVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [rect, setRect] = useState<DOMRect | undefined>();

  globalRgbColor = rgbColor;
  globalColorTreshold = colorThreshold ?? 0;

  useEffect(() => {
    if (videoRef !== undefined && videoRef.current !== null) {
      onVideoInit?.call(undefined, videoRef.current);
    }
  }, [onVideoInit]);

  useEffect(() => {
    if (canvasRef && canvasRef?.current !== null && autoPlay) {
      videoRef?.current?.play().catch(() => {});
    }
  }, [autoPlay, rect]);

  useEffect(() => {
    if (
      canvasRef &&
      canvasRef.current !== null &&
      videoRef &&
      rect &&
      videoRef?.current !== null
    ) {
      let id: number | null = null;
      const listener = () => {
        const ctx = canvasRef!.current!.getContext("2d", {
          willReadFrequently: true,
        });
        if (ctx) {
          // id = requestAnimationFrame(applyColorToFrame(ctx, video, rect));
        }
      };
      videoRef.current.addEventListener("play", listener);
      return () => {
        if (id !== null) window.cancelAnimationFrame(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef?.current?.removeEventListener?.call(
          undefined,
          "play",
          listener
        );
      };
    }
  }, [rect]);

  return (
    <>
      {rect && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: ZIndexes.background,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent={"flex-end"}
            alignContent={"center"}
            height={"100%"}
            width={"100%"}
          >
            <Grid item>
              <canvas ref={canvasRef} width={rect.width} height={rect.height} />
            </Grid>
          </Grid>
        </div>
      )}
      <div style={{ position: "fixed" }}>
        <video
          crossOrigin="anonymous"
          ref={videoRef}
          {...other}
          style={{ ...style, opacity: 0 }}
          onLoadedData={() =>
            setRect(videoRef.current?.getBoundingClientRect())
          }
          onLoadedMetadataCapture={() =>
            setRect(videoRef.current?.getBoundingClientRect())
          }
        />
      </div>
    </>
  );
};
