import { Grid, Typography } from "@mui/material";
import { RectangleButton } from "../../components/Atoms/RectangleButton";
import { TextWithLines } from "../../components/Atoms/TextWithLines";
import { VerticalGrid } from "../../components/HorizontalGrid/HorizontalGrid";
import { TriangleSelection, BottomCircle } from "./utils";
import {
  ElitePages,
  goToElitePage,
  useSelectedCircleOption,
} from "../../redux/elite.reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PlayIcon } from "../../components/Icons/PlayIcon";
import { LensIcon } from "../../components/Icons/LensIcon";
import { openModal } from "../../redux/modal.reducer";
import { useMemo, useRef } from "react";
import { useAsset } from "../../components/AsssetsLoader";
import { VideoKeys } from "../../utils/assets";
import { usePageByMainCircle } from ".";
import { useWindowSize } from "@uidotdev/usehooks";

export function Main() {
  const { width } = useWindowSize();
  const isTablet = width && width < 900;
  const dispatch = useAppDispatch();
  return (
    <>
      <VerticalGrid type="container" rows={["7rem", "auto", "25rem"]}>
        <VerticalGrid type="item"></VerticalGrid>
        <VerticalGrid type="item">
          <Grid
            container
            height={"100%"}
            width={"100%"}
            rowSpacing={1}
            direction={"column"}
          >
            <Grid item width={"100%"}>
              <TextWithLines animation="text-focus-in 1s ease-out">
                <Typography
                  variant="h4"
                  sx={{
                    letterSpacing: "0.5rem",
                    fontWeight: "400",
                    animation: "text-focus-in 1s ease-out",
                  }}
                >
                  LA TUA <strong>VISIONE</strong> AL CENTRO DELLA NOSTRA{" "}
                  <strong>INNOVAZIONE</strong>.
                </Typography>
              </TextWithLines>
            </Grid>
            <Grid
              item
              width={"100%"}
              sx={{
                animation: "text-focus-in 1s ease-out",
              }}
            >
              <Grid
                container
                width={"100%"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      letterSpacing: "0.1rem",
                      textAlign: "center",
                      fontWeight: "400",
                      opacity: 0,
                      animation: "text-focus-in 2s ease-out",
                      animationFillMode: "forwards",
                      animationDelay: "1s",
                    }}
                  >
                    Elaboriamo servizi esclusivi su{" "}
                    <strong>misura per te</strong> per garantirti
                    <br />
                    una{" "}
                    <strong>
                      <i>partnership</i>
                    </strong>{" "}
                    solida, orientata allo sviluppo di business.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs
              sx={{
                animation: "text-focus-in 1s ease-out",
              }}
            >
              <Grid
                container
                direction={"column"}
                height={"100%"}
                justifyContent={"space-between"}
              >
                {/* <Grid item xs></Grid> */}
                <Grid item xs pb={3}>
                  <MainButtons />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"end"}
                    alignContent={"center"}
                    height={"100%"}
                    rowGap={1}
                  >
                    <Grid item>
                      <Typography fontWeight={400} style={{ opacity: "0.5" }}>
                        Scorri per selezionare l'area d'interesse
                      </Typography>
                    </Grid>
                    <Grid item alignSelf={"center"}>
                      <TriangleSelection id={ElitePages.MAIN} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VerticalGrid>
        <VerticalGrid type="item"></VerticalGrid>
      </VerticalGrid>
      <BottomCircle
        id={ElitePages.MAIN}
        height={50}
        rectFont={isTablet ? "2rem" : "1.5rem"}
        boxHeight={3}
        boxWidth={isTablet ? 18 : 15}
        onClick={(e) => dispatch(goToElitePage(e))}
      />
    </>
  );
}

function MainButtons() {
  const page = useSelectedCircleOption<ElitePages>(ElitePages.MAIN);
  const { selected } = useAppSelector((state) =>
    state.elite.circles.find((e) => e.id === ElitePages.MAIN)
  )!;

  const diff = useMemo(
    () => (selected - Math.floor(selected) === 0 ? 1 : 0),
    [selected]
  );
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [
    elite_advanced_training,
    elite_digital_marketing,
    elite_in_clinic,
    elite_joint_business_plan,
    elite_scientific_network,
  ] = useAsset<VideoKeys>([
    "elite_advanced_training",
    "elite_digital_marketing",
    "elite_in_clinic",
    "elite_joint_business_plan",
    "elite_scientific_network",
  ]);

  const video = useMemo(() => {
    switch (page) {
      case ElitePages.ADVANCED_TRAINING:
        return elite_advanced_training;
      case ElitePages.DIGITAL_MARKETING:
        return elite_digital_marketing;
      case ElitePages.IN_CLINIC:
        return elite_in_clinic;
      case ElitePages.JOINT_BUSINESS_PLAN:
        return elite_joint_business_plan;
      case ElitePages.SCIENTIFIC_NETWORK:
        return elite_scientific_network;
      default:
        return undefined;
    }
  }, [
    elite_advanced_training,
    elite_digital_marketing,
    elite_in_clinic,
    elite_joint_business_plan,
    elite_scientific_network,
    page,
  ]);

  return (
    <Grid
      container
      height={"100%"}
      direction={"row"}
      columnGap={1}
      justifyContent={"center"}
      alignContent={"end"}
    >
      <RectangleButton
        ref={ref}
        text="Visualizza il video"
        icon={<PlayIcon width={"1.2rem"} />}
        opacity={diff}
        onClick={() => {
          dispatch(
            openModal({
              modalData: { type: "video", video: video! },
              rect: ref.current!.getRect(),
            })
          );
        }}
      />
      <GoToCategory opacity={diff} />
    </Grid>
  );
}

function GoToCategory(props: { opacity: number }) {
  const page = usePageByMainCircle();

  const dispatch = useAppDispatch();
  return (
    <RectangleButton
      text="Scopri di più"
      icon={<LensIcon width={"1.5rem"} />}
      opacity={props.opacity}
      onClick={() => dispatch(goToElitePage(page))}
    />
  );
}
