import { useAsset } from "../../components/AsssetsLoader";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { ImageKeys } from "../../utils/assets";
import { Routes } from "../../utils/routes";
import { Header } from "../prodotti/BioRepeelPage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BGVideo } from "../prodotti";
import {
  ElitePages,
  goToElitePage,
  resetElite,
} from "../../redux/elite.reducer";
import { Main } from "./main";
import { AdvancedTraining } from "./advanced_training";
import { InClinic } from "./inclinic";
import { ScientificNetwork } from "./scientific_network";
import { DigitalMarketing } from "./digital_marketing";
import { JointBusinessPlan } from "./joint_business_plan";
import { Box, Grid } from "@mui/material";
import { RectangleButton } from "../../components/Atoms/RectangleButton";
import { useMemo } from "react";
import { VideoKeys } from "../../utils/assets";

export function ElitePageWithRoute() {
  const isVisible = useIsRouteVisible(Routes.elite);
  if (!isVisible) return <></>;
  return <ElitePage />;
}

export function ElitePage() {
  const { changeRoute } = useRouter<DOMRect>();
  const [logo_elite_nero] = useAsset<ImageKeys>(["logo_elite_nero"]);
  const page = useAppSelector((state) => state.elite.page);
  const dispatch = useAppDispatch();

  return (
    <InternalPage
      color="black"
      backgroundColor="white"
      logoFill="black"
      sectionHeader={
        <Header
          id="elite"
          showEverywhere
          color="black"
          children={
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={logo_elite_nero}
              style={{ height: "6rem", width: "auto" }}
            />
          }
        />
      }
      goHome={() => {
        dispatch(resetElite());
      }}
      goBack={() => {
        changeRoute(Routes.servizi, { showPrevious: false });
        dispatch(resetElite());
      }}
      goUpper={
        page === ElitePages.MAIN
          ? undefined
          : () => {
              dispatch(goToElitePage(ElitePages.MAIN));
              dispatch(resetElite());
            }
      }
      subsection={<span style={{ color: "black" }}>Élite</span>}
    >
      <Core page={page} />
      <VideoBackground />
      <ImageBackground />
      <BottomCard />
    </InternalPage>
  );
}

function BottomCard() {
  const page = useAppSelector((state) => state.elite.page);
  switch (page) {
    case ElitePages.ADVANCED_TRAINING:
      return <Bottom txt="Advanced Training" />;
    case ElitePages.IN_CLINIC:
      return <Bottom txt="In-Clinic" />;
    case ElitePages.DIGITAL_MARKETING:
      return <Bottom txt="Digital Marketing" />;
    case ElitePages.SCIENTIFIC_NETWORK:
      return <Bottom txt="Scientific Network" />;
    case ElitePages.JOINT_BUSINESS_PLAN:
      return <Bottom txt="Joint Business Plan" />;
    default:
      return <></>;
  }
}

function Bottom(props: { txt: string }) {
  return (
    <Grid
      position="absolute"
      container
      justifyContent={"center"}
      bottom={"2rem"}
      width={"100%"}
      sx={{
        animation: "text-focus-in 1s ease-out",
      }}
    >
      <Grid item>
        <RectangleButton text={props.txt} />
      </Grid>
    </Grid>
  );
}

function getImgIndex(page: any) {
  switch (page) {
    case ElitePages.ADVANCED_TRAINING:
      return 0;
    case ElitePages.IN_CLINIC:
      return 1;
    case ElitePages.DIGITAL_MARKETING:
      return 2;
    case ElitePages.SCIENTIFIC_NETWORK:
      return 3;
    default:
      return -1;
  }
}

function getVideoIndex(page: any) {
  switch (page) {
    case ElitePages.JOINT_BUSINESS_PLAN:
      return 1;
    default:
      return 0;
  }
}

function VideoBackground() {
  const page = usePageByMainCircle();

  const videoKeys: Array<VideoKeys> = ["elite_sfondo", "elite_joint_sfondo"];

  const index = useMemo(() => getVideoIndex(page), [page]);

  return <BGVideo video={videoKeys[index]} />;
}

function ImageBackground() {
  const page = usePageByMainCircle();

  const imgs = useAsset<ImageKeys>([
    "elite_advanced_training_sfondo",
    "elite_in_clinic_sfondo",
    "elite_digital_marketing_sfondo",
    "elite_scientific_network_sfondo",
  ]);

  const index = useMemo(() => getImgIndex(page), [page]);

  return (
    <>
      {imgs.map((img, i) => {
        return (
          <Box
            width={"100%"}
            height={"100%"}
            position={"fixed"}
            top={0}
            zIndex={ZIndexes.background + 1}
          >
            <img
              src={img}
              height={"100%"}
              width={"100%"}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                objectFit: "cover",
                opacity: index === i ? 0.1 : 0,
                transition: "opacity 1s ease-in-out",
              }}
            />
          </Box>
        );
      })}
    </>
  );
}

export function usePageByMainCircle() {
  const [page, circle] = useAppSelector((state) => [
    state.elite.page,
    state.elite.circles.find((c) => c.id === ElitePages.MAIN)!,
  ]);

  if (page === ElitePages.MAIN) {
    return circle.options[Math.floor(circle.selected) - 1].value;
  } else {
    return page;
  }
}

function Core(props: { page: ElitePages }) {
  switch (props.page) {
    case ElitePages.MAIN:
      return <Main />;
    case ElitePages.ADVANCED_TRAINING:
      return <AdvancedTraining />;
    case ElitePages.IN_CLINIC:
      return <InClinic />;
    case ElitePages.DIGITAL_MARKETING:
      return <DigitalMarketing />;
    case ElitePages.SCIENTIFIC_NETWORK:
      return <ScientificNetwork />;
    case ElitePages.JOINT_BUSINESS_PLAN:
      return <JointBusinessPlan />;
    default:
      return <></>;
  }
}
