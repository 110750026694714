import { Grid, Typography } from "@mui/material";
import { useAsset } from "../components/AsssetsLoader";
import { ImageKeys } from "../utils/assets";
import { HomePage } from "./HomePage";
import { useRouter } from "../components/MainRouter/MainRouter";
import { Routes } from "../utils/routes";
import { VideoKeys } from "../utils/assets";

export function MerzHome() {
  const [chi_siamo, i_nostri_prodotti, servizi, elite, logo_elite] =
    useAsset<ImageKeys>([
      "chi_siamo",
      "i_nostri_prodotti",
      "servizi",
      "elite",
      "logo_elite",
    ]);
  const [chi_siamo_video, i_nostri_prodotti_video, servizi_video, elite_video] =
    useAsset<VideoKeys>([
      "chi_siamo_video",
      "i_nostri_prodotti_video",
      "servizi_video",
      "elite_video",
    ]);

  const { changeRoute } = useRouter();

  return (
    <>
      <HomePage
        duration={2}
        timingFunction="ease-in"
        startDelay={4}
        innerDelay={1}
        data={[
          {
            sectionHeader: (
              <Typography variant="h5" textAlign={"center"}>
                IL <strong>DNA</strong> DELL'
                <i style={{ paddingLeft: "2px" }}>innovazione</i>
              </Typography>
            ),
            sectionTitle: <strong>Chi siamo</strong>,
            img: chi_siamo,
            video: chi_siamo_video,
            color: "smalt",
            animation: "bottomUp",
            sx: {
              backgroundColor: "#0E0E0E",
            },
            onClick(rect) {
              changeRoute(Routes.dna, {
                showPrevious: true,
                args: rect,
              });
            },
          },
          {
            sectionHeader: (
              <Typography variant="h5" textAlign={"center"}>
                IL <strong>FUTURO</strong> DELLA <i>bellezza</i>
              </Typography>
            ),
            sectionTitle: (
              <>
                I nostri <strong>Prodotti</strong>
              </>
            ),
            img: i_nostri_prodotti,
            video: i_nostri_prodotti_video,
            color: "debianRed",
            animation: "topDown",
            onClick(rect) {
              changeRoute(Routes.inostriprodotti, {
                showPrevious: true,
                args: rect,
              });
            },
          },
          {
            sectionHeader: (
              <Typography variant="h5" textAlign={"center"}>
                CUSTO<strong>MERZ</strong> <i>experience</i>
              </Typography>
            ),
            sectionTitle: <strong>Servizi</strong>,
            img: servizi,
            video: servizi_video,
            color: "titaniumYellow",
            animation: "bottomUp",
            sx: {
              backgroundColor: "#0E0E0E",
            },
            onClick(rect) {
              changeRoute(Routes.servizi, {
                showPrevious: true,
                args: rect,
              });
            },
          },
          {
            sectionHeader: (
              <Grid
                container
                direction="row"
                justifyContent="center"
                position={"relative"}
              >
                <Typography
                  variant="h5"
                  textAlign={"center"}
                  visibility={"hidden"}
                >
                  CUSTO<strong>MERZ</strong> <i>experience</i>
                </Typography>
                <img
                  alt=""
                  src={logo_elite}
                  style={{
                    width: "30%",
                    height: "auto",
                    position: "absolute",
                    top: 0,
                  }}
                />
              </Grid>
            ),
            onClick(rect) {
              changeRoute(Routes.elite, {
                showPrevious: false,
                args: rect,
              });
            },
            sectionTitle: (
              <strong>
                Business
                <br /> development
              </strong>
            ),
            img: elite,
            video: elite_video,
            color: "eliteWhite",
            animation: "topDown",
          },
        ]}
      />
    </>
  );
}
