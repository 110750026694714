import React, { createContext, useContext, useEffect, useState } from "react";
import { createObjectURL } from "../utils/functions";
import { Quality, getQuality, hasEnoughSpace } from "../utils/assets";
import { humanReadableSize } from "../pages/LoadingPage";

const maxSizeInBytes = 150 * 1024 * 1024;

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

type DBConfig = {
  name: string;
  version: number;
  objectStoresMeta: {
    store: string;
    storeConfig: {
      keyPath: string;
      autoincrement?: boolean;
    };
    storeSchema: {
      name: string;
      keypath: string;
      options: { unique?: boolean };
    }[];
  };
};

export const dbConfig: DBConfig = {
  name: "merz",
  version: 3,
  objectStoresMeta: {
    store: "assets",
    storeConfig: { keyPath: "key" },
    storeSchema: [],
  },
};

type DownloadBlobType = {
  url: string;
  key: string;
  isOfflineAvailable?: boolean;
};

async function startDownloadOnBackground(
  storeAssets: (args: { url: string; key: string }) => void,
  queue: DownloadBlobType[]
) {
  for (let i = 0; i < queue.length; i++) {
    const res = queue[i];

    try {
      const bloblRes = await loadBlob(res, {
        delayTime: 500,
      });
      if (bloblRes !== undefined)
        storeAssets({ url: bloblRes.url, key: res.key });
    } catch {}
  }
}

function initObjectStore() {
  const request = window.indexedDB.open(dbConfig.name, dbConfig.version);

  return new Promise<IDBDatabase>((resolve, reject) => {
    request.onupgradeneeded = function (event) {
      const eventTarget = event.target as any;
      const db = eventTarget.result as IDBDatabase;
      const objectStore = db.createObjectStore(
        dbConfig.objectStoresMeta.store,
        dbConfig.objectStoresMeta.storeConfig
      );
      for (const storeSchema of dbConfig.objectStoresMeta.storeSchema) {
        objectStore.createIndex(
          storeSchema.name,
          storeSchema.keypath,
          storeSchema.options
        );
      }
      objectStore.transaction.oncomplete = function () {
        resolve(db);
      };
    };

    request.onsuccess = function (event) {
      const eventTarget = event.target as any;
      const db = eventTarget.result as IDBDatabase;

      resolve(db);
    };

    request.onerror = function (event) {
      const eventTarget = event.target as any;
      reject(eventTarget.result);
    };
  });
}

type MediaAttachement = {
  key: string;
  blobContent: ArrayBuffer;
  contentType: string;
  next?: string;
};

// const db = new PouchDB("assets");
export type AssetLoaderStrategy =
  | "noCache"
  | "cacheBefore"
  | "cacheAfter"
  | "cacheBeforeLazy";

export type AssetEntry = {
  type?: AssetLoaderStrategy;
  path: string;
  priority?: number;
  quality?: Array<Quality>;
};

interface AssetsLoaderProps {
  assetsUrl: Map<string, AssetEntry>;
  children?: React.ReactNode;
  loading?: (props: {
    percentage: number;
    size: number;
    shouldContinue: (e: boolean) => void;
    isOfflineAvailable: boolean;
    error?: string;
  }) => JSX.Element;
  type?: AssetLoaderStrategy;
}

enum AssetType {
  image = "image",
  video = "video",
}

type Asset = {
  url: string;
  type: AssetLoaderStrategy;
};

export type IAssetContext = Record<string, Asset>;
type AssetLoadedType = { url: string; size: number };

async function loadUrl(
  key: string,
  url: string
): Promise<AssetLoadedType | undefined> {
  return { url, size: 0 };
}

function getAssetType(url: string) {
  if (
    url.endsWith(".jpg") ||
    url.endsWith(".jpeg") ||
    url.endsWith(".png") ||
    url.endsWith(".gif")
  ) {
    return AssetType.image;
  } else if (
    url.endsWith(".mp4") ||
    url.endsWith(".webm") ||
    url.endsWith(".mov")
  ) {
    return AssetType.video;
  }
  return undefined;
}

async function loadBlob(
  { url, key, isOfflineAvailable }: DownloadBlobType,
  options: { donwloadEnabled?: boolean; delayTime?: number } = {
    donwloadEnabled: true,
    delayTime: 200,
  }
): Promise<AssetLoadedType | undefined> {
  const normalizedOptions = {
    donwloadEnabled: true,
    delayTime: 200,
    ...options,
  };
  if (isOfflineAvailable && getQuality() === Quality.low) {
    console.log(`AWAIT TO DOWNLOAD ${normalizedOptions.delayTime}ms`);
    await delay(normalizedOptions.delayTime);
  }
  if (url === undefined) {
    throw new Error("Url is undefined");
  }

  const urlPathname = new URL(url).pathname;
  const assetType = getAssetType(urlPathname);

  if (assetType === undefined) {
    console.warn("Unknown asset type " + url);
    return;
  }

  const blob = await downloadBlob(
    {
      url,
      key,
    },
    normalizedOptions
  );

  if (blob === null || !(blob instanceof Blob)) {
    if (normalizedOptions.donwloadEnabled)
      console.warn(`Blob undefined ${key}`);
    return;
  }
  const src = createObjectURL(blob);
  if (src == null) {
    console.warn(`Cant't create object url from blob ${key}`);
    return;
  }

  return { url: src, size: blob.size };
}

async function downloadBlob(
  { url, key }: DownloadBlobType,
  options: { donwloadEnabled: boolean }
): Promise<Blob | null> {
  const res = await getFromStore?.call(undefined, key);
  if (res != null) {
    return res;
  }
  if (!options.donwloadEnabled) return null;

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await response.blob().catch(console.error);
  if (!(blob instanceof Blob)) return null;
  const blobContent = await blob.arrayBuffer().catch(console.error);
  if (!(blobContent instanceof ArrayBuffer)) return null;
  const mediaAttachement: MediaAttachement = {
    key,
    blobContent,
    contentType: blob.type,
  };

  await addStore?.call(undefined, mediaAttachement, blob.size);
  return new Blob([blobContent], { type: blob.type });
}

export const AssetContext = createContext<IAssetContext>({});

var isLoading = false;

export function AssetsLoader(props: AssetsLoaderProps) {
  return <InternalAssetsLoader {...props} />;
}
export function addOnObjectStore(
  db: IDBDatabase
): (attachment: MediaAttachement, sizeInBytes: number) => Promise<IDBValidKey> {
  return async (attachment, sizeInBytes) => {
    let attachements: MediaAttachement[] = [];

    if (sizeInBytes > maxSizeInBytes) {
      //SHOULD SPLIT ARRAY BUFFER INTO CHUN
      const chunks = Math.ceil(sizeInBytes / maxSizeInBytes);
      for (let i = 0; i < chunks; i++) {
        console.log("AWAITING CHUNK");
        await delay(2000);
        const start = i * maxSizeInBytes;
        const end = (i + 1) * maxSizeInBytes;
        const chunk = attachment.blobContent.slice(start, end);
        const next = i + 1;
        const mediaAttachement: MediaAttachement = {
          key: i === 0 ? attachment.key : attachment.key + "_NEXT_" + i,
          blobContent: chunk,
          contentType: attachment.contentType,
          next: attachment.key + "_NEXT_" + next,
        };
        attachements.push(mediaAttachement);
      }
    } else {
      attachements.push(attachment);
    }
    const objectStoreTransaction = db
      .transaction(dbConfig.objectStoresMeta.store, "readwrite")
      .objectStore(dbConfig.objectStoresMeta.store);
    return Promise.all(
      attachements.map((e) => addAttachement(objectStoreTransaction, e))
    );
  };
}

function addAttachement(
  objectStore: IDBObjectStore,
  attachment: MediaAttachement
): Promise<IDBValidKey> {
  const request = objectStore.add(attachment);
  return new Promise((res, rej) => {
    request.onsuccess = function (event) {
      res(request.result);
    };
    request.onerror = function (event) {
      rej(request.error);
    };
  });
}

function getAttachement(
  db: IDBDatabase,
  key: string
): Promise<MediaAttachement | null> {
  const objectStore = db
    .transaction(dbConfig.objectStoresMeta.store, "readonly")
    .objectStore(dbConfig.objectStoresMeta.store);

  const request = objectStore.get(key);
  return new Promise((res, rej) => {
    request.onsuccess = function (event) {
      const eventTarget = event.target as any;
      const mediaAttachement = eventTarget.result as MediaAttachement | null;
      res(mediaAttachement);
    };
    request.onerror = function (event) {
      rej(request.error);
    };
  });
}

function allKeys(db: IDBDatabase): Promise<IDBValidKey[]> {
  const objectStore = db
    .transaction(dbConfig.objectStoresMeta.store, "readonly")
    .objectStore(dbConfig.objectStoresMeta.store);

  const request = objectStore.getAllKeys();
  return new Promise((res, rej) => {
    request.onsuccess = function (event) {
      res(request.result);
    };
    request.onerror = function (event) {
      rej(request.error);
    };
  });
}

function getFromObjectStore(
  objectStore: IDBDatabase
): (key: string) => Promise<Blob | null> {
  return async (key: string) => {
    const attachment = await getAttachement(objectStore, key);
    if (attachment == null) {
      return null;
    } else {
      //Collect all attachements
      const attachements: MediaAttachement[] = [attachment];
      var next = attachment.next;
      while (next !== undefined) {
        const res = await getAttachement(objectStore, next);
        if (res == null) {
          break;
        } else {
          attachements.push(res);
          next = res.next;
        }
      }

      return new Blob(
        attachements.map((e) => e.blobContent),
        { type: attachements[0].contentType }
      );
    }
  };
}

let getFromStore: ((key: string) => Promise<Blob | null>) | undefined =
  undefined;
let addStore:
  | ((
      attachment: MediaAttachement,
      sizeInBytes: number
    ) => Promise<IDBValidKey>)
  | undefined = undefined;

function InternalAssetsLoader({
  assetsUrl,
  children,
  loading,
  type,
}: AssetsLoaderProps) {
  const [assets, setAssets] = useState<IAssetContext | null>(null);
  const [percentage, setPercentage] = useState(0);
  const [size, setSize] = useState(0);
  const [shouldContinue, setShouldContinue] = useState(loading === undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isOfflineAvailable, setIsOfflineAvailable] = useState(false);

  useEffect(() => {
    async function loadAssets() {
      if (isLoading) return;
      isLoading = true;

      const requiredSpace = await hasEnoughSpace();
      if (requiredSpace !== null) {
        setError(
          `Spazio non sufficiente per scaricare i contenuti. Sono richiesti almeno ${humanReadableSize(
            requiredSpace
          )}.`
        );
        return;
      }

      var db = await initObjectStore().catch((e) => {
        console.error(e);
        setError("Impossibile inizializzare il database");
      });

      if (db === undefined) {
        return;
      }
      getFromStore = getFromObjectStore(db);
      addStore = addOnObjectStore(db);

      const keys = (await allKeys(db).catch((_) => null)) ?? [];
      let newAssets: IAssetContext = {};
      let entries = Array.from(assetsUrl.entries()).filter((e) =>
        !!e[1].quality ? e[1].quality.includes(getQuality()) : true
      );

      entries = entries.sort((a, b) => {
        const aPriority = a[1].priority ?? 0;
        const bPriority = b[1].priority ?? 0;

        return bPriority - aPriority;
      });
      let index = 1;
      const total = entries.length;
      const queue: DownloadBlobType[] = [];
      for (const [key, value] of entries) {
        const computedType = value.type ?? type ?? "noCache";
        let res: AssetLoadedType | undefined;

        const isOfflineAvailable = keys.some((e) => e === key);
        try {
          if (computedType === "cacheBeforeLazy" && isOfflineAvailable) {
            queue.push({ url: value.path, key: key, isOfflineAvailable });
          } else if (
            computedType === "cacheBefore" ||
            computedType === "cacheBeforeLazy"
          ) {
            res = await loadBlob({ key, url: value.path, isOfflineAvailable });
          } else if (computedType === "cacheAfter") {
            queue.push({ url: value.path, key: key, isOfflineAvailable });
          }

          if (res === undefined) {
            res = await loadUrl(key, value.path);
          }

          if (res !== undefined) {
            newAssets[key] = {
              url: res.url,
              type: computedType,
            };
            setSize((e) => e + res!.size);
          }
        } catch (error) {
          console.log(`Error loading asset ${key}`);
          db?.close();
          window.location.reload();
          queue.push({ url: value.path, key: key, isOfflineAvailable });
          // handleErrorOnDB(error);
        }
        setPercentage(index / total);
        index += 1;
      }

      setAssets(newAssets);
      startDownloadOnBackground(
        (args) => (newAssets[args.key] = { url: args.url, type: "noCache" }),
        queue
      );
      if (queue.filter((e) => !e.isOfflineAvailable).length === 0) {
        setIsOfflineAvailable(true);
      }
    }

    loadAssets();
  }, [assetsUrl, type]);

  return assets != null && shouldContinue ? (
    <AssetContext.Provider value={assets} children={children} />
  ) : (
    loading?.call(undefined, {
      percentage,
      size,
      isOfflineAvailable,
      shouldContinue: (res) => setShouldContinue(res),
      error,
    }) ?? <></>
  );
}

export function useAsset<T extends string = string>(key: T | ReadonlyArray<T>) {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useImage must be used within an AssetsLoader");
  }
  return (Array.isArray(key) ? key : [key]).map((e) => {
    try {
      const res = context[e];
      return res.url;
    } catch (error) {
      return "";
    }
  });
}

export function useSingleAsset<T extends string = string>(key: T | undefined) {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useImage must be used within an AssetsLoader");
  }
  if (key === undefined || context[key] === undefined) {
    return undefined;
  }
  return context[key].url;
}
