/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useMemo, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  ModalCarouselData,
  ModalDOMRect,
  Rect,
  closeModal,
} from "../../redux/modal.reducer";
import { useFullScreenModalSize } from "../../utils/hooks";
import { Carousel, useCarouselHandler } from "./generic.carousel";
import { maxZoomScale, transitionEnabled } from "../../constants";
import { ZIndexes } from "../InternalPage/InternalPage";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BeforeAndAfter } from "../Card/BeforeAndAfter";
import { Box } from "@mui/material";

function getElementRect(args: { rect: ModalDOMRect; id?: string }) {
  if (args.id !== undefined) return document.getElementById(args.id)?.getRect();
  else if (args.rect) return args.rect;
  return undefined;
}

const duration = 1;

export function ModalCarousel(
  props: ModalCarouselData & { rect: ModalDOMRect; id?: string }
) {
  const style = useFullScreenModalSize(props.rect);

  const ID = "carousel-container";
  const { next, prev, activeIndex, setActiveIndex } = useCarouselHandler(
    props.items.length,
    props.initial
  );
  const [rect, setRect] = useState<Rect | undefined>();
  const dispatch = useAppDispatch();

  const { close, animated } = useAppSelector((state) => ({
    close: state.modal.close,
    animated: state.modal.animated,
    fullscreen: state.modal.fullscreen,
  }));

  const imgRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const elRect = getElementRect(props);

    if (animated) {
      setRect(elRect);
      const timeout = setTimeout(
        () => setRect(document.getElementById(ID)?.getRect()),
        100
      );
      return () => clearTimeout(timeout);
    }
  }, [animated, props.id, props.rect]);

  const item = useMemo(() => {
    const currentItem = props.items[activeIndex];
    switch (currentItem.type) {
      case "image":
        return (
          <img
            id={ID}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            src={currentItem.src}
            style={{
              ...style,
              visibility: !animated ? "visible" : "hidden",
              ...currentItem.style,
            }}
            ref={imgRef}
          />
        );
      case "video":
        return (
          <video
            id={ID}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            crossOrigin="anonymous"
            src={currentItem.src}
            autoPlay={!animated}
            controls
            style={{
              ...style,
              visibility: !animated ? "visible" : "hidden",
              ...currentItem.style,
            }}
            ref={videoRef}
          />
        );
      case "beforeAndAfter":
        return (
          <Box
            width={400}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <BeforeAndAfter
              before={currentItem.before}
              after={currentItem.after}
              enabled
            />
          </Box>
        );

      default:
        return <></>;
    }
  }, [activeIndex, animated, props.items, style]);

  const title = useMemo(() => {
    const currentItem = props.items[activeIndex];
    switch (currentItem.type) {
      case "video":
        return currentItem.title;
      default:
        return undefined;
    }
  }, [activeIndex, animated, props.items, style]);

  useEffect(() => {
    if (close) {
      dispatch(closeModal());
    }
  }, [close, dispatch]);

  return (
    <>
      <Carousel
        next={next}
        prev={prev}
        setActiveIndex={setActiveIndex}
        title={title}
        children={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
            children={item}
          />
        }
        items={props.items.length}
        activeIndex={activeIndex}
      />
      <El rect={rect} item={props.items[activeIndex]} id={props.id} />
    </>
  );
}

const El = React.memo(
  ({
    id,
    rect,
    item,
  }: {
    id?: string;
    rect: ModalDOMRect | undefined;
    item: ModalCarouselData["items"][0];
  }) => {
    if (rect === undefined) return <></>;
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          transition: !transitionEnabled
            ? undefined
            : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
          width: rect.width,
          height: rect.height,
          left: rect.left,
          top: rect.top,
          zIndex: ZIndexes.dialog,
          visibility: "visible",
        }}
      >
        {item.type === "image" && (
          <TransformWrapper maxScale={maxZoomScale}>
            <TransformComponent
              wrapperStyle={{
                overflow: "visible",
              }}
            >
              <img
                id={id}
                src={item.src}
                style={{
                  transition: !transitionEnabled
                    ? undefined
                    : `width ${duration}s linear,height ${duration}s linear,top ${duration}s linear,left ${duration}s linear`,
                  width: rect.width,
                  height: rect.height,
                  left: rect.left,
                  top: rect.top,
                  ...item.style,
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        )}
        {item.type === "video" && (
          <video
            id={id}
            crossOrigin="anonymous"
            src={item.src}
            autoPlay
            controls
            disablePictureInPicture
            disableRemotePlayback
            style={{
              ...item.style,
            }}
          />
        )}
        {item.type === "beforeAndAfter" && <h1>TANO</h1>}
      </div>
    );
  }
);
