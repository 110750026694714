import { CSSProperties } from "react";
import { LogoProps } from "./BocotureLogo";
const SvgComponent = (props: LogoProps & { fill?: CSSProperties["fill"] }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="164.5 160.73 1983.19 352.93"
    {...props}
  >
    <rect width={24.5} height={257} x={1590} y={160.75} rx={0.61} />
    <rect width={24.26} height={257} x={1866.25} y={160.75} rx={0.6} />
    <path d="M2147.69 194.31a33.58 33.58 0 0 1-33.58 33.58 33.58 33.58 0 0 1-33.58-33.58 33.58 33.58 0 0 1 33.58-33.58 33.58 33.58 0 0 1 33.58 33.58Zm-6.23.01a27.35 27.35 0 0 0-27.35-27.35 27.35 27.35 0 0 0-27.35 27.35 27.35 27.35 0 0 0 27.35 27.35 27.35 27.35 0 0 0 27.35-27.35ZM1718.51 419.76c-44.58-11.95-65.35-55.72-69.65-98-5.35-52.48 4.72-124.68 62.54-145.63 17.56-6.37 37.99-6.54 56.11-2.62 26.77 5.79 48.05 23.13 60.23 47.75q.24.49-.23.76l-20.34 11.89a.72.72 0 0 1-.99-.28c-13.79-25.37-34.4-39.26-63.93-38.42-60.34 1.71-68.22 72.5-65.74 117.29 2.26 40.83 21.2 89.19 70.9 86.44 16.82-.93 30.84-5.73 42.02-18.51q10.8-12.35 17.7-26.8a.76.75 28 0 1 1.07-.32l21.38 12.87a.77.77 0 0 1 .3.99c-12.52 27.01-34.34 48.92-64.12 54.34q-24.23 4.42-47.25-1.75Z" />
    <path d="M2112.76 197.75h-3.51q-.75 0-.75.75v14q0 .75-.75.75h-6.09a.66.65 0 0 1-.66-.65v-37.11a.69.69 0 0 1 .67-.69c10.75-.34 28.02-3.04 27.88 12.01-.05 5.36-2.67 9.23-8.02 10.13a.63.62-20.7 0 0-.43.94l9.05 14.68q.42.69-.38.69h-6.49q-.52 0-.79-.44l-8.93-14.61a.96.93 73.7 0 0-.8-.45Zm-4.25-16.14-.02 9.24a.62.62 0 0 0 .62.62l6.21.01a6.95 4.69.1 0 0 6.96-4.68v-1.1a6.95 4.69.1 0 0-6.94-4.7l-6.21-.01a.62.62 0 0 0-.62.62ZM1123.25 176.25c33.19 0 61.04 19.61 67.85 52.74 10.47 50.93-14.57 96.11-71.11 96.24q-25.87.05-51.72.01a.52.52 0 0 0-.52.52v91.49a.5.5 0 0 1-.5.5h-25.5q-.75 0-.75-.75V176.58a.34.34 0 0 1 .34-.34q41.16.01 81.91.01Zm41.08 93.56c7.81-31.67-2.69-71.01-42.6-71.24q-26.85-.16-53.5-.04a.48.48 0 0 0-.48.48v103.74a.51.51 0 0 0 .51.51q26.46.06 52.92-.31c22.2-.31 37.81-11.48 43.15-33.14ZM283.64 287a.67.66 41.1 0 0 .09 1.25c35 9.71 45.63 41.53 40.39 74.67-5.65 35.75-34.53 54.46-69.06 54.66q-45.04.26-90.07.15-.49 0-.49-.49V176.76a.49.49 0 0 1 .49-.49q40.4-.06 81.01-.02c29.58.03 57.55 13.89 65.22 44.41 6.49 25.84-1.12 55.11-27.58 66.34Zm1.22-54.08c-2.28-23.1-18.65-34.16-41.11-34.17q-26.1-.01-52 0a.51.5-90 0 0-.5.51v79.48a.5.5 0 0 0 .5.5q28.21.07 56.43-.11c28.11-.19 39.21-20.56 36.68-46.21Zm-93.7 68.93.16 93.16a.58.58 0 0 0 .58.58l60.16-.1a45.18 43.68-.1 0 0 45.11-43.76l-.02-6.96a45.18 43.68-.1 0 0-45.25-43.6l-60.16.1a.58.58 0 0 0-.58.58ZM724.5 311.7a.9.9 0 0 0-.78-.45h-46.47a.5.5 0 0 0-.5.5v105.5a.5.5 0 0 1-.5.5h-25.5q-.75 0-.75-.75V176.76a.51.51 0 0 1 .51-.51q40.76-.01 81.74.01c30.12.01 57 14.32 67.28 42.85 14.31 39.69-7.52 79.26-48.05 88.97a.58.57-21.7 0 0-.37.85l62.29 108.15a.45.45 0 0 1-.39.67h-26.95q-.61 0-.92-.53L724.5 311.7Zm-47.91-112.28.31 88.9a.84.84 0 0 0 .84.84l53.9-.19a44 42.42-.2 0 0 43.85-42.57l-.02-5.74a44 42.42-.2 0 0-44.15-42.27l-53.9.19a.84.84 0 0 0-.83.84Z" />
    <circle cx={387.02} cy={200.73} r={19.27} />
    <path d="M549.43 248.83c75.59 18.56 82.43 135.16 14.79 166.89-15.7 7.36-33.88 9.26-50.93 6.24-37.46-6.63-61.75-37.17-66.21-73.88-4.47-36.83 9.36-76.67 44.68-93.78 17.72-8.58 38.58-10.16 57.67-5.47Zm-77.5 100.21c4.09 23.75 19.15 45.62 43.32 50.68q28.32 5.93 48.84-11.5c34.46-29.26 29.06-100.61-16.52-116.54-17.01-5.95-36.45-3.8-51.1 6.72-22.13 15.91-28.94 45.11-24.54 70.64ZM908.42 247.2c56.52-7.91 95.99 37.6 91.18 92a.6.6 0 0 1-.6.55H864.01a.46.46 0 0 0-.46.49c1.4 29.58 20.08 58.08 51.79 60.63 25.71 2.07 49.33-6.62 63.03-29.22a.87.87 0 0 1 1.22-.27l18.1 12.19a.69.68 33.8 0 1 .19.94c-19.65 29.83-52.9 42.52-87.82 38.03-93.88-12.06-95.07-162.25-1.64-175.34Zm67.03 72.03c-3.06-23.02-14.35-43.6-38.69-49.01-36.64-8.14-65.61 13.04-71.69 49.08a.4.39 4.9 0 0 .39.46l109.54-.01a.47.45 86.6 0 0 .45-.52ZM1287.25 423.41c-98.44-1.95-109.32-153.61-17.95-175.1 50.22-11.81 95.03 23.77 97.9 74.39q.45 7.97.27 16.11a.96.96 0 0 1-.96.94h-134.75a.43.43 0 0 0-.43.45c1.28 27.15 17.82 55.31 46.23 59.98 26.75 4.4 54.12-4.48 68.54-28.59a.75.74-57.8 0 1 1.05-.24l18.17 12.11a.92.92 0 0 1 .25 1.28c-18.43 27.18-46.01 39.3-78.32 38.67Zm55.97-104.2c-3.39-24.22-15.23-44.64-40.97-49.45-35.97-6.73-63.6 14.35-69.37 49.54q-.07.45.38.45h109.49a.47.47 0 0 0 .47-.54ZM1472.76 423.37c-39.43-.81-69.74-27.82-77.81-65.58-8.95-41.91 6.23-88.56 47.82-105.57 16.46-6.73 35.6-7.75 52.73-3.22 40.38 10.68 61.02 50.78 57.36 90.2q-.05.55-.6.55h-135.01a.34.34 0 0 0-.34.36c1.27 26.81 17.2 54.35 44.91 59.78q11.39 2.23 23.7.85 28.09-3.15 44.05-26.13 1.12-1.62 2.46-3.13.36-.4.8-.1L1551 383.5a.81.8 34 0 1 .22 1.11c-18.26 27.26-45.95 39.42-78.46 38.76Zm55.93-104.11c-2.53-23.88-15.85-45.35-41.14-49.57q-15.48-2.58-28.82.92c-22.51 5.89-36.6 26.82-40.34 48.74a.34.34 0 0 0 .34.4h109.51q.5 0 .45-.49Z" />
    <rect width={24.24} height={165.74} x={375.01} y={252.01} rx={0.57} />
    <path d="M2038.5 379.9c24.96 8.19 40.06 31.07 42 56.33 3.9 50.62-39.9 82.57-87.51 76.75-28.32-3.47-50.56-22.1-61.13-48.38a.76.75-26.2 0 1 .32-.93l20.18-11.79a.76.76 0 0 1 1.07.33c11.68 24.75 33.78 43.32 62.77 37.31 51.96-10.78 50.5-86.96-1.82-96.86-7.77-1.47-17.84-.87-26.63-.92a.51.5.6 0 1-.5-.5v-21.22a.5.49-90 0 1 .49-.5c7.9-.07 17.06.5 23.9-.69 45.15-7.83 46.37-80.83-2.54-84.72-26.76-2.12-44.61 15.48-55.67 38.12q-.35.72-1.05.34l-18.51-10.15q-.45-.24-.25-.7c9.89-23.25 29.24-42.53 54.3-48.27 33.44-7.67 69.71 6.31 80.4 41.01 8.75 28.43-1.24 61.93-29.86 74.79a.35.35 0 0 0 .04.65Z" />
  </svg>
);
export { SvgComponent as BioRepeelLogo };
