import {
  Grid,
  LinearProgressProps,
  LinearProgress as MuiProgress,
  Typography,
} from "@mui/material";
import { Colors } from "../styles/theme";
import { Logo } from "../components/Atoms/Logo";
import { useMemo } from "react";

export const LinearProgress = MuiProgress as (
  props: Omit<LinearProgressProps, "color"> & { color?: Colors }
) => JSX.Element;

export function humanReadableSize(fileSizeInBytes: number) {
  let i = -1;
  let byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

interface LoadingPageProps {
  percentage: number;
  size: number;
  isOfflineAvailable: boolean;
  error?: string;
  shouldContinue: (e: boolean) => void;
}

export function LoadingPage(props: LoadingPageProps) {
  const hasError = useMemo(() => props.error !== undefined, [props.error]);
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      direction={"column"}
      gap={1}
      onClick={() => props.shouldContinue(true)}
      style={{ height: "100%", width: "100%", backgroundColor: "black" }}
    >
      <Grid item alignSelf={"center"}>
        <Logo fill="white" height={150} />
      </Grid>

      {props.percentage === 1 && props.isOfflineAvailable && (
        <Grid item>
          <Typography
            variant="h6"
            textAlign={"center"}
            sx={{
              color: "green",
            }}
          >
            App disponibile offline
          </Typography>
        </Grid>
      )}

      {props.percentage < 1 && !hasError && (
        <Grid item>
          <Typography
            variant="h6"
            textAlign={"center"}
            sx={{
              color: "warning.main",
            }}
          >
            Durante lo scaricamento ricordati di non chiudere mai l'app e di
            tenere lo schermo acceso
          </Typography>
        </Grid>
      )}

      {hasError && (
        <Grid item>
          <Typography
            variant="h6"
            color="white"
            textAlign={"center"}
            children={props.error}
          />
        </Grid>
      )}

      {props.percentage < 1 && !hasError ? (
        <Grid item>
          <LinearProgress
            color="debianRed"
            variant="determinate"
            value={props.percentage * 100}
          ></LinearProgress>
        </Grid>
      ) : (
        <></>
      )}

      {props.percentage < 1 && !hasError && (
        <Grid item>
          <Typography variant="h6" color="white" textAlign={"center"}>
            {humanReadableSize(props.size)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
