/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Typography } from "@mui/material";
import { InternalPage } from "../../components/InternalPage/InternalPage";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { ImageKeys } from "../../utils/assets";
import { Routes } from "../../utils/routes";
import { useEffect, useMemo, useRef, useState } from "react";
import { TextCard } from "../../components/Card/TextCard";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { useWindowSize } from "@uidotdev/usehooks";
import { GenericCard } from "../../components/Card/GenericCard";
import { VideoKeys } from "../../utils/assets";
import { BeloteroIntroProdotti, useHandleIntro } from "./BeloteroIntroProdotti";
import { BeloteroLogo } from "../../components/Logos/BeloteroLogo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { NewVerticalScrollableCard } from "../../components/Card/NewVerticalScrollableCard";
import { KeyMessages } from "../../components/KeyMessages";
import { openModal } from "../../redux/modal.reducer";
import { PDFVisualizer } from "../../components/Card/PDFVisualizer";
import { setIndex } from "../../redux/belotero.reducer";
import { useAsset, useSingleAsset } from "../../components/AsssetsLoader";
import { Header, Inner } from "./BioRepeelPage";
import { heightToWidth } from "../../utils/functions";
import { BackgroundIntro } from "../../components/BackgroundIntro";

const row2WidthFraction = 0.2;

const animationRisultati = "bounce-in-right 2.5s ease-out";
const animationScopriIContenuti = "bounce-in-right 3s ease-out";

export function BeloteroPage() {
  const { changeRoute } = useRouter();
  const isVisible = useIsRouteVisible(Routes.belotero);
  const [videoIntro] = useAsset<VideoKeys>(["belotero_video_intro"]);
  const imageIntro = useSingleAsset<ImageKeys>("belotero_intro");
  const dispatch = useAppDispatch();

  if (!isVisible) return <></>;

  return (
    <InternalPage
      color="belotero"
      logoFill="black"
      backgroundColor="white"
      goUpper={() => {
        changeRoute(Routes.inostriprodotti, { showPrevious: false });
        dispatch(setIndex(null));
      }}
      goNext={() => {
        changeRoute(Routes.radiesse, { showPrevious: false });
        dispatch(setIndex(null));
      }}
      sectionHeader={
        <Header color="belotero" id={Routes.belotero}>
          <Typography
            variant="h3"
            textAlign="center"
            sx={{ color: "belotero.main" }}
          >
            <BeloteroLogo width={"20rem"} />
          </Typography>
        </Header>
      }
    >
      <Inner
        color="belotero"
        id={Routes.belotero}
        children={<Core />}
        videoKey="belotero_video_intro"
        logo={<BeloteroLogo width={"30rem"} />}
      />

      <BackgroundIntro
        id={Routes.belotero}
        type="video"
        src={videoIntro}
        whenQualityLow={imageIntro}
      />
    </InternalPage>
  );
}

function Core() {
  const ref = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ref.current) {
      const rect = (
        ref.current as unknown as HTMLElement
      ).getBoundingClientRect();
      setRect(rect);
    }
  }, []);

  return (
    <Box
      position={"fixed"}
      top={0}
      left={0}
      width={"100%"}
      height={"100%"}
      bgcolor={"white"}
    >
      <VerticalGrid type="container" rows={["70%", "30%"]}>
        {/* TODO GRADIENTE */}
        <VerticalGrid type="item" sx={{ backgroundColor: "bgGrey.main" }}>
          <VerticalGrid type="container" rows={["5rem", "auto"]}>
            <VerticalGrid type="item" />
            <div ref={ref} onClick={() => dispatch(setIndex(null))}>
              {rect && (
                <Grid
                  container
                  height={rect.height}
                  direction={"column"}
                  justifyContent={"space-around"}
                >
                  <BeloteroIntroProdotti />
                </Grid>
              )}
            </div>
          </VerticalGrid>
        </VerticalGrid>
        <VerticalGrid type="item" children={<BeloteroBottom />} />
      </VerticalGrid>
    </Box>
  );
}
function BeloteroBottom() {
  const index = useAppSelector((state) => state.belotero.index);
  const { width } = useWindowSize();

  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    if (ref.current !== null) {
      const height = ref.current.getBoundingClientRect().height;
      const padding = 2 * 8 * 2;
      setHeight(height - padding);
    }
  }, []);

  if (width === null || height === null)
    return <Box height={"100%"} pl={8} ref={ref} />;

  return index !== null ? (
    <Box pl={8} height={"100%"} py={2}>
      <BottomRow width={width} height={height} index={index} />
    </Box>
  ) : (
    <BeloterBottomVertical />
  );
}

function BeloterBottomVertical() {
  const { width, height } = useWindowSize();
  const [selected, setSelected] = useState<number>(0);

  const show = useHandleIntro("belotero", "after");
  const sliders = useSliders();
  const values = useMemo(() => Object.values(sliders), [sliders]);
  const [belotero_reologia, belotero_tecnologia] = useAsset<VideoKeys>([
    "belotero_reologia",
    "belotero_tecnologia",
  ]);
  const dispatch = useAppDispatch();

  if (width === null || height === null || !show) return <></>;

  return (
    <NewVerticalScrollableCard
      color="belotero"
      selected={selected}
      onSelectedIndexChange={setSelected}
      sx={{ backgroundColor: "white" }}
      elements={[
        {
          element: (
            <KeyMessages
              color="belotero"
              description={""}
              messages={[
                {
                  type: "line",
                  title: (
                    <Box component={"span"} color={"belotero.main"}>
                      <strong>
                        Si comporta
                        <br />
                        come la tua pelle
                      </strong>
                    </Box>
                  ),
                },
                {
                  type: "line",
                  title: (
                    <Box component={"span"} color={"belotero.main"}>
                      <strong>
                        Risultati naturali
                        <br />e personalizzabili
                      </strong>
                    </Box>
                  ),
                },
                {
                  type: "line",
                  title: (
                    <Box component={"span"} color={"belotero.main"}>
                      <strong>
                        Profilo di sicurezza
                        <br />a breve e lungo termine
                      </strong>
                    </Box>
                  ),
                },
                {
                  type: "line",
                  title: (
                    <Box component={"span"} color={"belotero.main"}>
                      <strong>
                        Proprietà reologiche
                        <br />
                        uniche
                      </strong>
                    </Box>
                  ),
                },
              ]}
            />
          ),
        },
        {
          element: (
            <Box pl={8} height={"100%"} py={2}>
              <HorizontalGrid
                type="container"
                sx={{
                  height: "100%",
                }}
                columnSize={width * row2WidthFraction}
                columns={6}
                gap={1}
              >
                <HorizontalGrid type="item">
                  <TextCard color="belotero" title="Before & After">
                    <GenericCard
                      video={values[0].video}
                      color="belotero"
                      onClick={(rect) =>
                        rect &&
                        dispatch(
                          openModal({
                            rect,
                            modalData: { type: "slider", sliders: values },
                            animated: false,
                          })
                        )
                      }
                    />
                  </TextCard>
                </HorizontalGrid>
                <HorizontalGrid type="item">
                  <TextCard color="belotero" title="Come agisce">
                    <PDFVisualizer
                      image="belotero_comeagisce"
                      pages={1}
                      color="belotero"
                      bgColor="#F3F3F3"
                    />
                  </TextCard>
                </HorizontalGrid>
                <HorizontalGrid type="item">
                  <TextCard color="belotero" title="Tecnologia">
                    <GenericCard
                      color="belotero"
                      startTime={2}
                      video={belotero_tecnologia}
                    />
                  </TextCard>
                </HorizontalGrid>
                <HorizontalGrid type="item">
                  <TextCard color="belotero" title="Reologia">
                    <GenericCard
                      color="belotero"
                      startTime={100}
                      video={belotero_reologia}
                    />
                  </TextCard>
                </HorizontalGrid>
                <HorizontalGrid type="item">
                  <TextCard color="belotero" title="Science brief">
                    <PDFVisualizer
                      image="belotero_science_brief"
                      pages={25}
                      color="belotero"
                      bgColor="#F3F3F3"
                    />
                  </TextCard>
                </HorizontalGrid>
                <HorizontalGrid type="item">
                  <TextCard color="belotero" title="Scopri di più">
                    <PDFVisualizer
                      image="belotero_scopridipiu"
                      pages={2}
                      color="belotero"
                      bgColor="#F3F3F3"
                    />
                  </TextCard>
                </HorizontalGrid>
              </HorizontalGrid>
            </Box>
          ),
        },
      ]}
    ></NewVerticalScrollableCard>
  );
}
interface BottomRowProps {
  index: number;
  width: number;
  height: number;
}

function BottomRow(props: BottomRowProps) {
  const dispatch = useAppDispatch();

  const [
    belotero_video_trattemento_revive,
    belotero_video_trattemento_lips,
    belotero_video_trattemento_balance,
    belotero_video_trattemento_intense,
    belotero_video_trattemento_volume,
    belotero_video_trattemento_soft,
  ] = useAsset<VideoKeys>([
    "belotero_video_trattemento_revive",
    "belotero_video_trattemento_lips",
    "belotero_video_trattemento_balance",
    "belotero_video_trattemento_intense",
    "belotero_video_trattemento_volume",
    "belotero_video_trattemento_soft",
  ]);
  const [
    belotero_scopridipiu_balance,
    belotero_scopridipiu_intense,
    belotero_scopridipiu_lips,
    belotero_scopridipiu_revive,
    belotero_scopridipiu_soft,
    belotero_scopridipiu_volume,
  ] = useAsset<ImageKeys>([
    "belotero_scopridipiu_balance",
    "belotero_scopridipiu_intense",
    "belotero_scopridipiu_lips",
    "belotero_scopridipiu_revive",
    "belotero_scopridipiu_soft",
    "belotero_scopridipiu_volume",
  ]);

  const sliders = useSliders();

  //TODO: remove hardcoded 42
  const heightCard = useMemo(() => props.height - 42, [props.height]);

  switch (props.index) {
    //REVIVE
    case 0:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={3}
          gap={1}
        >
          <HorizontalGrid type="item" sx={{ animation: animationRisultati }}>
            <TextCard color="belotero" title="Before & After">
              <GenericCard
                color="belotero_revive"
                width={"100%"}
                sx={{ backgroundColor: "belotero_revive.main" }}
                video={sliders.weisser.video}
                onClick={(rect) =>
                  rect &&
                  dispatch(
                    openModal({
                      modalData: {
                        type: "slider",
                        sliders: [
                          sliders.matthias,
                          sliders.weisser,
                          sliders.solvita,
                        ],
                      },
                      rect,
                      animated: false,
                    })
                  )
                }
              />
            </TextCard>
          </HorizontalGrid>

          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="Scopri i contenuti">
              <GenericCard
                width={"100%"}
                color="belotero_revive"
                video={belotero_video_trattemento_revive}
                startTime={4}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="S" visibility="hidden">
              <GenericCard
                width={"100%"}
                color="belotero_revive"
                img={belotero_scopridipiu_revive}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      );
    case 1:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={2}
          gap={1}
        >
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="Scopri i contenuti">
              <GenericCard
                width={"100%"}
                color="belotero_soft"
                video={belotero_video_trattemento_soft}
                startTime={4}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="S" visibility="hidden">
              <GenericCard
                width={"100%"}
                color="belotero_soft"
                img={belotero_scopridipiu_soft}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      );
    //BALANCE
    case 2:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={3}
          gap={1}
        >
          <HorizontalGrid type="item" sx={{ animation: animationRisultati }}>
            <TextCard color="belotero" title="Before & After">
              <GenericCard
                width={"100%"}
                color="belotero_balance"
                sx={{ backgroundColor: "belotero_balance.main" }}
                video={sliders.caroline.video}
                onClick={(rect) =>
                  rect &&
                  dispatch(
                    openModal({
                      modalData: {
                        type: "slider",
                        sliders: [
                          sliders.caroline,
                          sliders.gordana,
                          sliders.marion,
                          sliders.matthias,
                          sliders.siham,
                          sliders.mirjana,
                          sliders.weisser,
                          sliders.simone,
                          sliders.jessica,
                          sliders.milena,
                          sliders.sina,
                        ],
                      },
                      rect,
                      animated: false,
                    })
                  )
                }
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="Scopri i contenuti">
              <GenericCard
                width={"100%"}
                color="belotero_balance"
                video={belotero_video_trattemento_balance}
                startTime={2}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="S" visibility="hidden">
              <GenericCard
                width={"100%"}
                color="belotero_balance"
                img={belotero_scopridipiu_balance}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      );
    //INTENSE
    case 3:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={3}
          gap={1}
        >
          <HorizontalGrid type="item" sx={{ animation: animationRisultati }}>
            <TextCard color="belotero" title="Before & After">
              <GenericCard
                width={"100%"}
                color="belotero_intense"
                sx={{ backgroundColor: "belotero_intense.main" }}
                video={sliders.gordana.video}
                onClick={(rect) =>
                  rect &&
                  dispatch(
                    openModal({
                      modalData: {
                        type: "slider",
                        sliders: [
                          sliders.gordana,
                          sliders.siham,
                          sliders.lidia,
                          sliders.milena,
                          sliders.sina,
                          sliders.mirjana,
                          sliders.solvita,
                        ],
                      },
                      rect,
                      animated: false,
                    })
                  )
                }
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="Scopri i contenuti">
              <GenericCard
                width={"100%"}
                color="belotero_intense"
                video={belotero_video_trattemento_intense}
                startTime={2}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="S" visibility="hidden">
              <GenericCard
                width={"100%"}
                color="belotero_intense"
                img={belotero_scopridipiu_intense}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      );
    //VOLUME
    case 4:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={3}
          gap={1}
        >
          <HorizontalGrid type="item" sx={{ animation: animationRisultati }}>
            <TextCard color="belotero" title="Before & After">
              <GenericCard
                width={"100%"}
                color="belotero_volume"
                sx={{ backgroundColor: "belotero_volume.main" }}
                video={sliders.gordana.video}
                onClick={(rect) =>
                  rect &&
                  dispatch(
                    openModal({
                      modalData: {
                        type: "slider",
                        sliders: [
                          sliders.gordana,
                          sliders.marion,
                          sliders.siham,
                          sliders.mirjana,
                          sliders.simone,
                          sliders.jessica,
                          sliders.sina,
                          sliders.solvita,
                        ],
                      },
                      rect,
                      animated: false,
                    })
                  )
                }
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="Scopri i contenuti">
              <GenericCard
                width={"100%"}
                color="belotero_volume"
                video={belotero_video_trattemento_volume}
                startTime={2}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="S" visibility="hidden">
              <GenericCard
                width={"100%"}
                color="belotero_volume"
                img={belotero_scopridipiu_volume}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      );
    //LIPS
    case 5:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={3}
          gap={1}
        >
          <HorizontalGrid type="item" sx={{ animation: animationRisultati }}>
            <TextCard color="belotero" title="Before & After">
              <GenericCard
                color="belotero_lips"
                width={"100%"}
                sx={{ backgroundColor: "belotero_lips.main" }}
                video={sliders.marion.video}
                onClick={(rect) =>
                  rect &&
                  dispatch(
                    openModal({
                      modalData: {
                        type: "slider",
                        sliders: [
                          sliders.marion,
                          sliders.siham,
                          sliders.mirjana,
                          sliders.lidia,
                          sliders.milena,
                          sliders.solvita,
                        ],
                      },
                      rect,
                      animated: false,
                    })
                  )
                }
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="Scopri i contenuti">
              <GenericCard
                width={"100%"}
                color="belotero_lips"
                video={belotero_video_trattemento_lips}
                startTime={2}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
          <HorizontalGrid
            type="item"
            sx={{ animation: animationScopriIContenuti }}
          >
            <TextCard color="belotero" title="S" visibility="hidden">
              <GenericCard
                width={"100%"}
                color="belotero_lips"
                img={belotero_scopridipiu_lips}
                sx={{ backgroundColor: "white" }}
              />
            </TextCard>
          </HorizontalGrid>
        </HorizontalGrid>
      );
    default:
      return (
        <HorizontalGrid
          type="container"
          sx={{
            height: "100%",
          }}
          columnSize={heightToWidth(heightCard)}
          columns={5}
          gap={1}
        ></HorizontalGrid>
      );
  }
}

export type BeloteroSlider = {
  video: string;
  title: string;
  trattamento: string;
  before: string;
  after: string;
  prodotti: {
    title: string;
    amount: string;
  }[];
  desc: string;
};
export type BeloteroSliders = Record<string, BeloteroSlider>;

function useSliders() {
  const [
    belotero_video_ba_carolin,
    belotero_video_ba_gordana,
    belotero_video_ba_jessica,
    belotero_video_ba_lidia,
    belotero_video_ba_marion,
    belotero_video_ba_matthias,
    belotero_video_ba_milena,
    belotero_video_ba_mirjana,
    belotero_video_ba_weisser,
    belotero_video_ba_sihan,
    belotero_video_ba_simone,
    belotero_video_ba_sina,
    belotero_video_ba_solvita,
  ] = useAsset<VideoKeys>([
    "belotero_video_ba_carolin",
    "belotero_video_ba_gordana",
    "belotero_video_ba_jessica",
    "belotero_video_ba_lidia",
    "belotero_video_ba_marion",
    "belotero_video_ba_matthias",
    "belotero_video_ba_milena",
    "belotero_video_ba_mirjana",
    "belotero_video_ba_weisser",
    "belotero_video_ba_sihan",
    "belotero_video_ba_simone",
    "belotero_video_ba_sina",
    "belotero_video_ba_solvita",
  ]);

  return useMemo(() => {
    return {
      caroline: {
        video: belotero_video_ba_carolin,
        title: "Carolin Trappen - Hauf",
        trattamento: "05 August 2021",
        before: "22/23 July 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Balance Lidocaine",
            amount: "2ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 2 ml di Belotero® Balance con Lidocaina (tear trough 0,4 ml per lato, piega nasolabiale 0,4 ml per lato, bordo del vermiglio 0,4 ml). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      gordana: {
        video: belotero_video_ba_gordana,
        title: "Gordana Grgic",
        trattamento: "05 August 2021",
        before: "22/23 July 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Intense Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Volume Lidocaine",
            amount: "5ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "1ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 1 ml di Belotero® Balance con Lidocaina (labbra 1,0 ml), 1 ml di Belotero® Intense con Lidocaina (piega nasolabiale 0,5 ml per lato), 5 ml di Belotero®Volume con Lidocaina (guance 2,5 ml per lato ). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      marion: {
        video: belotero_video_ba_marion,
        title: "Marion Holler",
        before: "22/23 July 2021",
        trattamento: "05 August 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Volume Lidocaine",
            amount: "4ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Lips Shape",
            amount: "0.6ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 1 ml di Belotero® Balance con Lidocaina (labbro superiore 1 ml), 4 ml di Belotero® Volume con Lidocaina (guance 2 ml per lato), 0,6 ml di Belotero® Lips Shape. I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      matthias: {
        video: belotero_video_ba_matthias,
        title: "Matthias Hofmann",
        before: "22/23 July 2021",
        trattamento: "05 August 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Balance Lidocaine",
            amount: "2ml",
          },
          {
            title: "Belotero Revive Lidocaine",
            amount: "2ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 2 ml di di Belotero® Balance con Lidocaina (tear trough 0,5 ml per lato, piega nasolabiale 0,2 ml per lato, fronte 0,6 ml), 2 ml di Belotero® Revive (guance 1 ml per lato). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      siham: {
        video: belotero_video_ba_sihan,
        title: "Siham Iddouch",
        before: "22/23 July 2021",
        trattamento: "05 August 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Intense Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Volume Lidocaine",
            amount: "3ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Lips Contour",
            amount: "0.6ml",
          },
        ],
        desc: "Al paziente sono staio iniettati 1 ml di Belotero® Balance con Lidocaina (tear trough 0,5 ml per lato), 1 ml di Belotero® Intense con Lidocaina (piega nasolabiale 0,2 ml per lato, bordo del vermiglio 0,3 ml), 3 ml di Belotero® Volume con Lidocaina (guance 1,5 ml per lato), 0,6 ml di Belotero®Lips Contour. I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      mirjana: {
        video: belotero_video_ba_mirjana,
        title: "Mirjana Schröer",
        before: "22/23 July 2021",
        trattamento: "05 August 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Volume Lidocaine",
            amount: "2.2ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Lips Shape",
            amount: "0.6ml",
          },
        ],
        desc: "Al paziente sono stati stato iniettati 1 ml di Belotero®Balance con Lidocaina (bordo del vermiglio 0,6 ml, piega nasolabiale 0,2 ml per lato), 2,2 ml di Belotero® Volume con Lidocaina (mento 0,2 ml, tempie 0,5 ml per lato, guance 0,5 ml per lato), 0,6 ml di Belotero® Lips Shape. I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      weisser: {
        video: belotero_video_ba_weisser,
        title: "Sandra Weisser",
        before: "20 August 2021",
        trattamento: "01 September 2021",
        after: "13 September 2021",
        prodotti: [
          {
            title: "Belotero Revive",
            amount: "2ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "1ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 1 ml di Belotero® Balance con Lidocaina (piega nasolabiale 0,5 ml per lato), 2 ml di Belotero® Revive (guance 1 ml per lato). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      simone: {
        video: belotero_video_ba_simone,
        title: "Simone Kostka",
        before: "20 August 2021",
        trattamento: "1 September 2021",
        after: "13 September 2021",
        prodotti: [
          {
            title: "Belotero Volume Lidocaine",
            amount: "8ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "3ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 3 ml di Belotero® Balance con Lidocaina (tear trough 0,2 ml per lato, rughe della marionetta 1,3 ml per lato), 8 ml di Belotero® Volume con Lidocaina (guance 1,5 ml per lato, mento 3 ml, tempie 1 ml per lato). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      jessica: {
        video: belotero_video_ba_jessica,
        title: "Jessica",
        before: "22/23 July 2021",
        trattamento: "05 August 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Volume Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Balance Lidocaine",
            amount: "2ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 2 ml di Belotero® Balance con Lidocaina (tear trough 0,5 ml per lato, labbra 1 ml), 1 ml di Belotero® Volume con Lidocaina (rughe della marionetta 0,5 ml per lato). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      lidia: {
        video: belotero_video_ba_lidia,
        title: "Lidia",
        before: "20 August 2021",
        trattamento: "1 September 2021",
        after: "13 September 2021",
        prodotti: [
          {
            title: "Belotero Volume Lidocaine",
            amount: "2ml",
          },
          {
            title: "Belotero Intese Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Lips Contour",
            amount: "0.6ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 1 ml di Belotero® Balance con Lidocaina (labbra 1 ml), 2 ml di Belotero® Volume con Lidocaina (guance 1 ml per lato), 0,6 ml di Belotero® Lips Contour. I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      milena: {
        video: belotero_video_ba_milena,
        title: "Milena",
        before: "22/23 July 2021",
        trattamento: "05 August 2021",
        after: "19/20 August 2021",
        prodotti: [
          {
            title: "Belotero Balance Lidocaine",
            amount: "1ml",
          },
          {
            title: "Belotero Lips Shape",
            amount: "0.6ml",
          },
          {
            title: "Belotero Lips Contour",
            amount: "0.6ml",
          },
          {
            title: "Belotero Intese Lidocaine",
            amount: "0.4ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 1 ml di Belotero® Balance con Lidocaina (tear trough 0,5 ml per lato), 0,4 ml, Belotero® Intense con Lidocaina (piega nasolabiale 0,2 ml per lato), 0,6 ml di Belotero® Lips Contour, 0,6 ml di Belotero®Lips Shape. I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      sina: {
        video: belotero_video_ba_sina,
        title: "Sina",
        before: "20 August 2021",
        trattamento: "1 September 2021",
        after: "13 September 2021",
        prodotti: [
          {
            title: "Belotero Balance Lidocaine",
            amount: "0.4ml",
          },
          {
            title: "Belotero Intese Lidocaine",
            amount: "0.2ml",
          },
          {
            title: "Belotero Volume Lidocaine",
            amount: "1.2ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 0,4 ml di Belotero® Balance con Lidocaina (tear trough 0,2 ml per lato), 0,2 ml di Belotero® Intense con Lidocaina (piega nasolabiale 0,1 ml per lato), 1,2 ml di Belotero® Volume con Lidocaina (guance 0,6 ml per lato). I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
      solvita: {
        video: belotero_video_ba_solvita,
        title: "Solvita",
        before: "20 August 2021",
        trattamento: "1 September 2021",
        after: "13 September 2021",
        prodotti: [
          {
            title: "Belotero Volume Lido",
            amount: "2ml",
          },
          {
            title: "Belotero Intense Lido",
            amount: "0.4ml",
          },
          {
            title: "Belotero Revive",
            amount: "1ml",
          },
          {
            title: "Belotero Lips shape",
            amount: "0.6ml",
          },
          {
            title: "Belotero Lips contour",
            amount: "0.6ml",
          },
        ],
        desc: "Al paziente sono stati iniettati 0,4 ml di Belotero® Intense con Lidocaina (piega nasolabiale 0,2 ml per lato), 2 ml di Belotero® Volume con Lidocaina (zigomi 1 ml per lato), 1 ml di Belotero® Revive (guance 0,5 ml per lato), 0,6 ml di Belotero® Lips Contour , 0,6 ml di Belotero® Lips Shape. I risultati mostrati sono stati rilevati 14 giorni dopo il trattamento. Nessun ritocco dell'immagine è stato fatto. I risultati individuali possono variare.",
      },
    };
  }, [
    belotero_video_ba_carolin,
    belotero_video_ba_gordana,
    belotero_video_ba_marion,
    belotero_video_ba_matthias,
    belotero_video_ba_sihan,
    belotero_video_ba_mirjana,
    belotero_video_ba_weisser,
    belotero_video_ba_simone,
    belotero_video_ba_jessica,
    belotero_video_ba_lidia,
    belotero_video_ba_milena,
    belotero_video_ba_sina,
    belotero_video_ba_solvita,
  ]);
}
