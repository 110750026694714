import { Box, SxProps, Theme } from "@mui/material";
import { CSSProperties, useMemo } from "react";
import { transitionEnabled } from "../../../constants";

export const transitionTypes = ["topDown", "bottomUp"] as const;

export interface TransitionProps {
  animation: (typeof transitionTypes)[number];
  on: boolean;
  children?: JSX.Element;
  duration: number;
  timingFunction: CSSProperties["transitionTimingFunction"];
}

const endPosition = {
  opacity: "100%",
  transform: "translate(0%) rotate3d(0)",
  transformStyle: "preserve-3d",
};

const startPosition = (transform: string) => ({
  opacity: "0%",
  transform,
  transformStyle: "preserve-3d",
});

export function Transition(props: TransitionProps) {
  const style: SxProps<Theme> = useMemo(() => {
    switch (props.animation) {
      case "topDown":
        return props.on
          ? endPosition
          : startPosition("translateY(-150%) rotate3d(0, 1, -1, 45deg)");
      case "bottomUp":
        return props.on
          ? endPosition
          : startPosition("translateY(150%) rotate3d(0, 1, -1, 45deg)");
      default:
        return {};
    }
  }, [props.animation, props.on]);

  return (
    <Box
      component="div"
      sx={{
        ...style,
        height: "100%",
        visibility: props.on ? "visible" : "hidden",
        transition: !transitionEnabled
          ? undefined
          : !transitionEnabled
          ? undefined
          : props.on
          ? `all ${props.duration}s ${props.timingFunction}`
          : undefined,
        transitionDelay: "6s",
      }}
    >
      {props.children}
    </Box>
  );
}
