import { Box, Grid, Typography } from "@mui/material";
import { TextWithLines } from "../../components/Atoms/TextWithLines";
import { VerticalGrid } from "../../components/HorizontalGrid/HorizontalGrid";
import {
  AdvancedTrainingOpts,
  ElitePages,
  useSelectedCircleOption,
} from "../../redux/elite.reducer";
import { TriangleSelection, BottomCircle } from "./utils";
import { useAppDispatch } from "../../redux/hooks";
import { ImageKeys } from "../../utils/assets";
import { VideoKeys } from "../../utils/assets";
import { GenericCard } from "../../components/Card/GenericCard";
import { useAsset } from "../../components/AsssetsLoader";
import { openModal } from "../../redux/modal.reducer";
import { CSSProperties } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

export function AdvancedTraining() {
  const { width } = useWindowSize();
  const isTablet = width && width < 900;
  const circleHeight = 35;
  return (
    <>
      <VerticalGrid
        type="container"
        rows={["7rem", "auto", `${circleHeight / 2}rem`]}
      >
        <VerticalGrid type="item"></VerticalGrid>
        <VerticalGrid type="item">
          <Grid
            container
            height={"100%"}
            width={"100%"}
            rowSpacing={1}
            direction={"column"}
          >
            <Grid item width={"100%"}>
              <TextWithLines animation="blur 1s ease-out">
                <Typography
                  variant="h4"
                  textAlign={"center"}
                  style={{ animation: "text-focus-in 2s ease-out" }}
                >
                  <strong>
                    Investiamo sulla tua <i>formazione avanzata</i>
                    <br />
                    con i migliori esperti nazionali e internazionali
                  </strong>
                </Typography>
              </TextWithLines>
            </Grid>
            <Grid item xs>
              <Grid
                container
                direction={"column"}
                height={"100%"}
                justifyContent={"space-between"}
              >
                <Grid item xs>
                  <Core />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"end"}
                    alignContent={"center"}
                    height={"100%"}
                    rowGap={0.5}
                  >
                    <Grid item alignSelf={"center"}>
                      <TriangleSelection id={ElitePages.ADVANCED_TRAINING} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VerticalGrid>
        <VerticalGrid type="item"></VerticalGrid>
      </VerticalGrid>
      <BottomCircle
        id={ElitePages.ADVANCED_TRAINING}
        height={circleHeight}
        startAngle={180}
        endAngle={540}
        boxWidth={isTablet ? 30 : 28}
        boxHeight={5}
        rectFont={isTablet ? "2.5rem" : "2rem"}
      />
    </>
  );
}

function Core() {
  const option = useSelectedCircleOption<AdvancedTrainingOpts>(
    ElitePages.ADVANCED_TRAINING
  );
  switch (option) {
    case AdvancedTrainingOpts.INTERNATIONAL_MASTERCLASS:
      return (
        <InternalAdvancedTraining
          images={[
            { key: "elite_imclass_gallery_1" },
            {
              key: "elite_imclass_gallery_2",
            },
          ]}
          videos={[
            { key: "elite_imclass_video_1" },
            { key: "elite_imclass_video_2" },
          ]}
          startTime={3}
        >
          <Typography>
            Esperienza scientifica avanzata per un gruppo
            <br />
            selezionato in una delle capitali Europee, con i nostri
            <br />
            esperti globali su focus particolari e nuovi protocolli
            <br />
            combinati di trattamento.
          </Typography>
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.INTERNATIONAL_MEXS:
      return (
        <InternalAdvancedTraining
          images={[
            { key: "elite_imexs_gallery_1" },
            { key: "elite_imexs_gallery_2" },
            { key: "elite_imexs_gallery_3" },
          ]}
          videos={[{ key: "elite_imexs_video_1" }]}
        >
          <Typography>
            Format esclusivo firmato Merz Aesthetics
            <br />
            un'occasione di formazione avanzata con oltre 500
            <br />
            esperti internazionali e una faculty di spessore sui
            <br />
            nuovi trend di trattamento
          </Typography>
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.MEXS_ITALY:
      return (
        <InternalAdvancedTraining
          images={[
            { key: "elite_itmexs_gallery_1" },
            { key: "elite_itmexs_gallery_2" },
            { key: "elite_itmexs_gallery_3" },
          ]}
          videos={[{ key: "elite_itmexs_video_1" }]}
          startTime={22}
        >
          <Typography>
            Format esclusivo firmato Merz Aesthetics
            <br />
            un'occasione di formazione avanzata con oltre 250
            <br />
            esperti nazionali e una faculty internazionale sui
            <br />
            nuovi trend di trattamento
          </Typography>
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.HANDS_ON:
      return (
        <InternalAdvancedTraining images={[{ key: "elite_handson_gallery_1" }]}>
          <Typography>
            Master course presso UMA clinic - Amsterdam.
            <br />
            Un'opportunità unica di incontrare l'equipe
            <br />
            del Dr. Jani van Loghem. KOL Global Merz Aesthetics
            <br />
            ed esperto sulle tecniche avanzate e protocolli
            <br />
            combinati.
          </Typography>
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.PRIVATE_FACE_TO_FACE:
      return (
        <InternalAdvancedTraining videos={[{ key: "elite_face_to_face" }]}>
          <Typography>
            Confronto avanzato one-to-one con focus a scelta
            <br />
            con uno dei nostri migliori esperti nazionali.
          </Typography>
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.STAFF_TRAINING:
      return (
        <InternalAdvancedTraining>
          In ottica di offrirti supporto a 360°, pianifichiamo <br />
          insieme a te la formazione del tuo team nelle aree
          <br /> di maggiore interesse.
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.EXPERT_ROUND_TABLE:
      return (
        <InternalAdvancedTraining
          images={[
            { key: "elite_expert_gallery_2" },
            { key: "elite_expert_gallery_1" },
            { key: "elite_expert_gallery_3" },
            { key: "elite_expert_gallery_4" },
          ]}
          videos={[]}
        >
          <Typography>
            Possibilità per piccoli gruppi di incontri di discussione
            <br />
            su focus specifici.
          </Typography>
        </InternalAdvancedTraining>
      );
    case AdvancedTrainingOpts.INTERNATIONAL_CONGRESS_AMWC:
      return (
        <InternalAdvancedTraining
          images={[{ key: "elite_amcw_gallery_1" }]}
          videos={[{ key: "elite_amcw_video_1" }]}
          startTime={69}
        />
      );
    case AdvancedTrainingOpts.INTERNATIONAL_CONGRESS_IMCAS:
      return (
        <InternalAdvancedTraining
          videos={[{ key: "elite_imcas_video_1" }]}
          startTime={78}
        />
      );
    default:
      return <></>;
  }
}

function InternalAdvancedTraining(props: {
  children?: React.ReactNode;
  images?: Array<{ key: ImageKeys; styles?: CSSProperties }>;
  videos?: Array<{ key: VideoKeys; styles?: CSSProperties }>;
  startTime?: number;
}) {
  const boxWidth = 25;
  const videos = useAsset(props.videos?.map((e) => e.key) ?? []);
  const images = useAsset(props.images?.map((e) => e.key) ?? []);
  const dispatch = useAppDispatch();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignContent={"center"}
      height={"100%"}
      columnGap={1}
      sx={{
        animation: "blur 1s ease-out",
      }}
    >
      {props.children && (
        <Box width={`${boxWidth}rem`} height={`${boxWidth / 1.77}rem`}>
          <Grid
            container
            alignContent={"center"}
            height={"100%"}
            children={props.children}
          />
        </Box>
      )}
      {images.length > 0 && (
        <Box width={`${boxWidth}rem`} height={`${boxWidth / 1.77}rem`}>
          <GenericCard
            byWidth
            img={images[0]}
            onClick={(rect, id) => {
              if (rect === undefined) return;
              dispatch(
                openModal({
                  modalData: {
                    type: "carousel",
                    items: images.map((src, i) => ({
                      src,
                      type: "image",
                      style:
                        props?.images !== undefined
                          ? props.images[i].styles
                          : undefined,
                    })),
                  },
                  rect,
                  id,
                })
              );
            }}
          />
        </Box>
      )}
      {videos.length > 0 && (
        <Box width={`${boxWidth}rem`} height={`${boxWidth / 1.77}rem`}>
          <GenericCard
            byWidth
            video={videos[0]}
            startTime={props.startTime}
            onClick={(rect, id) => {
              if (rect === undefined) return;
              dispatch(
                openModal({
                  modalData: {
                    type: "carousel",
                    items: videos.map((src) => ({
                      src,
                      type: "video",
                      style: { height: "100%", width: "auto" },
                    })),
                  },
                  rect,
                  id,
                })
              );
            }}
          />
        </Box>
      )}
    </Grid>
  );
}
