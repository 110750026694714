import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="104.72 3.6 22.36 28.21"
    fill="white"
    {...props}
  >
    <path d="M126.39 15.97a11.96 11.96 0 0 0-11.25-7.9H108l3.11-3.11-1.36-1.36L105 8.35a.99.99 0 0 0 0 1.39l4.75 4.75 1.36-1.36-3.12-3.13h7.14c2.8 0 5.46 1.17 7.36 3.22a9.828 9.828 0 0 1 2.62 7.5c-.42 5.16-4.73 9.14-9.91 9.16h-9.55v1.93h9.55c6.55.01 11.86-5.29 11.87-11.84.01-1.36-.22-2.71-.68-4" />
  </svg>
);
export { SvgComponent as ReturnIcon };
