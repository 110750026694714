import { Box, BoxProps, Grid, Typography } from "@mui/material";
import { useAsset } from "../../components/AsssetsLoader";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Colors } from "../../styles/theme";
import { ZIndexes } from "../InternalPage/InternalPage";
import { Line } from "../Atoms/Lines";
import { VerticalGrid } from "../HorizontalGrid/HorizontalGrid";
import { openModal } from "../../redux/modal.reducer";
import { useAppDispatch } from "../../redux/hooks";

interface PDFVisualizerProps {
  image: string;
  pages: number;
  color: Colors;
  compute?: boolean;
  bgColor?: CSSProperties["backgroundColor"];
  width?: CSSProperties["width"];
  height?: string;
  position?: CSSProperties["position"];
  text?: string;
  byWidth?: boolean;
}

export function PDFVisualizer(props: PDFVisualizerProps) {
  const dispatch = useAppDispatch();

  const images = useAsset(
    new Array(props.pages).fill(0).map((_, i) => `${props.image}${i}`)
  );

  const thumbnailRef = useRef<HTMLImageElement>(null);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  const thumbnail = useMemo(() => images[0], [images]);
  const [thumbnailRect, setThumbnailRect] = useState<DOMRect | undefined>();

  useEffect(() => {
    if (thumbnailContainerRef.current) {
      setThumbnailRect(thumbnailContainerRef.current.getBoundingClientRect());
    }
  }, []);

  const onClick = useCallback(() => {
    if (thumbnailRef.current !== null) {
      dispatch(
        openModal({
          rect: thumbnailRef.current.getBoundingClientRect(),
          modalData: {
            type: "pdf",
            pdf: props.image,
            pages: props.pages,
          },
        })
      );
    }
  }, [dispatch, props.image, props.pages]);

  if (images.length === 0) return <></>;

  return (
    <VerticalGrid
      type="container"
      rows={[props.height ?? "auto", props.text ? "2.5rem" : "6px"]}
      sx={{ border: "0.1rem solid", borderColor: `${props.color}.main` }}
    >
      <VerticalGrid type="item">
        <Box
          sx={{ height: "100%", width: "100%" }}
          onClick={onClick}
          ref={thumbnailContainerRef}
        >
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            position={props.position}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: props.bgColor,
            }}
          >
            {thumbnailRect && (
              <img
                alt="Thumbnail"
                ref={thumbnailRef}
                src={thumbnail}
                style={{
                  height: props.byWidth ? "auto" : thumbnailRect.height,
                  width: props.byWidth ? thumbnailRect.width : "auto",
                  objectFit: "cover",
                }}
              />
            )}
          </Grid>
        </Box>
      </VerticalGrid>

      <VerticalGrid type="item">
        <Line height={"100%"} color={props.color}>
          {props.text && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              onClick={onClick}
            >
              <Typography textAlign={"center"}>{props.text}</Typography>
            </Grid>
          )}
        </Line>
      </VerticalGrid>
    </VerticalGrid>
  );
}

interface ModalProps {
  open: boolean;
  handleClose?: () => void;
  children?: BoxProps["children"];
}
export function Modal({ open, children, handleClose }: ModalProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: open ? ZIndexes.dialog : ZIndexes.hide,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: open ? 1 : 0,
        visibility: open ? "visible" : "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onClick={handleClose}
    >
      {open && <Box width={"100%"} height={"100%"} children={children} />}
    </Box>
  );
}
