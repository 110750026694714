import { LogoProps } from "./BocotureLogo";
const SvgComponent = (props: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Livello_2_00000029023935125939539630000013568177799414570132_"
    x={0}
    y={0}
    viewBox="0 0 166.7 58"
    {...props}
  >
    <g id="Livello_1-2">
      <g>
        <path d="M73.1 4.6h5.2c2.7 0 4.4 1.6 4.4 4.1 0 2.1-1.3 3.6-3.4 3.9l3.4 6h-1.2l-3.3-5.8h-4v5.8h-1.1v-14zm1.1 1v6.2h4c2.1 0 3.4-1.2 3.4-3.1 0-1.9-1.3-3.1-3.4-3.1h-4z" />
      </g>
      <g>
        <path d="m84.2 18.6 5.4-14h1.3l5.4 14h-1.2l-1.7-4.3H87l-1.7 4.3h-1.1zm3.1-5.3h5.8l-2.9-7.5-2.9 7.5z" />
      </g>
      <g>
        <path d="M98.1 18.6v-14h4.1c4.4 0 7 2.7 7 7 0 4.2-2.6 7-7 7h-4.1zm1.1-1h3c3.8 0 5.9-2.3 5.9-6s-2.1-6-5.9-6h-3v12z" />
      </g>
      <g>
        <path d="M111.6 4.6h1.1v14h-1.1v-14z" />
      </g>
      <g>
        <path d="M115.7 4.6h8.6v1h-7.5v5.3h6.3v1h-6.3v5.7h7.8v1h-8.9v-14z" />
      </g>
      <g>
        <path d="M126.8 14.3c.1 2.1 1.7 3.4 4.1 3.4 2.3 0 3.8-1.1 3.8-2.9 0-2-2-2.5-4.2-2.9-2.2-.4-4.5-1-4.5-3.6 0-2.4 1.9-3.9 4.7-3.9 2.9 0 4.7 1.6 4.9 4h-1.1c-.2-1.8-1.7-3-3.8-3-2.2 0-3.6 1-3.6 2.8 0 2 1.9 2.4 4 2.7 2.3.4 4.7 1.1 4.7 3.7 0 2.4-1.9 4-4.9 4-3.1 0-5.1-1.8-5.2-4.4h1.1z" />
      </g>
      <g>
        <path d="M138.7 14.3c.1 2.1 1.7 3.4 4.1 3.4 2.3 0 3.8-1.1 3.8-2.9 0-2-2-2.5-4.2-2.9-2.2-.4-4.5-1-4.5-3.6 0-2.4 1.9-3.9 4.7-3.9 2.9 0 4.7 1.6 4.9 4h-1.1c-.2-1.8-1.7-3-3.8-3-2.2 0-3.6 1-3.6 2.8 0 2 1.9 2.4 4 2.7 2.3.4 4.7 1.1 4.7 3.7 0 2.4-1.9 4-4.9 4-3.1 0-5.1-1.8-5.2-4.4h1.1z" />
      </g>
      <g>
        <path d="M150.2 4.6h8.6v1h-7.5v5.3h6.3v1h-6.3v5.7h7.8v1h-8.9v-14z" />
      </g>
      <g>
        <path d="M163.5 3.2c.6 0 1.1.1 1.6.4.5.3.9.7 1.2 1.2.3.5.4 1.1.4 1.7 0 .6-.1 1.1-.4 1.6-.3.5-.7.9-1.2 1.2-.5.3-1.1.4-1.7.4s-1.1-.1-1.7-.4c-.5-.3-.9-.7-1.2-1.2s-.4-1.1-.4-1.6c0-.6.1-1.1.4-1.7.3-.5.7-.9 1.2-1.2.7-.3 1.2-.4 1.8-.4zm0 .5c-.5 0-.9.1-1.4.4-.4.2-.8.6-1 1-.2.4-.4.9-.4 1.4 0 .5.1.9.4 1.4.2.4.6.8 1 1 .4.2.9.4 1.4.4s.9-.1 1.4-.4c.4-.2.8-.6 1-1 .2-.4.4-.9.4-1.4 0-.5-.1-.9-.4-1.4-.2-.4-.6-.8-1-1-.5-.3-1-.4-1.4-.4zM162 8.3V4.7h1.2c.4 0 .7 0 .9.1.2.1.3.2.4.3s.2.3.2.5c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3.1.1.2.1.3.2.1.1.3.4.5.7l.4.7h-.7l-.2-.4c-.2-.4-.5-.7-.6-.8-.1-.1-.3-.1-.5-.1h-.3v1.5h-.6zm.6-2h.7c.3 0 .6 0 .7-.1.1-.1.2-.2.2-.4 0-.1 0-.2-.1-.3l-.2-.2c-.1 0-.3-.1-.6-.1h-.7v1.1z" />
      </g>
      <g>
        <path d="M72.1 24.2h10.7v1H78v13h-1.1v-13h-4.8v-1z" />
      </g>
      <g>
        <path d="M84.6 24.2h5.2c2.7 0 4.4 1.6 4.4 4.1 0 2.1-1.3 3.6-3.4 3.9l3.4 6H93l-3.3-5.8h-4v5.8h-1.1v-14zm1.1 1v6.2h4c2.1 0 3.4-1.2 3.4-3.1 0-1.9-1.3-3.1-3.4-3.1h-4z" />
      </g>
      <g>
        <path d="M96.6 24.2h1.1v14h-1.1v-14z" />
      </g>
      <g>
        <path d="M100.7 24.2h5.2c2.7 0 4.4 1.6 4.4 4.1s-1.7 4.1-4.4 4.1h-4.1v5.8h-1.1v-14zm1.1 1v6.2h4c2.1 0 3.4-1.3 3.4-3.1s-1.3-3.1-3.4-3.1h-4z" />
      </g>
      <g>
        <path d="M112.1 38.2v-14h1.1v13h6.7v1h-7.8z" />
      </g>
      <g>
        <path d="M121.7 24.2h1.1v14h-1.1v-14z" />
      </g>
      <g>
        <path d="M125.2 31.2c0-4.2 2.6-7.1 6.5-7.1 2.9 0 5.1 1.6 5.7 4.4h-1.1c-.6-2.2-2.3-3.4-4.6-3.4-3.2 0-5.3 2.5-5.3 6.1 0 3.6 2 6.1 5.3 6.1 2.4 0 4-1.2 4.6-3.4h1.1c-.6 2.8-2.7 4.4-5.7 4.4-4 0-6.5-2.9-6.5-7.1z" />
      </g>
      <g>
        <path d="M139.7 24.2h8.6v1h-7.5v5.3h6.3v1h-6.3v5.7h7.8v1h-8.9v-14z" />
      </g>
      <g>
        <path d="M73.1 43.8h8.6v1h-7.5v5.3h6.3v1h-6.3v5.7H82v1h-8.9v-14z" />
      </g>
      <g>
        <path d="M83.9 43.8h8.3v1H85v5.5h6v1h-6v6.5h-1.1v-14zM94.1 43.8h8.3v1h-7.2v5.5h6v1h-6v6.5h-1.1v-14z" />
      </g>
      <g>
        <path d="M104.3 43.8h8.6v1h-7.5v5.3h6.3v1h-6.3v5.7h7.8v1h-8.9v-14z" />
      </g>
      <g>
        <path d="M114.4 43.8h10.7v1h-4.8v13h-1.1v-13h-4.8v-1z" />
      </g>
      <g>
        <path d="M126.4 43.8h10.7v1h-4.8v13h-1.1v-13h-4.8v-1z" />
      </g>
      <g>
        <path d="M144 58c-3.8 0-6.5-3-6.5-7.1s2.7-7.1 6.5-7.1c3.9 0 6.5 3 6.5 7.1S147.8 58 144 58zm0-1c3.2 0 5.4-2.5 5.4-6.1 0-3.6-2.2-6.1-5.4-6.1s-5.4 2.5-5.4 6.1c-.1 3.5 2.2 6.1 5.4 6.1z" />
      </g>
      <defs>
        <path id="SVGID_RADIESSE_" d="M0 0h140.5v57.8H0z" />
      </defs>
      <clipPath id="SVGID_00000004548057301516297410000014968877252178619801_">
        <use
          xlinkHref="#SVGID_RADIESSE_"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath:
            "url(#SVGID_00000004548057301516297410000014968877252178619801_)",
        }}
      >
        <path d="M29.8 56.2v1.6c-9.2-.6-17.2-5.5-22-12.7-.7-1.1-1.4-2.2-2-3.4-1.9-3.8-3-8.2-3-12.7s1.1-8.9 3-12.7l1.4.7c-1.8 3.6-2.8 7.7-2.8 12s1 8.3 2.7 11.9c.6 1.2 1.2 2.3 2 3.4 4.6 6.7 12.1 11.3 20.7 11.9m28-40-1.4.8c1.8 3.6 2.7 7.7 2.7 11.9s-1 8.3-2.7 11.9c-.6 1.2-1.2 2.3-2 3.4-4.6 6.7-12.1 11.3-20.7 12v1.6c9.2-.6 17.2-5.5 22.1-12.7.7-1.1 1.4-2.2 2-3.4 1.9-3.8 3-8.2 3-12.7s-1.1-8.9-3-12.8M33.8.1c-.7-.1-1.4-.1-2-.1s-1.3 0-2 .1c-9.2.6-17.2 5.5-22 12.7l1.4.8c4.6-6.7 12.1-11.3 20.7-12 .6 0 1.3-.1 2-.1s1.3 0 2 .1c8.6.6 16.1 5.2 20.7 11.9l1.4-.8C51 5.6 42.9.7 33.8.1M41.4 35.1c0 4.6-3.9 7.8-9.6 7.8s-9.5-3.1-9.5-8.3h2c0 4.1 3.1 6.5 7.6 6.5s7.4-2.5 7.4-6.1-2.7-5.5-7.5-5.5h-2.3v-1.8h2.2c4.6 0 6.9-2.4 6.9-5.7s-2.7-5.3-6.6-5.3-7 2.3-7 5.9h-2c0-4.7 3.8-7.8 9.1-7.8s8.7 2.9 8.7 7.1c0 3.6-2 5.8-5.2 6.4 3 .6 5.8 2.6 5.8 6.8" />
      </g>
    </g>
  </svg>
);

export const Radiesse = SvgComponent;
