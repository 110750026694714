import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//Create a reducer that handle history case selected, that mean an object whit {year: number}
type HistoryState = {
  year: number | null;
};

export const historySlice = createSlice({
  name: "history",
  initialState: {
    year: null,
  } as HistoryState,
  reducers: {
    setYear: (state, action: PayloadAction<number | null>) => {
      state.year = action.payload;
    },
  },
});

export const { setYear } = historySlice.actions;

export default historySlice.reducer;
