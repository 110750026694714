import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ProdottiState = {
  selectedProduct:
    | "belotero"
    | "ultherapy"
    | "radiesse"
    | "biorepeel"
    | "neuromodulazione"
    | null;
  color?: string;
};

export const colors: Record<string, string> = {
  belotero: "#ED7204",
  neuromodulazione: "#0279C1",
  ultherapy: "#FCB43A",
  radiesse: "#1C3754",
  biorepeel: "#008083",
  viso: "white",
};

export const prodottiSlice = createSlice({
  name: "prodotti",
  initialState: {
    selectedProduct: null,
    color: "#ffffff",
  } as ProdottiState,
  reducers: {
    selectProduct: (
      state,
      action: PayloadAction<ProdottiState["selectedProduct"]>
    ) => {
      state.selectedProduct = action.payload;
      state.color =
        action.payload !== null ? colors[action.payload] : colors.viso;
    },
  },
});

export const { selectProduct } = prodottiSlice.actions;

export default prodottiSlice.reducer;
