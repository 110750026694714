import { Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  useIsRouteVisible,
  useRouter,
} from "../components/MainRouter/MainRouter";
import { Routes } from "../utils/routes";
import { Logo } from "../components/Atoms/Logo";
import { adjustVolume } from "../utils/functions";
import { ZIndexes } from "../components/InternalPage/InternalPage";
import { VideoKeys } from "../utils/assets";
import { useAsset } from "../components/AsssetsLoader";

export function StandByPage() {
  const [sfondo] = useAsset<VideoKeys>("sfondo");
  const { currentRoute, changeRoute } = useRouter();
  const isActive = useIsRouteVisible(Routes.standby);

  const ref = useRef<HTMLVideoElement>(null);
  const duration = 1000;

  const [opacity, setOpacity] = useState(1);

  const transition = useMemo(() => `opacity ${duration}ms`, []);

  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.currentTime = 0;
      ref.current.volume = 1;
      ref.current.play().catch(() => {});
      setOpacity(1);
    }
  }, [isActive]);

  if (currentRoute === Routes.standby || currentRoute === Routes.home) {
    return (
      <>
        <video
          crossOrigin="anonymous"
          ref={ref}
          loop
          src={sfondo}
          disablePictureInPicture
          style={{
            opacity,
            transition,
            zIndex: ZIndexes.background,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            backgroundColor: "black",
          }}
        />
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent="space-around"
          width={"100%"}
          height={"100%"}
          onClick={() => {
            changeRoute(Routes.home, { showPrevious: true });
            if (ref.current) {
              adjustVolume(ref!.current, 0, { duration: 1000 });
              setOpacity(0);
            }
          }}
        >
          <div></div>
          <div style={{ opacity, transition }}>
            <Logo fill="white" height={150} />
          </div>
          <div style={{ opacity, transition }}>
            {/* <IconButton
              sx={{ color: "white" }}
              onClick={() => {
                changeRoute(Routes.home, { showPrevious: true });
                if (ref.current) {
                  adjustVolume(ref!.current, 0, { duration: 1000 });
                  setOpacity(0);
                }
              }}
            >
              <ArrowDownIcon />
            </IconButton> */}
          </div>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}
