import { Box, Grid } from "@mui/material";

export function TextWithLines(props: {
  children: React.ReactNode;
  animation?: string;
}) {
  return (
    <Grid
      container
      width={"100%"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Grid item flex={1} pr={2} display={"flex"}>
        <Box
          sx={{
            borderTop: "1px solid #707070",
            width: "100%",
            height: 0,
            margin: "auto",
            animation: props.animation,
          }}
        />
      </Grid>
      <Grid item children={props.children} />
      <Grid item flex={1} pl={2} display={"flex"}>
        <Box
          sx={{
            borderTop: "1px solid #707070",
            width: "100%",
            height: 0,
            margin: "auto",
            animation: props.animation,
          }}
        />
      </Grid>
    </Grid>
  );
}
