/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import {
  InternalPage,
  ZIndexes,
} from "../../components/InternalPage/InternalPage";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  useIsRouteVisible,
  useRouter,
} from "../../components/MainRouter/MainRouter";
import { Routes } from "../../utils/routes";
import { Box, Grid, Typography } from "@mui/material";
import {
  HorizontalGrid,
  VerticalGrid,
} from "../../components/HorizontalGrid/HorizontalGrid";
import { useAsset } from "../../components/AsssetsLoader";
import { ImageKeys } from "../../utils/assets";
import { Header } from "../prodotti/BioRepeelPage";
import { VideoKeys } from "../../utils/assets";
import { useAppDispatch } from "../../redux/hooks";
import { ModalCarouselData, Rect, openModal } from "../../redux/modal.reducer";
import { easeOutQuad } from "../../constants";
import { BGVideo } from "../prodotti";
import { ArrowUpIcon } from "../../components/Icons/ArrowUP";
import { MAXLogo } from "../../components/Logos/MAXLogo";

const widthFraction = 0.135;

export function ServiziPage() {
  const { args, changeRoute } = useRouter<DOMRect>();

  const isActive = useIsRouteVisible(Routes.servizi);

  if (isActive) {
    return (
      <InternalPage
        color="titaniumYellow"
        backgroundColor="black"
        rect={args}
        goUpper={() => changeRoute(Routes.home, { showPrevious: false })}
        goBack={() =>
          changeRoute(Routes.inostriprodotti, { showPrevious: false })
        }
        goNext={() => changeRoute(Routes.elite, { showPrevious: false })}
        subsection={"Servizi"}
        sectionHeader={
          <Header color="titaniumYellow" showEverywhere>
            <Typography variant="h5" textAlign={"center"}>
              CUSTO<strong>MERZ</strong> <i>experience</i>
            </Typography>
          </Header>
        }
      >
        <Core />
      </InternalPage>
    );
  } else {
    return <></>;
  }
}

function Core() {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const apple_ref = useRef<HTMLImageElement>(null);
  const google_ref = useRef<HTMLImageElement>(null);
  const [
    servizi_formazione_thumbnail,
    servizi_eventi_thumbnail,
    servizi_marketing_thumbnail,
    servizi_medical_thumbnail,
    servizi_app_thumbnail,
    apple_store_qr,
    google_play_qr,
  ] = useAsset<ImageKeys>([
    "servizi_formazione_thumbnail",
    "servizi_eventi_thumbnail",
    "servizi_marketing_thumbnail",
    "servizi_medical_thumbnail",
    "servizi_app_thumbnail",
    "apple_store_qr",
    "google_play_qr",
  ]);
  const [
    servizi_formazione,
    servizi_eventi,
    servizi_marketing,
    servizi_medical,
    servizi_merz_care,
    servizi_app,
  ] = useAsset<VideoKeys>([
    "servizi_formazione",
    "servizi_eventi",
    "servizi_marketing",
    "servizi_medical",
    "servizi_merz_care",
    "servizi_app",
  ]);

  const elements: Array<VerticalCardProps> = [
    {
      title: "FORMAZIONE",
      subtitle: "MerzLab, Webinar, Masterclass",
      thumbnail: servizi_formazione_thumbnail,
      src: servizi_formazione,
      hasMaxLogo: true,
    },
    {
      title: "EVENTI",
      subtitle: (
        <>
          Congressi nazionali, internazionali,
          <br />
          Mexs Italia e Emea
        </>
      ),
      thumbnail: servizi_eventi_thumbnail,
      src: servizi_eventi,
    },
    {
      title: (
        <>
          MARKETING &<br /> DIGITAL
        </>
      ),
      thumbnail: servizi_marketing_thumbnail,
      src: servizi_marketing,
    },
    {
      title: (
        <>
          MEDICAL AFFAIRS &<br />
          MERZ CARE
        </>
      ),
      thumbnail: servizi_medical_thumbnail,
      src: [
        { type: "video", src: servizi_medical, title: "Medical Affairs" },
        { type: "video", src: servizi_merz_care, title: "Merz Care" },
      ],
      hasMaxLogo: true,
    },
    {
      title: "MERZ APP",
      thumbnail: servizi_app_thumbnail,
      src: servizi_app,
    },
  ];

  return (
    <>
      <Grid
        container
        height={"100%"}
        direction={"row"}
        justifyContent={"center"}
        py={6}
        style={{ color: "white" }}
      >
        <Grid item>
          {width !== null && (
            <HorizontalGrid
              type="container"
              columnSize={width * widthFraction}
              columns={6}
              gap={1}
              sx={{ height: "100%" }}
              scrollable={false}
            >
              {elements.map((element, index) => {
                return (
                  <HorizontalGrid type="item" key={index}>
                    <VerticalCard
                      title={element.title}
                      subtitle={element.subtitle}
                      thumbnail={element.thumbnail}
                      src={element.src}
                      delay={index * 0.5}
                      hasMaxLogo={element.hasMaxLogo}
                    />
                  </HorizontalGrid>
                );
              })}
              <HorizontalGrid type="item">
                <Grid
                  container
                  direction={"column"}
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
                  <Grid item xs>
                    <Grid
                      container
                      direction={"column"}
                      width={"100%"}
                      height={"100%"}
                      justifyContent={"space-evenly"}
                      alignContent={"center"}
                      sx={{
                        animation: "blur 1.5s ease-out",
                        animationDelay: `${elements.length * 0.5 + 3}s`,
                        opacity: 0,
                        animationFillMode: "forwards",
                      }}
                    >
                      <Grid item>
                        <Typography variant="body1" textAlign={"center"} pb={1}>
                          <strong>
                            <i>Apple Store</i>
                          </strong>
                        </Typography>
                        <Grid container justifyContent={"center"}>
                          <img
                            ref={apple_ref}
                            src={apple_store_qr}
                            style={{ width: "80%", height: "auto" }}
                            onClick={() =>
                              dispatch(
                                openModal({
                                  modalData: {
                                    type: "image",
                                    img: apple_store_qr,
                                  },
                                  rect: apple_ref.current!.getRect(),
                                })
                              )
                            }
                          ></img>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" textAlign={"center"} pb={1}>
                          <strong>
                            <i>Google Play</i>
                          </strong>
                        </Typography>
                        <Grid container justifyContent={"center"}>
                          <img
                            ref={google_ref}
                            src={google_play_qr}
                            onClick={() =>
                              dispatch(
                                openModal({
                                  modalData: {
                                    type: "image",
                                    img: google_play_qr,
                                  },
                                  rect: google_ref.current!.getRect(),
                                })
                              )
                            }
                            style={{ width: "80%", height: "auto" }}
                          ></img>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      sx={{
                        backgroundColor: `titaniumYellow.main`,
                        color: "white",
                        animation: "blur 1.5s ease-out",
                        animationDelay: `${elements.length * 0.5 + 3}s`,
                        opacity: 0,
                        animationFillMode: "forwards",
                      }}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignContent={"center"}
                      py={1}
                      px={2}
                      width={"15rem"}
                    >
                      <Grid item xs={2}>
                        <Grid
                          container
                          width={"100%"}
                          direction={"row"}
                          justifyContent={"center"}
                          alignContent={"center"}
                        >
                          <ArrowUpIcon height={"2rem"} fill="black" />
                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <Grid
                          container
                          direction={"column"}
                          justifyContent={"center"}
                          height={"100%"}
                          width={"100%"}
                        >
                          <Typography variant="h6" textAlign={"center"}>
                            <span style={{ color: "black", fontWeight: "500" }}>
                              Scarica l'app
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </HorizontalGrid>
            </HorizontalGrid>
          )}
        </Grid>
      </Grid>

      <BGVideo video="servizi_sfondo" />
    </>
  );
}
interface VerticalCardProps {
  title: React.ReactNode;
  thumbnail?: string;
  subtitle?: React.ReactNode;
  src?: string | ModalCarouselData["items"];
  delay?: number;
  hasMaxLogo?: boolean;
}

function VerticalCard(props: VerticalCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<Rect | null>(null);

  const src = props.src;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getRect());
    }
  }, []);

  return (
    <Box
      position={"relative"}
      height={"100%"}
      onClick={
        props.src !== undefined && rect
          ? () =>
              dispatch(
                openModal({
                  modalData: {
                    type: "carousel",
                    items:
                      typeof src === "string"
                        ? [
                            {
                              type: "video",
                              src,
                            },
                          ]
                        : src ?? [],
                  },
                  rect,
                  fullscreen: false,
                  closeOnEnd: true,
                })
              )
          : undefined
      }
    >
      <VerticalGrid type="container" rows={["auto", "6px"]}>
        <div
          ref={ref}
          style={{ height: rect?.height ?? "100%", position: "relative" }}
        >
          {rect && props.thumbnail && (
            <img
              src={props.thumbnail}
              width={"auto"}
              height={"100%"}
              style={{
                position: "absolute",
                bottom: 0,
                animationName: "bottom-up",
                animationDelay: `${props.delay ?? 0}s`,
                animationDuration: "2s",
                animationTimingFunction: easeOutQuad,
                animationFillMode: "both",
              }}
            />
          )}
        </div>
        <VerticalGrid
          type="item"
          sx={{ height: "100%", backgroundColor: "titaniumYellow.main" }}
        ></VerticalGrid>
      </VerticalGrid>

      <VerticalGrid
        type="container"
        rows={["auto", "6px"]}
        sx={{
          position: "absolute",
          width: "100%",
          top: 0,
          zIndex: ZIndexes.body + 1,
        }}
      >
        <VerticalGrid type="item">
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "100%",
              animation: "opacity-in 3s ease-out",
              opacity: 0,
              animationDelay: `${(props.delay ?? 0) + 3}s`,
              animationFillMode: "forwards",
            }}
          ></div>
        </VerticalGrid>
        <VerticalGrid type="item"></VerticalGrid>
      </VerticalGrid>
      {props.subtitle && (
        <Box
          position={"absolute"}
          zIndex={ZIndexes.body + 2}
          top={0}
          width={"100%"}
          px={1}
          py={2}
          sx={{
            animation: "blur 1.5s ease-out",
            opacity: 0,
            animationDelay: `${(props.delay ?? 0) + 3}s`,
            animationFillMode: "forwards",
          }}
        >
          <Typography variant="body2" textAlign={"center"}>
            <i>{props.subtitle}</i>
          </Typography>
        </Box>
      )}
      <Box
        position={"absolute"}
        zIndex={ZIndexes.body + 2}
        bottom={"1rem"}
        left={"1rem"}
        height={"14px"}
        sx={{
          animation: "blur 1.5s ease-out",
          opacity: 0,
          animationDelay: `${(props.delay ?? 0) + 3}s`,
          animationFillMode: "forwards",
        }}
      >
        <Typography
          variant="h3"
          textAlign={"left"}
          sx={{
            transform: "rotate(-90deg)",
            transformOrigin: "0 0",
            lineHeight: 0.9,
          }}
        >
          <strong>{props.title}</strong>
        </Typography>
      </Box>
      {props.hasMaxLogo && (
        <Box
          position={"absolute"}
          zIndex={ZIndexes.body + 2}
          bottom={0}
          right={0}
          sx={{
            animation: "blur 1.5s ease-out",
            opacity: 0,
            animationDelay: `${(props.delay ?? 0) + 3}s`,
            animationFillMode: "forwards",
          }}
        >
          <Grid container direction="column" gap={1} pr={1} pb={3}>
            <Grid item>
              <Typography variant="body1">Powered by</Typography>
            </Grid>
            <Grid item>
              <MAXLogo fill="white" width={"6rem"} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
