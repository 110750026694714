import { Box, Grid, Typography } from "@mui/material";
import { TextWithLines } from "../../components/Atoms/TextWithLines";
import { VerticalGrid } from "../../components/HorizontalGrid/HorizontalGrid";
import { GenericCard } from "../../components/Card/GenericCard";
import { useAsset } from "../../components/AsssetsLoader";
import { ImageKeys } from "../../utils/assets";

export function InClinic() {
  const boxWidth = 30;
  const [gallery1, gallery2, gallery3] = useAsset<ImageKeys>([
    "elite_in_clinic_gallery_1",
    "elite_in_clinic_gallery_2",
    "elite_in_clinic_gallery_3",
  ]);
  return (
    <>
      <VerticalGrid type="container" rows={["7rem", "auto"]}>
        <VerticalGrid type="item"></VerticalGrid>
        <VerticalGrid type="item">
          <Grid container height={"100%"} width={"100%"} direction={"column"}>
            <Grid item width={"100%"}>
              <TextWithLines animation="blur 1s ease-out">
                <Typography
                  variant="h4"
                  textAlign={"center"}
                  style={{ animation: "text-focus-in 3s ease-out" }}
                >
                  <strong>
                    Personalizziamo l'
                    <i>esperienza</i> dei pazienti <br />
                    all'interno del tuo studio medico.
                  </strong>
                </Typography>
              </TextWithLines>
            </Grid>
            <Grid item xs>
              <Grid
                container
                justifyContent={"center"}
                alignContent={"center"}
                height={"100%"}
                columnGap={1}
                sx={{
                  animation: "blur 3s ease-out",
                }}
              >
                <Box width={`${boxWidth}rem`} height={`${boxWidth / 1.77}rem`}>
                  <GenericCard byHeight img={gallery1} />
                </Box>
                <Box width={`${boxWidth}rem`} height={`${boxWidth / 1.77}rem`}>
                  <GenericCard byHeight img={gallery2} />
                </Box>
                <Box width={`${boxWidth}rem`} height={`${boxWidth / 1.77}rem`}>
                  <GenericCard byHeight img={gallery3} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </VerticalGrid>
      </VerticalGrid>
    </>
  );
}
