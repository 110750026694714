import { Grid, Typography } from "@mui/material";
import { Center } from "../../pages/elite/digital_marketing";
import { forwardRef } from "react";

export const RectangleButton = forwardRef(function RectangleButton(
  props: {
    width?: string;
    height?: string;
    icon?: React.ReactNode;
    text?: string;
    opacity?: number;
    onClick?: () => void;
  },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Grid
      container
      width={props.width ?? "15rem"}
      height={props.height ?? "3rem"}
      sx={{
        backgroundColor: "#000",
        opacity: props.opacity ?? 1,
        transition: "opacity 1s ease-in-out",
      }}
      justifyContent={"center"}
      alignContent={"center"}
      onClick={props.onClick}
      ref={ref}
    >
      {props.icon !== undefined && (
        <Grid item px={1}>
          <Center>{props.icon}</Center>
        </Grid>
      )}
      <Grid item xs py={1}>
        <Typography
          color={"white"}
          textAlign={"center"}
          ml={props.icon === undefined ? 0 : -2}
        >
          <i>{props.text}</i>
        </Typography>
      </Grid>
    </Grid>
  );
});
