/* eslint-disable jsx-a11y/alt-text */
import { Grid, GridProps } from "@mui/material";

import { Colors } from "../../styles/theme";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppDispatch } from "../../redux/hooks";
import { ModalData, Rect, openModal } from "../../redux/modal.reducer";
import { preventDefault } from "../Modal";

type StyleSize = { width: string | number; height: string | number };

export type GenericCardItem = {
  index: number;
};

//function that generate random string
const randomString = (length: number) => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
export interface GenericCardProps extends Pick<GridProps, "width" | "height"> {
  reverse?: boolean;
  children?: JSX.Element;
  color?: Colors;
  mediaChild?: (rect: Rect, id?: string) => GridProps["children"];
  img?: string;
  video?: string;
  is169?: boolean;
  byWidth?: boolean;
  byHeight?: boolean;
  startTime?: number;
  sx?: GridProps["sx"];
  modalContainer?: (
    setRef: (ref: HTMLDivElement | undefined) => void,
    changeVideo: (video: HTMLVideoElement | undefined) => void
  ) => JSX.Element;
  opacity?: CSSProperties["opacity"];
  onClick?: (rect?: Rect, id?: string) => void;
  height?: CSSProperties["height"];
}

export function GenericCard({ mediaChild, ...props }: GenericCardProps) {
  const containerMediaRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [rect, setRect] = useState<Rect | undefined>();
  const [size, setSize] = useState<StyleSize | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  const id = useMemo(() => randomString(10), []);

  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    let rect: Rect | undefined = undefined;
    let modalData: ModalData | undefined = undefined;
    if (props.img !== undefined) {
      rect = imgRef.current?.getRect();
      modalData = {
        type: "image",
        img: props.img,
      };
    } else if (props.video !== undefined) {
      rect = videoRef.current?.getRect();
      modalData = {
        type: "video",
        video: props.video,
      };
    }
    if (rect !== undefined && modalData !== undefined) {
      dispatch(
        openModal({
          modalData,
          rect,
        })
      );
    }
  }, [dispatch, props.img, props.video]);

  useEffect(() => {
    const rect = containerMediaRef?.current?.getRect();
    containerMediaRef.current?.setAttribute("id", id);
    setRect(rect);
    if (rect !== undefined) {
      if (props.is169) {
        // setSize({
        //   width: rect.width,
        //   height: widthToHeight(rect.width),
        // });
      } else if (props.byHeight) {
        setSize({
          width: "auto",
          height: props.height ?? rect.height,
        });
      } else if (props.byWidth) {
        setSize({
          width: rect.width,
          height: "auto",
        });
      } else {
        setSize({
          width: rect.width,
          height: props.height ?? "auto",
        });
      }
    }

    ref.current?.classList?.add("generic-card");
  }, [id, props.byHeight, props.byWidth, props.height, props.is169]);

  return (
    <Grid
      ref={ref}
      container
      direction={props.reverse ? "column-reverse" : "column"}
      sx={{
        height: "100%",
        width: props.width,
        ...props.sx,
        border: `0.1rem solid`,
        borderColor: `${props.color}.main`,
      }}
      onClick={(e) => {
        preventDefault(e);
        props.onClick === undefined
          ? handleClick()
          : props.onClick(
              document.getElementById(id ?? "")?.getRect() ?? rect,
              id
            );
      }}
    >
      <Grid
        ref={containerMediaRef}
        item
        xs
        overflow={"hidden"}
        style={{ maxHeight: props.height }}
        maxWidth={"100%"}
      >
        {props.img !== undefined && size !== undefined && (
          <Grid container justifyContent={"center"} alignContent={"center"}>
            <img
              ref={imgRef}
              src={props.img}
              style={{
                objectFit: "cover",
                height: size.height,
                width: size.width,
                opacity: 1,
              }}
            />
          </Grid>
        )}
        {props.video !== undefined && size !== undefined && (
          <Grid container justifyContent={"center"} alignContent={"center"}>
            <video
              crossOrigin="anonymous"
              ref={videoRef}
              onLoadedData={(e) =>
                ((e.target as HTMLVideoElement).currentTime =
                  props.startTime ?? 0)
              }
              src={props.video}
              style={{
                objectFit: "cover",
                height: size.height,
                width: size.width,
                opacity: 1,
              }}
            />
          </Grid>
        )}
        {mediaChild !== undefined && rect !== undefined && mediaChild(rect, id)}
      </Grid>
      {props.children}
      {props.color && (
        <Grid item height={6} sx={{ backgroundColor: `${props.color}.main` }} />
      )}
    </Grid>
  );
}
