import { Box, Grid } from "@mui/material";
import { ElitePages, updatePosition } from "../../redux/elite.reducer";
import { RotatingCircle } from "../../components/Atoms/RotatingCircle";
import { useWindowSize } from "@uidotdev/usehooks";
import { useMemo, useCallback, useEffect } from "react";
import { useDrag } from "react-use-gesture";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { ArrowDownFill } from "../../components/Icons/ArrowDownFill";
import { rotationOffsetSensibility } from "../../constants";

export function BottomCircle({
  id,
  height,
  onClick,
  startAngle,
  endAngle,
  rectFont,
  boxWidth,
  boxHeight,
}: {
  id: ElitePages;
  height: number;
  onClick?: (value: any) => void;
  startAngle?: number;
  endAngle?: number;
  boxWidth: number;
  boxHeight: number;
  rectFont?: React.CSSProperties["fontSize"];
}) {
  return (
    <Box
      position="absolute"
      bottom={0}
      width={"100%"}
      height={`${height / 2.5}rem`}
      sx={{
        animation: "blur 1s ease-out",
      }}
    >
      <Grid container justifyContent="center">
        <DraggableBox id={id}>
          <RotatingCircle
            circleId={id}
            height={`${height}rem`}
            overflow={"visible"}
            onClick={onClick}
            startAngle={startAngle ?? 270}
            endAngle={endAngle ?? 450}
            rectFont={rectFont}
            boxHeight={boxHeight}
            boxWidth={boxWidth}
          />
        </DraggableBox>
      </Grid>
    </Box>
  );
}

export function TriangleSelection({ id }: { id: ElitePages }) {
  const sensibility = 0.05;
  const { selected, isDraggingUser, isAutoRotating, velocity } = useAppSelector(
    (state) => state.elite.circles.find((e) => e.id === id)
  )!;
  const dispatch = useAppDispatch();
  const [diff, opacity] = useMemo(
    () => [
      Math.abs(selected - Math.floor(selected) - 0.5) / 0.5,
      selected - Math.floor(selected) === 0 ? 1 : 0,
    ],
    [selected]
  );

  const nextTick = useCallback(() => {
    const firstHalf = selected - Math.floor(selected);
    if (firstHalf === 0 || firstHalf === 1) {
      return;
    }
    const sign = firstHalf < 0.5 ? 1 : -1;
    const diff = firstHalf < 0.5 ? firstHalf : 1 - firstHalf;
    const normalizedSensibility = sensibility * Math.max(velocity, 0.4);
    dispatch(
      updatePosition({
        id,
        offset: Math.min(normalizedSensibility, diff) * sign,
        isDraggingUser: false,
        isAutoRotating: true,
        velocity,
      })
    );
  }, [dispatch, id, selected, velocity]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isDraggingUser) {
      return;
    } else if (isAutoRotating) {
      timeout = setTimeout(nextTick, 75);
    } else {
      nextTick();
    }
    return () => clearTimeout(timeout);
  }, [diff, dispatch, nextTick, isDraggingUser, isAutoRotating, velocity]);

  return (
    <ArrowDownFill
      height={"1.5rem"}
      fill={"black"}
      opacity={opacity}
      style={{
        transition: "opacity 1s ease-in-out",
      }}
    />
  );
}

function DraggableBox(props: { id: ElitePages; children: React.ReactNode }) {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const bind = useDrag(
    ({ down, movement: [mx], velocity }) => {
      const range = width == null ? 1000 : width;
      const offset = Math.sign(mx) * Math.min(1, Math.abs(mx) / range);
      dispatch(
        updatePosition({
          id: props.id,
          offset: rotationOffsetSensibility(offset),
          isDraggingUser: down,
          isAutoRotating: false,
          velocity,
        })
      );
    },
    { axis: "x" }
  );
  return <div {...bind()} children={props.children} />;
}
