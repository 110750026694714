import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="100.8 -29.63 31.51 23.09"
    fill="white"
    {...props}
  >
    <path d="m132.31-14.28-15.06-15.06a.99.99 0 0 0-1.39 0L100.8-14.28l1.37 1.36 3.05-3.05v9.44h1.93V-17.9l9.41-9.41 9.4 9.4v11.37h1.93v-9.44l3.05 3.05 1.37-1.35z" />
  </svg>
);
export { SvgComponent as HomeIcon };
