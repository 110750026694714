import { Box, BoxProps, ButtonProps, Button as MuiButton } from "@mui/material";
import { Colors } from "../../styles/theme";
import { easeInQuad } from "../../constants";

export interface LineProps {
  color: Colors;
  height?: BoxProps["height"];
  children?: BoxProps["children"];
  animated?: boolean;
}

export function Line(props: LineProps) {
  return (
    <Box
      height={props.height}
      sx={{
        ...(props.animated
          ? {
              animation: `width-fill 2s ${easeInQuad}  both`,
            }
          : {
              width: "100%",
            }),
        color: `${props.color}.contrastText`,
        backgroundColor: `${props.color}.main`,
      }}
      children={props.children}
    />
  );
}

export const Button = MuiButton as (
  props: Omit<ButtonProps, "color"> & { color?: Colors }
) => JSX.Element;
